export let softs = [
 {
  "id": "1",
  "ini": "す",
  "name": "スーパーマリオワールド",
  "maker": "任天堂",
  "releaseDate": "1990/11/21",
  "price": "8000",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "2",
  "ini": "え",
  "name": "F-ZERO",
  "maker": "任天堂",
  "releaseDate": "1990/11/21",
  "price": "7000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "ほ",
  "name": "ボンバザル",
  "maker": "ケムコ",
  "releaseDate": "1990/12/1",
  "price": "6500",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "4",
  "ini": "あ",
  "name": "アクトレイザー",
  "maker": "エニックス",
  "releaseDate": "1990/12/16",
  "price": "8000",
  "genre": "アクション・育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "ほ",
  "name": "ポピュラス",
  "maker": "イマジニア",
  "releaseDate": "1990/12/16",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "6",
  "ini": "く",
  "name": "グラディウスⅢ",
  "maker": "コナミ",
  "releaseDate": "1990/12/21",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "60"
 },
 {
  "id": "7",
  "ini": "は",
  "name": "パイロットウイングス",
  "maker": "任天堂",
  "releaseDate": "1990/12/21",
  "price": "8000",
  "genre": "フライト・シミュレーション・スポーツ・3D",
  "icNo": "53"
 },
 {
  "id": "8",
  "ini": "ふ",
  "name": "ファイナルファイト",
  "maker": "カプコン",
  "releaseDate": "1990/12/21",
  "price": "8500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "え",
  "name": "SDザ・グレイトバトル",
  "maker": "バンプレスト",
  "releaseDate": "1990/12/29",
  "price": "8200",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "10",
  "ini": "し",
  "name": "ジャンボ尾崎のホールインワン",
  "maker": "HAL研究所",
  "releaseDate": "1991/2/23",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "62"
 },
 {
  "id": "11",
  "ini": "ひ",
  "name": "BIG RUN",
  "maker": "ジャレコ",
  "releaseDate": "1991/3/20",
  "price": "8700",
  "genre": "レース・3D",
  "icNo": "22"
 },
 {
  "id": "12",
  "ini": "た",
  "name": "ダライアスツイン",
  "maker": "タイトー",
  "releaseDate": "1991/3/29",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "20"
 },
 {
  "id": "13",
  "ini": "は",
  "name": "遙かなるオーガスタ",
  "maker": "T&Eソフト",
  "releaseDate": "1991/4/5",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "14",
  "ini": "う",
  "name": "ウルトラマン",
  "maker": "バンダイ",
  "releaseDate": "1991/4/6",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "し",
  "name": "シムシティー",
  "maker": "任天堂",
  "releaseDate": "1991/4/26",
  "price": "8000",
  "genre": "育成・シミュレーション",
  "icNo": "55"
 },
 {
  "id": "16",
  "ini": "す",
  "name": "スーパープロフェッショナルベースボール",
  "maker": "ジャレコ",
  "releaseDate": "1991/5/17",
  "price": "8700",
  "genre": "スポーツ",
  "icNo": "51"
 },
 {
  "id": "17",
  "ini": "と",
  "name": "ドラッケン",
  "maker": "ケムコ",
  "releaseDate": "1991/5/24",
  "price": "8500",
  "genre": "RPG・3D",
  "icNo": "60"
 },
 {
  "id": "18",
  "ini": "か",
  "name": "ガデュリン",
  "maker": "セタ",
  "releaseDate": "1991/5/28",
  "price": "8800",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "19",
  "ini": "い",
  "name": "イースⅢ ワンダラーズ フロム イース",
  "maker": "トンキンハウス",
  "releaseDate": "1991/6/21",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "20",
  "ini": "す",
  "name": "スーパースタジアム",
  "maker": "セタ",
  "releaseDate": "1991/7/2",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "21",
  "ini": "き",
  "name": "機動戦士ガンダムF91 フォーミュラー戦記0122",
  "maker": "バンダイ",
  "releaseDate": "1991/7/6",
  "price": "9500",
  "genre": "シミュレーション",
  "icNo": "54"
 },
 {
  "id": "22",
  "ini": "す",
  "name": "スーパーウルトラベースボール",
  "maker": "カルチャーブレーン",
  "releaseDate": "1991/7/12",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "23",
  "ini": "す",
  "name": "SUPER R・TYPE",
  "maker": "アイレム",
  "releaseDate": "1991/7/13",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "か",
  "name": "がんばれゴエモン ゆき姫救出絵巻",
  "maker": "コナミ",
  "releaseDate": "1991/7/19",
  "price": "8800",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "25",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅣ",
  "maker": "スクウェア",
  "releaseDate": "1991/7/19",
  "price": "8800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "26",
  "ini": "は",
  "name": "バトルドッジボール",
  "maker": "バンプレスト",
  "releaseDate": "1991/7/20",
  "price": "9600",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "27",
  "ini": "え",
  "name": "エリア88",
  "maker": "カプコン",
  "releaseDate": "1991/7/26",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "43"
 },
 {
  "id": "28",
  "ini": "は",
  "name": "白熱プロ野球ガンバリーグ",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1991/8/9",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "29",
  "ini": "し",
  "name": "初段 森田将棋",
  "maker": "セタ",
  "releaseDate": "1991/8/23",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "33"
 },
 {
  "id": "30",
  "ini": "す",
  "name": "スーパーテニス ワールドサーキット",
  "maker": "トンキンハウス",
  "releaseDate": "1991/8/30",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "22"
 },
 {
  "id": "31",
  "ini": "は",
  "name": "ハイパーゾーン",
  "maker": "HAL研究所",
  "releaseDate": "1991/8/31",
  "price": "8500",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "し",
  "name": "ジェリーボーイ",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1991/9/13",
  "price": "8500",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "33",
  "ini": "す",
  "name": "スーパー三國志Ⅱ",
  "maker": "光栄",
  "releaseDate": "1991/9/15",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "21"
 },
 {
  "id": "34",
  "ini": "ふ",
  "name": "プロサッカー",
  "maker": "イマジニア",
  "releaseDate": "1991/9/20",
  "price": "8000",
  "genre": "スポーツ",
  "icNo": "20"
 },
 {
  "id": "35",
  "ini": "ち",
  "name": "超魔界村",
  "maker": "カプコン",
  "releaseDate": "1991/10/4",
  "price": "8500",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "36",
  "ini": "す",
  "name": "SUPER E.D.F.",
  "maker": "ジャレコ",
  "releaseDate": "1991/10/25",
  "price": "8700",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅣ イージータイプ",
  "maker": "スクウェア",
  "releaseDate": "1991/10/29",
  "price": "9000",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "38",
  "ini": "あ",
  "name": "悪魔城ドラキュラ",
  "maker": "コナミ",
  "releaseDate": "1991/10/31",
  "price": "8800",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "39",
  "ini": "せ",
  "name": "ゼルダの伝説 神々のトライフォース",
  "maker": "任天堂",
  "releaseDate": "1991/11/21",
  "price": "8000",
  "genre": "アクション・アドベンチャー",
  "icNo": "54"
 },
 {
  "id": "40",
  "ini": "ら",
  "name": "雷電伝説",
  "maker": "東映動画",
  "releaseDate": "1991/11/29",
  "price": "8700",
  "genre": "シューティング",
  "icNo": "20"
 },
 {
  "id": "41",
  "ini": "し",
  "name": "JOE&MAC 戦え原始人",
  "maker": "データイースト",
  "releaseDate": "1991/12/6",
  "price": "8500",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "42",
  "ini": "す",
  "name": "スーパーフォーメーションサッカー",
  "maker": "ヒューマン",
  "releaseDate": "1991/12/13",
  "price": "7700",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "す",
  "name": "スーパーワギャンランド",
  "maker": "ナムコ",
  "releaseDate": "1991/12/13",
  "price": "8300",
  "genre": "アクション・ミニゲーム",
  "icNo": "22"
 },
 {
  "id": "44",
  "ini": "ち",
  "name": "ちびまる子ちゃん 「はりきり365日」の巻",
  "maker": "エポック社",
  "releaseDate": "1991/12/13",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "45",
  "ini": "ら",
  "name": "ラグーン",
  "maker": "ケムコ",
  "releaseDate": "1991/12/13",
  "price": "8500",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "46",
  "ini": "れ",
  "name": "レミングス",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/12/18",
  "price": "8500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング",
  "maker": "ヒューマン",
  "releaseDate": "1991/12/20",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "48",
  "ini": "た",
  "name": "ダンジョン・マスター",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1991/12/20",
  "price": "9800",
  "genre": "RPG・3D",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "て",
  "name": "ディメンションフォース",
  "maker": "アスミック",
  "releaseDate": "1991/12/20",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "20"
 },
 {
  "id": "50",
  "ini": "え",
  "name": "SDガンダム外伝 ナイトガンダム物語 大いなる遺産",
  "maker": "エンジェル",
  "releaseDate": "1991/12/21",
  "price": "9500",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "51",
  "ini": "す",
  "name": "スーパー信長の野望 武将風雲録",
  "maker": "光栄",
  "releaseDate": "1991/12/21",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "13"
 },
 {
  "id": "52",
  "ini": "さ",
  "name": "サンダースピリッツ",
  "maker": "東芝EMI",
  "releaseDate": "1991/12/27",
  "price": "8600",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "53",
  "ini": "は",
  "name": "反省ザル ジローくんの大冒険",
  "maker": "ナツメ",
  "releaseDate": "1991/12/27",
  "price": "7000",
  "genre": "アクション",
  "icNo": "65"
 },
 {
  "id": "54",
  "ini": "す",
  "name": "スーパーチャイニーズワールド",
  "maker": "カルチャーブレーン",
  "releaseDate": "1991/12/28",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "55",
  "ini": "し",
  "name": "シムアース",
  "maker": "イマジニア",
  "releaseDate": "1991/12/29",
  "price": "9600",
  "genre": "育成・シミュレーション",
  "icNo": "56"
 },
 {
  "id": "56",
  "ini": "は",
  "name": "バトルコマンダー",
  "maker": "バンプレスト",
  "releaseDate": "1991/12/29",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "57",
  "ini": "た",
  "name": "高橋名人の大冒険島",
  "maker": "ハドソン",
  "releaseDate": "1992/1/11",
  "price": "8500",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "58",
  "ini": "ふ",
  "name": "プロフットボール",
  "maker": "イマジニア",
  "releaseDate": "1992/1/17",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "44"
 },
 {
  "id": "59",
  "ini": "と",
  "name": "ドラゴンボールZ 超サイヤ伝説",
  "maker": "バンダイ",
  "releaseDate": "1992/1/25",
  "price": "9500",
  "genre": "RPG",
  "icNo": "30"
 },
 {
  "id": "60",
  "ini": "ろ",
  "name": "ロマンシング サ・ガ",
  "maker": "スクウェア",
  "releaseDate": "1992/1/28",
  "price": "9500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "そ",
  "name": "ソウルブレイダー",
  "maker": "エニックス",
  "releaseDate": "1992/1/31",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "44"
 },
 {
  "id": "62",
  "ini": "と",
  "name": "ドラゴンスレイヤー 英雄伝説",
  "maker": "エポック社",
  "releaseDate": "1992/2/14",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "え",
  "name": "エキゾースト・ヒート",
  "maker": "セタ",
  "releaseDate": "1992/2/21",
  "price": "8900",
  "genre": "レース・3D",
  "icNo": "31"
 },
 {
  "id": "64",
  "ini": "こ",
  "name": "魂斗羅スピリッツ",
  "maker": "コナミ",
  "releaseDate": "1992/2/28",
  "price": "8500",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "65",
  "ini": "ろ",
  "name": "ロケッティア",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1992/2/28",
  "price": "8900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "す",
  "name": "スーパーバーディ・ラッシュ",
  "maker": "データイースト",
  "releaseDate": "1992/3/6",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "60"
 },
 {
  "id": "67",
  "ini": "お",
  "name": "弟切草",
  "maker": "チュンソフト",
  "releaseDate": "1992/3/7",
  "price": "8800",
  "genre": "サウンドノベル",
  "icNo": "32"
 },
 {
  "id": "68",
  "ini": "あ",
  "name": "R.P.M.レーシング",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1992/3/19",
  "price": "8800",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "69",
  "ini": "す",
  "name": "スーパー伊忍道 打倒信長",
  "maker": "光栄",
  "releaseDate": "1992/3/19",
  "price": "11800",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "70",
  "ini": "し",
  "name": "新世紀GPX サイバーフォーミュラ",
  "maker": "タカラ",
  "releaseDate": "1992/3/19",
  "price": "8800",
  "genre": "レース",
  "icNo": "52"
 },
 {
  "id": "71",
  "ini": "ち",
  "name": "超攻合神サーディオン",
  "maker": "アスミック",
  "releaseDate": "1992/3/20",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "ふ",
  "name": "ファイナルファイト・ガイ",
  "maker": "カプコン",
  "releaseDate": "1992/3/20",
  "price": "8500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "73",
  "ini": "え",
  "name": "S・T・G",
  "maker": "アテナ",
  "releaseDate": "1992/3/27",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "74",
  "ini": "か",
  "name": "カードマスター",
  "maker": "HAL研究所",
  "releaseDate": "1992/3/27",
  "price": "8900",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "75",
  "ini": "さ",
  "name": "ザ・グレイトバトルⅡ ラストファイターツイン",
  "maker": "バンプレスト",
  "releaseDate": "1992/3/27",
  "price": "8200",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "76",
  "ini": "す",
  "name": "SUPERヴァリス 赤き月の乙女",
  "maker": "日本テレネット",
  "releaseDate": "1992/3/27",
  "price": "8500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "77",
  "ini": "す",
  "name": "スーパーファミスタ",
  "maker": "ナムコ",
  "releaseDate": "1992/3/27",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "78",
  "ini": "す",
  "name": "SMASH・T.V.",
  "maker": "アスキー",
  "releaseDate": "1992/3/27",
  "price": "7800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "79",
  "ini": "と",
  "name": "トップレーサー",
  "maker": "ケムコ",
  "releaseDate": "1992/3/27",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "2"
 },
 {
  "id": "80",
  "ini": "は",
  "name": "ハットトリックヒーロー",
  "maker": "タイトー",
  "releaseDate": "1992/3/27",
  "price": "8000",
  "genre": "スポーツ",
  "icNo": "54"
 },
 {
  "id": "81",
  "ini": "は",
  "name": "バトルグランプリ",
  "maker": "ナグザット",
  "releaseDate": "1992/3/27",
  "price": "8500",
  "genre": "レース",
  "icNo": "33"
 },
 {
  "id": "82",
  "ini": "ら",
  "name": "ラッシング・ビート",
  "maker": "ジャレコ",
  "releaseDate": "1992/3/27",
  "price": "8700",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "83",
  "ini": "ら",
  "name": "らんま1/2 町内激闘篇",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/3/27",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "ま",
  "name": "まじかる☆タルるートくん MAGIC ADVENTURE",
  "maker": "バンダイ",
  "releaseDate": "1992/3/28",
  "price": "8000",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "85",
  "ini": "う",
  "name": "ウルティマⅥ 偽りの予言者",
  "maker": "ポニーキャニオン",
  "releaseDate": "1992/4/3",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "え",
  "name": "エアーマネジメント 大空に賭ける",
  "maker": "光栄",
  "releaseDate": "1992/4/5",
  "price": "11800",
  "genre": "経営・シミュレーション",
  "icNo": "51"
 },
 {
  "id": "87",
  "ini": "お",
  "name": "オセロワールド",
  "maker": "ツクダオリジナル",
  "releaseDate": "1992/4/5",
  "price": "8700",
  "genre": "テーブル",
  "icNo": "61"
 },
 {
  "id": "88",
  "ini": "へ",
  "name": "ペブルビーチの波濤",
  "maker": "T&Eソフト",
  "releaseDate": "1992/4/10",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "89",
  "ini": "こ",
  "name": "豪槍神雷伝説 武者",
  "maker": "データム・ポリスター",
  "releaseDate": "1992/4/21",
  "price": "8800",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "90",
  "ini": "す",
  "name": "スーパーカップサッカー",
  "maker": "ジャレコ",
  "releaseDate": "1992/4/24",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "51"
 },
 {
  "id": "91",
  "ini": "た",
  "name": "WWFスーパーレッスルマニア",
  "maker": "アクレイムジャパン",
  "releaseDate": "1992/4/24",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "へ",
  "name": "ヘラクレスの栄光Ⅲ 神々の沈黙",
  "maker": "データイースト",
  "releaseDate": "1992/4/24",
  "price": "8800",
  "genre": "RPG",
  "icNo": "55"
 },
 {
  "id": "93",
  "ini": "ま",
  "name": "摩訶摩訶",
  "maker": "シグマ",
  "releaseDate": "1992/4/24",
  "price": "8700",
  "genre": "RPG",
  "icNo": "31"
 },
 {
  "id": "94",
  "ini": "え",
  "name": "F-1　GRAND PRIX",
  "maker": "ビデオシステム",
  "releaseDate": "1992/4/28",
  "price": "9700",
  "genre": "レース",
  "icNo": "2"
 },
 {
  "id": "95",
  "ini": "け",
  "name": "拳闘王ワールドチャンピオン",
  "maker": "ソフエル",
  "releaseDate": "1992/4/28",
  "price": "8000",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "96",
  "ini": "す",
  "name": "スーパーアレスタ",
  "maker": "東宝",
  "releaseDate": "1992/4/28",
  "price": "8700",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "97",
  "ini": "す",
  "name": "スーパー上海ドラゴンズアイ",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/4/28",
  "price": "7800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "98",
  "ini": "は",
  "name": "バトルブレイズ",
  "maker": "サミー",
  "releaseDate": "1992/5/1",
  "price": "8700",
  "genre": "対戦格闘",
  "icNo": "2"
 },
 {
  "id": "99",
  "ini": "こ",
  "name": "甲竜伝説ヴィルガスト 消えた少女",
  "maker": "バンダイ",
  "releaseDate": "1992/5/23",
  "price": "9000",
  "genre": "RPG",
  "icNo": "31"
 },
 {
  "id": "100",
  "ini": "さ",
  "name": "斬Ⅱ スピリッツ",
  "maker": "ウルフ・チーム",
  "releaseDate": "1992/5/29",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "30"
 },
 {
  "id": "101",
  "ini": "ま",
  "name": "マジックソード",
  "maker": "カプコン",
  "releaseDate": "1992/5/29",
  "price": "8500",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "102",
  "ini": "す",
  "name": "ストリートファイターⅡ",
  "maker": "カプコン",
  "releaseDate": "1992/6/10",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "す",
  "name": "スーパー将棋",
  "maker": "アイマックス",
  "releaseDate": "1992/6/19",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "104",
  "ini": "す",
  "name": "スーパーダンクショット",
  "maker": "HAL研究所",
  "releaseDate": "1992/6/19",
  "price": "8600",
  "genre": "スポーツ・3D",
  "icNo": "43"
 },
 {
  "id": "105",
  "ini": "き",
  "name": "キャメルトライ",
  "maker": "タイトー",
  "releaseDate": "1992/6/26",
  "price": "8500",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "106",
  "ini": "こ",
  "name": "甲子園2",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1992/6/26",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "107",
  "ini": "そ",
  "name": "総合格闘技アストラルバウト",
  "maker": "キングレコード",
  "releaseDate": "1992/6/26",
  "price": "9030",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "よ",
  "name": "横山光輝 三国志",
  "maker": "エンジェル",
  "releaseDate": "1992/6/26",
  "price": "9500",
  "genre": "シミュレーション",
  "icNo": "33"
 },
 {
  "id": "109",
  "ini": "す",
  "name": "スーパーオフロード",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/7/3",
  "price": "6900",
  "genre": "レース",
  "icNo": "55"
 },
 {
  "id": "110",
  "ini": "す",
  "name": "スーパーボウリング",
  "maker": "アテナ",
  "releaseDate": "1992/7/3",
  "price": "8300",
  "genre": "スポーツ",
  "icNo": "40"
 },
 {
  "id": "111",
  "ini": "は",
  "name": "パロディウスだ! 神話からお笑いへ",
  "maker": "コナミ",
  "releaseDate": "1992/7/3",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "ひ",
  "name": "PGAツアーゴルフ",
  "maker": "イマジニア",
  "releaseDate": "1992/7/3",
  "price": "8500",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "113",
  "ini": "ふ",
  "name": "プリンス オブ ペルシャ",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/7/3",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "ら",
  "name": "ライトファンタジー",
  "maker": "トンキンハウス",
  "releaseDate": "1992/7/3",
  "price": "8900",
  "genre": "RPG",
  "icNo": "12"
 },
 {
  "id": "115",
  "ini": "ほ",
  "name": "北斗の拳5 天魔流星伝 哀★絶章",
  "maker": "東映動画",
  "releaseDate": "1992/7/10",
  "price": "8900",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "116",
  "ini": "ゆ",
  "name": "ゆうゆのクイズでGO！GO！",
  "maker": "タイトー",
  "releaseDate": "1992/7/10",
  "price": "8500",
  "genre": "クイズ",
  "icNo": "55"
 },
 {
  "id": "117",
  "ini": "ま",
  "name": "マリオペイント",
  "maker": "任天堂",
  "releaseDate": "1992/7/14",
  "price": "9800",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "118",
  "ini": "き",
  "name": "キャプテン翼Ⅲ 皇帝の挑戦",
  "maker": "テクモ",
  "releaseDate": "1992/7/17",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "119",
  "ini": "す",
  "name": "鈴木亜久里のF-1スーパードライビング",
  "maker": "ロジーク",
  "releaseDate": "1992/7/17",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "52"
 },
 {
  "id": "120",
  "ini": "た",
  "name": "ダイナウォーズ 恐竜王国への大冒険",
  "maker": "アイレム",
  "releaseDate": "1992/7/17",
  "price": "8800",
  "genre": "アクション",
  "icNo": "64"
 },
 {
  "id": "121",
  "ini": "は",
  "name": "パチンコウォーズ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/7/17",
  "price": "9500",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "122",
  "ini": "ふ",
  "name": "HOOK",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1992/7/17",
  "price": "8500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "123",
  "ini": "さ",
  "name": "サンドラの大冒険 ワルキューレとの出逢い",
  "maker": "ナムコ",
  "releaseDate": "1992/7/23",
  "price": "8300",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "124",
  "ini": "あ",
  "name": "アースライト",
  "maker": "ハドソン",
  "releaseDate": "1992/7/24",
  "price": "8500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "う",
  "name": "ウルティメイトフットボール",
  "maker": "サミー",
  "releaseDate": "1992/7/24",
  "price": "8700",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "126",
  "ini": "さ",
  "name": "サイバリオン",
  "maker": "東芝EMI",
  "releaseDate": "1992/7/24",
  "price": "8600",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "127",
  "ini": "す",
  "name": "スーパーF1サーカス",
  "maker": "日本物産",
  "releaseDate": "1992/7/24",
  "price": "8800",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "128",
  "ini": "て",
  "name": "ティーンエイジ ミュータント ニンジャ タートルズ TURTLES IN TIME",
  "maker": "コナミ",
  "releaseDate": "1992/7/24",
  "price": "8500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "129",
  "ini": "ふ",
  "name": "ブレイゾン",
  "maker": "アトラス",
  "releaseDate": "1992/7/24",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "42"
 },
 {
  "id": "130",
  "ini": "さ",
  "name": "3×3EYES 聖魔降臨伝",
  "maker": "ユタカ",
  "releaseDate": "1992/7/28",
  "price": "9500",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "131",
  "ini": "き",
  "name": "機甲警察メタルジャック",
  "maker": "アトラス",
  "releaseDate": "1992/7/31",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "き",
  "name": "KING OF THE MONSTERS",
  "maker": "タカラ",
  "releaseDate": "1992/7/31",
  "price": "8800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "133",
  "ini": "ひ",
  "name": "飛龍の拳S GOLDEN FIGHTER",
  "maker": "カルチャーブレーン",
  "releaseDate": "1992/7/31",
  "price": "9700",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "134",
  "ini": "ほ",
  "name": "炎の闘球児 ドッジ弾平",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/7/31",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "20"
 },
 {
  "id": "135",
  "ini": "す",
  "name": "スーパー大航海時代",
  "maker": "光栄",
  "releaseDate": "1992/8/5",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "53"
 },
 {
  "id": "136",
  "ini": "し",
  "name": "初代熱血硬派くにおくん",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/8/7",
  "price": "8900",
  "genre": "アクション・RPG",
  "icNo": "10"
 },
 {
  "id": "137",
  "ini": "す",
  "name": "スーパーパン",
  "maker": "カプコン",
  "releaseDate": "1992/8/7",
  "price": "7500",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "138",
  "ini": "す",
  "name": "スーパープロフェッショナルベースボールⅡ",
  "maker": "ジャレコ",
  "releaseDate": "1992/8/7",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "139",
  "ini": "す",
  "name": "スーパー桃太郎電鉄Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1992/8/7",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "140",
  "ini": "す",
  "name": "スピンディジー・ワールド",
  "maker": "アスキー",
  "releaseDate": "1992/8/7",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "141",
  "ini": "は",
  "name": "パイプドリーム",
  "maker": "BPS",
  "releaseDate": "1992/8/7",
  "price": "7500",
  "genre": "パズル",
  "icNo": "52"
 },
 {
  "id": "142",
  "ini": "ふ",
  "name": "ファランクス",
  "maker": "ケムコ",
  "releaseDate": "1992/8/7",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "143",
  "ini": "ほ",
  "name": "ホーム・アローン",
  "maker": "アルトロン",
  "releaseDate": "1992/8/11",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "144",
  "ini": "き",
  "name": "キン肉マン DIRTY CHALLENGER",
  "maker": "ユタカ",
  "releaseDate": "1992/8/21",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "す",
  "name": "スーパー麻雀",
  "maker": "アイマックス",
  "releaseDate": "1992/8/22",
  "price": "8000",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "146",
  "ini": "す",
  "name": "スーパーマリオカート",
  "maker": "任天堂",
  "releaseDate": "1992/8/27",
  "price": "8900",
  "genre": "レース・3D",
  "icNo": "30"
 },
 {
  "id": "147",
  "ini": "う",
  "name": "ウルトラベースボール 実名版",
  "maker": "マイクロアカデミー",
  "releaseDate": "1992/8/28",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "148",
  "ini": "し",
  "name": "CBキャラウォーズ 失われたギャ～グ",
  "maker": "バンプレスト",
  "releaseDate": "1992/8/28",
  "price": "8500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "149",
  "ini": "あ",
  "name": "アクスレイ",
  "maker": "コナミ",
  "releaseDate": "1992/9/11",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "あ",
  "name": "アクロバットミッション",
  "maker": "テイチク",
  "releaseDate": "1992/9/11",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "65"
 },
 {
  "id": "151",
  "ini": "え",
  "name": "SD機動戦士ガンダム V作戦始動",
  "maker": "エンジェル",
  "releaseDate": "1992/9/12",
  "price": "7800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "152",
  "ini": "す",
  "name": "スーパー麻雀大会",
  "maker": "光栄",
  "releaseDate": "1992/9/12",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "153",
  "ini": "す",
  "name": "SUPER GACHAPON WORLD SDガンダムX",
  "maker": "ユタカ",
  "releaseDate": "1992/9/18",
  "price": "9500",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "154",
  "ini": "わ",
  "name": "ワイアラエの奇蹟",
  "maker": "T&Eソフト",
  "releaseDate": "1992/9/18",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "155",
  "ini": "て",
  "name": "提督の決断",
  "maker": "光栄",
  "releaseDate": "1992/9/24",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "40"
 },
 {
  "id": "156",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派 麻雀皇位戦",
  "maker": "パルソフト",
  "releaseDate": "1992/9/25",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "157",
  "ini": "き",
  "name": "銀河英雄伝説",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1992/9/25",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "158",
  "ini": "そ",
  "name": "ソニックブラストマン",
  "maker": "タイトー",
  "releaseDate": "1992/9/25",
  "price": "8500",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "159",
  "ini": "た",
  "name": "大戦略エキスパート",
  "maker": "アスキー",
  "releaseDate": "1992/9/25",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "160",
  "ini": "と",
  "name": "ドラゴンクエストⅤ 天空の花嫁",
  "maker": "エニックス",
  "releaseDate": "1992/9/27",
  "price": "9600",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "161",
  "ini": "す",
  "name": "スカイミッション",
  "maker": "ナムコ",
  "releaseDate": "1992/9/29",
  "price": "8300",
  "genre": "シミュレーション",
  "icNo": "61"
 },
 {
  "id": "162",
  "ini": "ろ",
  "name": "ロードモナーク",
  "maker": "エポック社",
  "releaseDate": "1992/10/9",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "163",
  "ini": "り",
  "name": "リターン・オブ・双截龍",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/10/16",
  "price": "8600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "164",
  "ini": "す",
  "name": "スーパーロイヤルブラッド",
  "maker": "光栄",
  "releaseDate": "1992/10/22",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "33"
 },
 {
  "id": "165",
  "ini": "あ",
  "name": "アダムス・ファミリー",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1992/10/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "166",
  "ini": "す",
  "name": "スーパーF1サーカス リミテッド",
  "maker": "日本物産",
  "releaseDate": "1992/10/23",
  "price": "9200",
  "genre": "レース",
  "icNo": "11"
 },
 {
  "id": "167",
  "ini": "こ",
  "name": "コズモギャング ザ ビデオ",
  "maker": "ナムコ",
  "releaseDate": "1992/10/29",
  "price": "7900",
  "genre": "シューティング",
  "icNo": "44"
 },
 {
  "id": "168",
  "ini": "さ",
  "name": "サイバーナイト",
  "maker": "トンキンハウス",
  "releaseDate": "1992/10/30",
  "price": "8900",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "169",
  "ini": "し",
  "name": "真・女神転生",
  "maker": "アトラス",
  "releaseDate": "1992/10/30",
  "price": "9800",
  "genre": "RPG",
  "icNo": "33"
 },
 {
  "id": "170",
  "ini": "す",
  "name": "スーパーリニアボール",
  "maker": "ヒロ",
  "releaseDate": "1992/11/6",
  "price": "8000",
  "genre": "アクション・3D",
  "icNo": "43"
 },
 {
  "id": "171",
  "ini": "さ",
  "name": "三國志Ⅲ",
  "maker": "光栄",
  "releaseDate": "1992/11/8",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "10"
 },
 {
  "id": "172",
  "ini": "ひ",
  "name": "飛龍の拳S ハイパーバージョン",
  "maker": "カルチャーブレーン",
  "releaseDate": "1992/11/11",
  "price": "9700",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "173",
  "ini": "す",
  "name": "SUPER SWIV",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/11/13",
  "price": "9500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "174",
  "ini": "れ",
  "name": "レナス 古代機械の記憶",
  "maker": "アスミック",
  "releaseDate": "1992/11/13",
  "price": "9600",
  "genre": "RPG",
  "icNo": "55"
 },
 {
  "id": "175",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズ",
  "maker": "トミー",
  "releaseDate": "1992/11/20",
  "price": "8700",
  "genre": "クイズ",
  "icNo": "0"
 },
 {
  "id": "176",
  "ini": "う",
  "name": "ウィザードリィⅤ 災禍の中心",
  "maker": "アスキー",
  "releaseDate": "1992/11/20",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "た",
  "name": "対決!! ブラスナンバーズ",
  "maker": "レーザーソフト",
  "releaseDate": "1992/11/20",
  "price": "8500",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "178",
  "ini": "ひ",
  "name": "ヒーロー戦記 プロジェクト オリュンポス",
  "maker": "バンプレスト",
  "releaseDate": "1992/11/20",
  "price": "9600",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "179",
  "ini": "ひ",
  "name": "ヒューマン・グランプリ",
  "maker": "ヒューマン",
  "releaseDate": "1992/11/20",
  "price": "9700",
  "genre": "レース・3D",
  "icNo": "43"
 },
 {
  "id": "180",
  "ini": "ほ",
  "name": "北斗の拳6 激闘伝承拳 覇王への道",
  "maker": "東映動画",
  "releaseDate": "1992/11/20",
  "price": "8900",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "181",
  "ini": "み",
  "name": "ミッキーのマジカルアドベンチャー",
  "maker": "カプコン",
  "releaseDate": "1992/11/20",
  "price": "8500",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "182",
  "ini": "か",
  "name": "CACOMA KNIGHT",
  "maker": "データム・ポリスター",
  "releaseDate": "1992/11/21",
  "price": "7800",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "183",
  "ini": "あ",
  "name": "アウター・ワールド",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1992/11/27",
  "price": "8800",
  "genre": "アクション・アドベンチャー",
  "icNo": "34"
 },
 {
  "id": "184",
  "ini": "あ",
  "name": "あしたのジョー",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1992/11/27",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "43"
 },
 {
  "id": "185",
  "ini": "か",
  "name": "餓狼伝説 宿命の闘い",
  "maker": "タカラ",
  "releaseDate": "1992/11/27",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "か",
  "name": "ガンフォース",
  "maker": "アイレム",
  "releaseDate": "1992/11/27",
  "price": "8300",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "187",
  "ini": "そ",
  "name": "ソングマスター",
  "maker": "やのまん",
  "releaseDate": "1992/11/27",
  "price": "9000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "188",
  "ini": "は",
  "name": "バルバロッサ",
  "maker": "サミー",
  "releaseDate": "1992/11/27",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "189",
  "ini": "は",
  "name": "バレーボール Twin",
  "maker": "トンキンハウス",
  "releaseDate": "1992/11/27",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "190",
  "ini": "は",
  "name": "パワーアスリート",
  "maker": "KANEKO",
  "releaseDate": "1992/11/27",
  "price": "8500",
  "genre": "対戦格闘",
  "icNo": "60"
 },
 {
  "id": "191",
  "ini": "ろ",
  "name": "ロイヤルコンクエスト",
  "maker": "ジャレコ",
  "releaseDate": "1992/11/27",
  "price": "8500",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "192",
  "ini": "す",
  "name": "スーパーブラックバス",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/12/4",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "193",
  "ini": "せ",
  "name": "芹沢信雄のバーディトライ",
  "maker": "東宝",
  "releaseDate": "1992/12/4",
  "price": "9600",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "194",
  "ini": "み",
  "name": "ミステリーサークル",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1992/12/4",
  "price": "8300",
  "genre": "パズル",
  "icNo": "44"
 },
 {
  "id": "195",
  "ini": "め",
  "name": "メジャータイトル",
  "maker": "アイレム",
  "releaseDate": "1992/12/4",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅤ",
  "maker": "スクウェア",
  "releaseDate": "1992/12/6",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "197",
  "ini": "お",
  "name": "大相撲魂",
  "maker": "タカラ",
  "releaseDate": "1992/12/11",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "34"
 },
 {
  "id": "198",
  "ini": "さ",
  "name": "サイコドリーム",
  "maker": "日本テレネット",
  "releaseDate": "1992/12/11",
  "price": "8900",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "199",
  "ini": "は",
  "name": "白熱プロ野球ガンバリーグ'93",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1992/12/11",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "200",
  "ini": "は",
  "name": "パチ夫くんSPECIAL",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1992/12/11",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "32"
 },
 {
  "id": "201",
  "ini": "は",
  "name": "バトルサッカー フィールドの覇者",
  "maker": "バンプレスト",
  "releaseDate": "1992/12/11",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "202",
  "ini": "へ",
  "name": "弁慶外伝 沙の章",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/12/11",
  "price": "9600",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "203",
  "ini": "き",
  "name": "機動装甲ダイオン",
  "maker": "ビック東海",
  "releaseDate": "1992/12/14",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "204",
  "ini": "あ",
  "name": "アメージングテニス",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "205",
  "ini": "え",
  "name": "SDガンダム外伝2 円卓の騎士",
  "maker": "ユタカ",
  "releaseDate": "1992/12/18",
  "price": "9500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "206",
  "ini": "し",
  "name": "重装機兵ヴァルケン",
  "maker": "日本コンピュータシステム",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "アクション",
  "icNo": "65"
 },
 {
  "id": "207",
  "ini": "す",
  "name": "スーパー大相撲 熱戦大一番",
  "maker": "ナムコ",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "33"
 },
 {
  "id": "208",
  "ini": "す",
  "name": "スーパー・スター・ウォーズ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "す",
  "name": "スーパーテトリス2+ボンブリス",
  "maker": "BPS",
  "releaseDate": "1992/12/18",
  "price": "8500",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "210",
  "ini": "す",
  "name": "スーパーニチブツマージャン",
  "maker": "日本物産",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "43"
 },
 {
  "id": "211",
  "ini": "す",
  "name": "ステルス",
  "maker": "ヘクト",
  "releaseDate": "1992/12/18",
  "price": "9700",
  "genre": "シミュレーション",
  "icNo": "55"
 },
 {
  "id": "212",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ",
  "maker": "コナミ",
  "releaseDate": "1992/12/18",
  "price": "8000",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "213",
  "ini": "た",
  "name": "戦え原始人2 ルーキーの大冒険",
  "maker": "データイースト",
  "releaseDate": "1992/12/18",
  "price": "8000",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "214",
  "ini": "な",
  "name": "中嶋悟監修 SUPER F1 HERO",
  "maker": "バリエ",
  "releaseDate": "1992/12/18",
  "price": "8900",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "215",
  "ini": "な",
  "name": "ナグザット スーパーピンボール 邪鬼破壊",
  "maker": "ナグザット",
  "releaseDate": "1992/12/18",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "43"
 },
 {
  "id": "216",
  "ini": "ふ",
  "name": "フライング ヒーロー ぶぎゅる～の大冒険",
  "maker": "ソフエル",
  "releaseDate": "1992/12/18",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "22"
 },
 {
  "id": "217",
  "ini": "は",
  "name": "半熟英雄 ああ、世界よ半熟なれ…!!",
  "maker": "スクウェア",
  "releaseDate": "1992/12/19",
  "price": "9500",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "218",
  "ini": "よ",
  "name": "46億年物語 はるかなるエデンへ",
  "maker": "エニックス",
  "releaseDate": "1992/12/21",
  "price": "9600",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "219",
  "ini": "き",
  "name": "奇々怪界 謎の黒マント",
  "maker": "ナツメ",
  "releaseDate": "1992/12/22",
  "price": "8500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "220",
  "ini": "し",
  "name": "少年アシベ ゴマちゃんのゆうえんち大冒険",
  "maker": "タカラ",
  "releaseDate": "1992/12/22",
  "price": "7800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "221",
  "ini": "ら",
  "name": "ラッシング・ビート乱 複製都市",
  "maker": "ジャレコ",
  "releaseDate": "1992/12/22",
  "price": "9600",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "222",
  "ini": "る",
  "name": "LOONEY TUNES ロードランナーVSワイリーコヨーテ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/12/22",
  "price": "8600",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "223",
  "ini": "こ",
  "name": "コンバットライブス",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/12/23",
  "price": "9300",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "224",
  "ini": "す",
  "name": "スーパーキックオフ",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1992/12/25",
  "price": "7700",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "225",
  "ini": "す",
  "name": "スーパーバレーⅡ",
  "maker": "ビデオシステム",
  "releaseDate": "1992/12/25",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "226",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング2",
  "maker": "ヒューマン",
  "releaseDate": "1992/12/25",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "227",
  "ini": "た",
  "name": "大爆笑 人生劇場",
  "maker": "タイトー",
  "releaseDate": "1992/12/25",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "228",
  "ini": "て",
  "name": "テクモスーパーNBAバスケットボール",
  "maker": "テクモ",
  "releaseDate": "1992/12/25",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "32"
 },
 {
  "id": "229",
  "ini": "ま",
  "name": "麻雀飛翔伝 哭きの竜",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1992/12/25",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "40"
 },
 {
  "id": "230",
  "ini": "ら",
  "name": "らんま1/2 爆烈乱闘篇",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/12/25",
  "price": "9600",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "さ",
  "name": "ザ・キングオブ・ラリー",
  "maker": "メルダック",
  "releaseDate": "1992/12/28",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "32"
 },
 {
  "id": "232",
  "ini": "し",
  "name": "史上最強のクイズ王決定戦 SUPER",
  "maker": "ヨネザワ",
  "releaseDate": "1992/12/28",
  "price": "8900",
  "genre": "クイズ",
  "icNo": "41"
 },
 {
  "id": "233",
  "ini": "え",
  "name": "エルファリア",
  "maker": "ハドソン",
  "releaseDate": "1993/1/3",
  "price": "9500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "234",
  "ini": "え",
  "name": "エイリアンVSプレデター",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1993/1/8",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "235",
  "ini": "ふ",
  "name": "BURAI 八玉の勇士伝説",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1993/1/14",
  "price": "9800",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "236",
  "ini": "よ",
  "name": "ヨーロッパ戦線",
  "maker": "光栄",
  "releaseDate": "1993/1/16",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "52"
 },
 {
  "id": "237",
  "ini": "と",
  "name": "ドラゴンズ・アース",
  "maker": "ヒューマン",
  "releaseDate": "1993/1/22",
  "price": "8500",
  "genre": "シミュレーション",
  "icNo": "44"
 },
 {
  "id": "238",
  "ini": "ほ",
  "name": "ポピュラス2",
  "maker": "イマジニア",
  "releaseDate": "1993/1/22",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "239",
  "ini": "ま",
  "name": "マイト&マジック BOOKⅡ",
  "maker": "ロジーク",
  "releaseDate": "1993/1/22",
  "price": "8800",
  "genre": "RPG・3D",
  "icNo": "21"
 },
 {
  "id": "240",
  "ini": "ま",
  "name": "負けるな！魔剣道",
  "maker": "データム・ポリスター",
  "releaseDate": "1993/1/22",
  "price": "8800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "241",
  "ini": "う",
  "name": "うしおととら",
  "maker": "ユタカ",
  "releaseDate": "1993/1/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "242",
  "ini": "き",
  "name": "Q*bert3",
  "maker": "バップ",
  "releaseDate": "1993/1/29",
  "price": "7000",
  "genre": "パズル",
  "icNo": "54"
 },
 {
  "id": "243",
  "ini": "く",
  "name": "クリスティーワールド",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/1/29",
  "price": "8000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "244",
  "ini": "の",
  "name": "信長公記",
  "maker": "やのまん",
  "releaseDate": "1993/1/29",
  "price": "12500",
  "genre": "シミュレーション",
  "icNo": "64"
 },
 {
  "id": "245",
  "ini": "す",
  "name": "スーパー倉庫番",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/1/29",
  "price": "7800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "246",
  "ini": "す",
  "name": "スーパービックリマン",
  "maker": "ベック",
  "releaseDate": "1993/1/29",
  "price": "7800",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "247",
  "ini": "な",
  "name": "ナムコットオープン",
  "maker": "ナムコ",
  "releaseDate": "1993/1/29",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "63"
 },
 {
  "id": "248",
  "ini": "わ",
  "name": "ワールドクラスラグビー",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1993/1/29",
  "price": "7900",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "249",
  "ini": "け",
  "name": "ゲゲゲの鬼太郎 復活！天魔大王",
  "maker": "バンダイ",
  "releaseDate": "1993/2/5",
  "price": "8800",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "250",
  "ini": "ふ",
  "name": "プロフットボール'93",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/2/12",
  "price": "8900",
  "genre": "スポーツ・3D",
  "icNo": "61"
 },
 {
  "id": "251",
  "ini": "う",
  "name": "ウォーリーをさがせ！絵本の国の大冒険",
  "maker": "トミー",
  "releaseDate": "1993/2/19",
  "price": "9500",
  "genre": "アドベンチャー",
  "icNo": "10"
 },
 {
  "id": "252",
  "ini": "き",
  "name": "喜国雅彦の雀闘士 銅鑼王",
  "maker": "POW",
  "releaseDate": "1993/2/19",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "253",
  "ini": "こ",
  "name": "鋼鉄の騎士",
  "maker": "アスミック",
  "releaseDate": "1993/2/19",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "254",
  "ini": "と",
  "name": "ドラえもん のび太と妖精の国",
  "maker": "エポック社",
  "releaseDate": "1993/2/19",
  "price": "8000",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "255",
  "ini": "す",
  "name": "スターフォックス",
  "maker": "任天堂",
  "releaseDate": "1993/2/21",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "256",
  "ini": "え",
  "name": "NBAプロバスケットボール ブルズVSブレイザーズ",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/2/26",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "257",
  "ini": "え",
  "name": "F-1 GRAND PRIX PARTⅡ",
  "maker": "ビデオシステム",
  "releaseDate": "1993/2/26",
  "price": "9800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "258",
  "ini": "お",
  "name": "織田信長 覇王の軍団",
  "maker": "エンジェル",
  "releaseDate": "1993/2/26",
  "price": "9500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "259",
  "ini": "こ",
  "name": "コズモギャング ザ パズル",
  "maker": "ナムコ",
  "releaseDate": "1993/2/26",
  "price": "7900",
  "genre": "パズル",
  "icNo": "30"
 },
 {
  "id": "260",
  "ini": "さ",
  "name": "サーク The Art of Visual Stage.",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/2/26",
  "price": "9600",
  "genre": "アクション・RPG",
  "icNo": "50"
 },
 {
  "id": "261",
  "ini": "し",
  "name": "シムアント",
  "maker": "イマジニア",
  "releaseDate": "1993/2/26",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "262",
  "ini": "は",
  "name": "バートの不思議な夢の大冒険",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/2/26",
  "price": "8800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "263",
  "ini": "は",
  "name": "バットマン リターンズ",
  "maker": "コナミ",
  "releaseDate": "1993/2/26",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "264",
  "ini": "は",
  "name": "バトルテック",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/2/26",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "265",
  "ini": "り",
  "name": "リーディングカンパニー",
  "maker": "光栄",
  "releaseDate": "1993/2/26",
  "price": "12800",
  "genre": "経営・シミュレーション",
  "icNo": "55"
 },
 {
  "id": "266",
  "ini": "あ",
  "name": "アルバートオデッセイ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/3/5",
  "price": "9600",
  "genre": "シュミレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "267",
  "ini": "い",
  "name": "イーハトーヴォ物語",
  "maker": "ヘクト",
  "releaseDate": "1993/3/5",
  "price": "9700",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "268",
  "ini": "え",
  "name": "エキゾースト・ヒート F1ドライバーへの軌跡",
  "maker": "セタ",
  "releaseDate": "1993/3/5",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "269",
  "ini": "し",
  "name": "ジョジョの奇妙な冒険",
  "maker": "コブラチーム",
  "releaseDate": "1993/3/5",
  "price": "9500",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "270",
  "ini": "す",
  "name": "スーパーキックボクシング",
  "maker": "エレクトロ・ブレイン・ジャパン",
  "releaseDate": "1993/3/5",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "44"
 },
 {
  "id": "271",
  "ini": "て",
  "name": "デビルズコース",
  "maker": "T&Eソフト",
  "releaseDate": "1993/3/5",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "272",
  "ini": "め",
  "name": "メタルマックス2",
  "maker": "データイースト",
  "releaseDate": "1993/3/5",
  "price": "9500",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "273",
  "ini": "も",
  "name": "モノポリー",
  "maker": "トミー",
  "releaseDate": "1993/3/5",
  "price": "9700",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "274",
  "ini": "え",
  "name": "EDONO牙",
  "maker": "マイクロワールド",
  "releaseDate": "1993/3/12",
  "price": "8900",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "275",
  "ini": "か",
  "name": "カリフォルニアゲームズⅡ",
  "maker": "ヘクト",
  "releaseDate": "1993/3/12",
  "price": "8800",
  "genre": "アクション・3D",
  "icNo": "1"
 },
 {
  "id": "276",
  "ini": "す",
  "name": "スーパーファミスタ2",
  "maker": "ナムコ",
  "releaseDate": "1993/3/12",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "277",
  "ini": "て",
  "name": "伝説のオウガバトル",
  "maker": "クエスト",
  "releaseDate": "1993/3/12",
  "price": "9600",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "278",
  "ini": "に",
  "name": "2020年スーパーベースボール",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1993/3/12",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "42"
 },
 {
  "id": "279",
  "ini": "し",
  "name": "ジャングルウォーズ2 古代魔法アティモスの謎",
  "maker": "ポニーキャニオン",
  "releaseDate": "1993/3/19",
  "price": "9500",
  "genre": "RPG",
  "icNo": "30"
 },
 {
  "id": "280",
  "ini": "ち",
  "name": "超魔界大戦！どらぼっちゃん",
  "maker": "ナグザット",
  "releaseDate": "1993/3/19",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "281",
  "ini": "な",
  "name": "ナイジェル・マンセル F1チャレンジ",
  "maker": "インフォコム",
  "releaseDate": "1993/3/19",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "282",
  "ini": "は",
  "name": "バイオメタル",
  "maker": "アテナ",
  "releaseDate": "1993/3/19",
  "price": "8980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "283",
  "ini": "ゆ",
  "name": "USA アイスホッケー",
  "maker": "ジャレコ",
  "releaseDate": "1993/3/19",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "284",
  "ini": "と",
  "name": "ドラゴンボールZ 超武闘伝",
  "maker": "バンダイ",
  "releaseDate": "1993/3/20",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "31"
 },
 {
  "id": "285",
  "ini": "す",
  "name": "スーパー蒼き狼と白き牝鹿 元朝秘史",
  "maker": "光栄",
  "releaseDate": "1993/3/25",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "53"
 },
 {
  "id": "286",
  "ini": "す",
  "name": "スーパーワギャンランド2",
  "maker": "ナムコ",
  "releaseDate": "1993/3/25",
  "price": "8300",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "287",
  "ini": "い",
  "name": "インターナショナル・テニスツアー",
  "maker": "マイクロワールド",
  "releaseDate": "1993/3/26",
  "price": "8900",
  "genre": "スポーツ・3D",
  "icNo": "55"
 },
 {
  "id": "288",
  "ini": "う",
  "name": "ウルトラセブン",
  "maker": "バンダイ",
  "releaseDate": "1993/3/26",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "52"
 },
 {
  "id": "289",
  "ini": "さ",
  "name": "ザ・グレイトバトルⅢ",
  "maker": "バンプレスト",
  "releaseDate": "1993/3/26",
  "price": "8700",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "290",
  "ini": "さ",
  "name": "ザ・心理ゲーム 悪魔のココロジー",
  "maker": "ヴィジット",
  "releaseDate": "1993/3/26",
  "price": "9800",
  "genre": "その他",
  "icNo": "12"
 },
 {
  "id": "291",
  "ini": "つ",
  "name": "つっぱり大相撲 立身出世編",
  "maker": "テクモ",
  "releaseDate": "1993/3/26",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "292",
  "ini": "て",
  "name": "デザートストライク 湾岸作戦",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/3/26",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "55"
 },
 {
  "id": "293",
  "ini": "て",
  "name": "デッドダンス",
  "maker": "ジャレコ",
  "releaseDate": "1993/3/26",
  "price": "9700",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "294",
  "ini": "の",
  "name": "ノイギーア 海と風の鼓動",
  "maker": "ウルフ・チーム",
  "releaseDate": "1993/3/26",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "50"
 },
 {
  "id": "295",
  "ini": "は",
  "name": "パワーモンガー 魔将の謀略",
  "maker": "イマジニア",
  "releaseDate": "1993/3/26",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "20"
 },
 {
  "id": "296",
  "ini": "ふ",
  "name": "ブルースブラザース",
  "maker": "ケムコ",
  "releaseDate": "1993/3/26",
  "price": "7800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "297",
  "ini": "ほ",
  "name": "Pop'nツインビー",
  "maker": "コナミ",
  "releaseDate": "1993/3/26",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "12"
 },
 {
  "id": "298",
  "ini": "え",
  "name": "エアーマネジメントⅡ 航空王をめざせ",
  "maker": "光栄",
  "releaseDate": "1993/4/2",
  "price": "12800",
  "genre": "経営・シミュレーション",
  "icNo": "44"
 },
 {
  "id": "299",
  "ini": "き",
  "name": "キャプテン翼Ⅳ プロのライバルたち",
  "maker": "テクモ",
  "releaseDate": "1993/4/3",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "300",
  "ini": "ふ",
  "name": "ブレス オブ ファイア 竜の戦士",
  "maker": "カプコン",
  "releaseDate": "1993/4/3",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "301",
  "ini": "た",
  "name": "太閤立志伝",
  "maker": "光栄",
  "releaseDate": "1993/4/7",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "21"
 },
 {
  "id": "302",
  "ini": "あ",
  "name": "アクション パチ夫",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/4/9",
  "price": "9500",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "303",
  "ini": "さ",
  "name": "The麻雀 闘牌伝",
  "maker": "ビデオシステム",
  "releaseDate": "1993/4/16",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "304",
  "ini": "て",
  "name": "デュアルオーブ 聖霊珠伝説",
  "maker": "アイマックス",
  "releaseDate": "1993/4/16",
  "price": "9700",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "305",
  "ini": "い",
  "name": "怒りの要塞",
  "maker": "ジャレコ",
  "releaseDate": "1993/4/23",
  "price": "8700",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "306",
  "ini": "え",
  "name": "エルナード",
  "maker": "ゲームプラン21",
  "releaseDate": "1993/4/23",
  "price": "9600",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "307",
  "ini": "す",
  "name": "スーパーバトルタンク",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/4/23",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "43"
 },
 {
  "id": "308",
  "ini": "り",
  "name": "龍騎兵団ダンザルブ",
  "maker": "ユタカ",
  "releaseDate": "1993/4/23",
  "price": "9500",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "309",
  "ini": "す",
  "name": "スーパーダンクスター",
  "maker": "サミー",
  "releaseDate": "1993/4/28",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "310",
  "ini": "す",
  "name": "スーパーボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "1993/4/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "311",
  "ini": "た",
  "name": "対局囲碁ゴライアス",
  "maker": "BPS",
  "releaseDate": "1993/5/14",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "31"
 },
 {
  "id": "312",
  "ini": "は",
  "name": "バーコードバトラー戦記 スーパー戦士出撃せよ！",
  "maker": "エポック社",
  "releaseDate": "1993/5/14",
  "price": "7680",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "313",
  "ini": "え",
  "name": "NBAオールスターチャレンジ",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/5/21",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "314",
  "ini": "ふ",
  "name": "ファイナルファイト2",
  "maker": "カプコン",
  "releaseDate": "1993/5/22",
  "price": "9000",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "315",
  "ini": "せ",
  "name": "セプテントリオン",
  "maker": "ヒューマン",
  "releaseDate": "1993/5/28",
  "price": "8500",
  "genre": "アクション・アドベンチャー",
  "icNo": "32"
 },
 {
  "id": "316",
  "ini": "は",
  "name": "パチンコ物語 パチスロもあるでよ!!",
  "maker": "KSS",
  "releaseDate": "1993/5/28",
  "price": "9800",
  "genre": "パチンコ・スロット",
  "icNo": "1"
 },
 {
  "id": "317",
  "ini": "と",
  "name": "ドラゴンスレイヤー 英雄伝説Ⅱ",
  "maker": "エポック社",
  "releaseDate": "1993/6/4",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "318",
  "ini": "こ",
  "name": "コスモポリス ギャリバンⅡ",
  "maker": "日本物産",
  "releaseDate": "1993/6/11",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "319",
  "ini": "す",
  "name": "スーパーフォーメーションサッカーⅡ",
  "maker": "ヒューマン",
  "releaseDate": "1993/6/11",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "320",
  "ini": "ふ",
  "name": "プロ麻雀 極",
  "maker": "アテナ",
  "releaseDate": "1993/6/11",
  "price": "9600",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "し",
  "name": "神聖紀オデッセリア",
  "maker": "ビック東海",
  "releaseDate": "1993/6/18",
  "price": "9500",
  "genre": "RPG",
  "icNo": "22"
 },
 {
  "id": "322",
  "ini": "は",
  "name": "早指し二段 森田将棋",
  "maker": "セタ",
  "releaseDate": "1993/6/18",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "323",
  "ini": "す",
  "name": "スーパースコープ6（スーパースコープ専用）",
  "maker": "任天堂",
  "releaseDate": "1993/6/21",
  "price": "9800",
  "genre": "ガンシューティング",
  "icNo": "44"
 },
 {
  "id": "324",
  "ini": "す",
  "name": "スペースバズーカ（スーパースコープ専用）",
  "maker": "任天堂",
  "releaseDate": "1993/6/21",
  "price": "6500",
  "genre": "ガンシューティング",
  "icNo": "44"
 },
 {
  "id": "325",
  "ini": "え",
  "name": "エストポリス伝記",
  "maker": "タイトー",
  "releaseDate": "1993/6/25",
  "price": "8900",
  "genre": "RPG",
  "icNo": "40"
 },
 {
  "id": "326",
  "ini": "け",
  "name": "激突弾丸自動車決戦 バトルモービル",
  "maker": "システムサコム",
  "releaseDate": "1993/6/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "327",
  "ini": "さ",
  "name": "三国志正史 天舞スピリッツ",
  "maker": "ウルフ・チーム",
  "releaseDate": "1993/6/25",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "30"
 },
 {
  "id": "328",
  "ini": "し",
  "name": "GP-1",
  "maker": "アトラス",
  "releaseDate": "1993/6/25",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "329",
  "ini": "し",
  "name": "シルヴァ・サーガⅡ",
  "maker": "セタ",
  "releaseDate": "1993/6/25",
  "price": "9800",
  "genre": "RPG",
  "icNo": "31"
 },
 {
  "id": "330",
  "ini": "す",
  "name": "スーパーファミリーテニス",
  "maker": "ナムコ",
  "releaseDate": "1993/6/25",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "331",
  "ini": "と",
  "name": "トムとジェリー",
  "maker": "アルトロン",
  "releaseDate": "1993/6/25",
  "price": "8900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "332",
  "ini": "と",
  "name": "ドラゴンズ マジック",
  "maker": "コナミ",
  "releaseDate": "1993/6/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "333",
  "ini": "ま",
  "name": "マジンガーZ",
  "maker": "バンダイ",
  "releaseDate": "1993/6/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "334",
  "ini": "ふ",
  "name": "ファーストサムライ",
  "maker": "ケムコ",
  "releaseDate": "1993/7/2",
  "price": "7800",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "335",
  "ini": "え",
  "name": "エイリアン3",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/7/9",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "か",
  "name": "仮面ライダーSD 出撃!!ライダーマシン",
  "maker": "ユタカ",
  "releaseDate": "1993/7/9",
  "price": "8800",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "337",
  "ini": "す",
  "name": "スーパーハイインパクト",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/7/9",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "338",
  "ini": "よ",
  "name": "ヨッシーのクッキー",
  "maker": "BPS",
  "releaseDate": "1993/7/9",
  "price": "6600",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "339",
  "ini": "す",
  "name": "ストリートファイターⅡターボ",
  "maker": "カプコン",
  "releaseDate": "1993/7/11",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "340",
  "ini": "よ",
  "name": "ヨッシーのロードハンティング（スーパースコープ専用）",
  "maker": "任天堂",
  "releaseDate": "1993/7/14",
  "price": "6500",
  "genre": "ガンシューティング",
  "icNo": "31"
 },
 {
  "id": "341",
  "ini": "す",
  "name": "スーパーマリオコレクション",
  "maker": "任天堂",
  "releaseDate": "1993/7/14",
  "price": "9800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "342",
  "ini": "さ",
  "name": "サンリオワールド スマッシュボール！",
  "maker": "キャラクターソフト",
  "releaseDate": "1993/7/16",
  "price": "6980",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "343",
  "ini": "す",
  "name": "スーパーエアダイバー",
  "maker": "アスミック",
  "releaseDate": "1993/7/16",
  "price": "8900",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "344",
  "ini": "せ",
  "name": "全日本プロレス",
  "maker": "日本コンピュータシステム",
  "releaseDate": "1993/7/16",
  "price": "9900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "345",
  "ini": "て",
  "name": "デスブレイド",
  "maker": "アイマックス",
  "releaseDate": "1993/7/16",
  "price": "9700",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "346",
  "ini": "ま",
  "name": "マジックジョンソンのスーパースラムダンク",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1993/7/16",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "13"
 },
 {
  "id": "347",
  "ini": "も",
  "name": "魍魎戦記MADARA2",
  "maker": "コナミ",
  "releaseDate": "1993/7/16",
  "price": "9800",
  "genre": "RPG",
  "icNo": "22"
 },
 {
  "id": "348",
  "ini": "わ",
  "name": "ワールドサッカー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/7/16",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "65"
 },
 {
  "id": "349",
  "ini": "う",
  "name": "ウイングコマンダー",
  "maker": "アスキー",
  "releaseDate": "1993/7/23",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "350",
  "ini": "す",
  "name": "スーパー・ジェームスポンドⅡ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/7/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "351",
  "ini": "す",
  "name": "スーパー・バック・トゥ・ザ・フューチャーⅡ",
  "maker": "東芝EMI",
  "releaseDate": "1993/7/23",
  "price": "9000",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "352",
  "ini": "た",
  "name": "第3次スーパーロボット大戦",
  "maker": "バンプレスト",
  "releaseDate": "1993/7/23",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "353",
  "ini": "た",
  "name": "WWFロイヤルランブル",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/7/23",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "354",
  "ini": "は",
  "name": "バズー！魔法世界",
  "maker": "ホット・ビィ",
  "releaseDate": "1993/7/23",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "355",
  "ini": "は",
  "name": "バトルドッジボールⅡ",
  "maker": "バンプレスト",
  "releaseDate": "1993/7/23",
  "price": "9600",
  "genre": "スポーツ",
  "icNo": "22"
 },
 {
  "id": "356",
  "ini": "め",
  "name": "メガロマニア 時空大戦略",
  "maker": "イマジニア",
  "releaseDate": "1993/7/23",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "357",
  "ini": "す",
  "name": "スーパーF1サーカス2",
  "maker": "日本物産",
  "releaseDate": "1993/7/29",
  "price": "9500",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "358",
  "ini": "う",
  "name": "宇宙の騎士テッカマンブレード",
  "maker": "ベック",
  "releaseDate": "1993/7/30",
  "price": "8800",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "359",
  "ini": "く",
  "name": "クレヨンしんちゃん 嵐を呼ぶ園児",
  "maker": "バンダイ",
  "releaseDate": "1993/7/30",
  "price": "9500",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "360",
  "ini": "そ",
  "name": "ソニックウイングス",
  "maker": "ビデオシステム",
  "releaseDate": "1993/7/30",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "55"
 },
 {
  "id": "361",
  "ini": "た",
  "name": "大爆笑 人生劇場 ドキドキ青春編",
  "maker": "タイトー",
  "releaseDate": "1993/7/30",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "362",
  "ini": "に",
  "name": "ニトロパンクス マイトヘッズ",
  "maker": "アイレム",
  "releaseDate": "1993/7/30",
  "price": "8800",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "363",
  "ini": "は",
  "name": "パティームーン",
  "maker": "バリエ",
  "releaseDate": "1993/7/30",
  "price": "8900",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "364",
  "ini": "ひ",
  "name": "美少女雀士スーチーパイ",
  "maker": "ジャレコ",
  "releaseDate": "1993/7/30",
  "price": "9700",
  "genre": "テーブル",
  "icNo": "32"
 },
 {
  "id": "365",
  "ini": "す",
  "name": "スーパー信長の野望 全・国・版",
  "maker": "光栄",
  "releaseDate": "1993/8/5",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "33"
 },
 {
  "id": "366",
  "ini": "お",
  "name": "大仁田厚 FMW",
  "maker": "ポニーキャニオン",
  "releaseDate": "1993/8/6",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "367",
  "ini": "く",
  "name": "くにおくんのドッジボールだよ全員集合！",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/8/6",
  "price": "9600",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "368",
  "ini": "し",
  "name": "Jリーグサッカー プライムゴール",
  "maker": "ナムコ",
  "releaseDate": "1993/8/6",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "32"
 },
 {
  "id": "369",
  "ini": "す",
  "name": "スーパーパワーリーグ",
  "maker": "ハドソン",
  "releaseDate": "1993/8/6",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "370",
  "ini": "せ",
  "name": "聖剣伝説2",
  "maker": "スクウェア",
  "releaseDate": "1993/8/6",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "371",
  "ini": "そ",
  "name": "ソード・ワールドSFC",
  "maker": "T&Eソフト",
  "releaseDate": "1993/8/6",
  "price": "9800",
  "genre": "RPG",
  "icNo": "43"
 },
 {
  "id": "372",
  "ini": "ひ",
  "name": "ヒューマンベースボール",
  "maker": "ヒューマン",
  "releaseDate": "1993/8/6",
  "price": "8600",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "373",
  "ini": "す",
  "name": "スーパー競馬",
  "maker": "アイマックス",
  "releaseDate": "1993/8/10",
  "price": "9700",
  "genre": "育成・シュミレーション",
  "icNo": "61"
 },
 {
  "id": "374",
  "ini": "わ",
  "name": "ワールドヒーローズ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/8/12",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "375",
  "ini": "す",
  "name": "スーパースラップショット",
  "maker": "アルトロン",
  "releaseDate": "1993/8/20",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "376",
  "ini": "え",
  "name": "MVPベースボール",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/8/27",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "377",
  "ini": "さ",
  "name": "サラブレッドブリーダー",
  "maker": "ヘクト",
  "releaseDate": "1993/8/27",
  "price": "9700",
  "genre": "育成・シュミレーション",
  "icNo": "44"
 },
 {
  "id": "378",
  "ini": "し",
  "name": "樹帝戦紀",
  "maker": "エニックス",
  "releaseDate": "1993/8/27",
  "price": "9600",
  "genre": "シミュレーション",
  "icNo": "50"
 },
 {
  "id": "379",
  "ini": "え",
  "name": "X ZONE（スーパースコープ専用）",
  "maker": "ケムコ",
  "releaseDate": "1993/8/27",
  "price": "6500",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "380",
  "ini": "て",
  "name": "デストラクティブ（スーパースコープ専用）",
  "maker": "バンダイ",
  "releaseDate": "1993/8/27",
  "price": "6800",
  "genre": "ガンシューティング",
  "icNo": "56"
 },
 {
  "id": "381",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン",
  "maker": "エンジェル",
  "releaseDate": "1993/8/27",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "382",
  "ini": "ま",
  "name": "マリオとワリオ",
  "maker": "任天堂",
  "releaseDate": "1993/8/27",
  "price": "6800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "383",
  "ini": "す",
  "name": "スーパータリカン",
  "maker": "トンキンハウス",
  "releaseDate": "1993/9/3",
  "price": "7500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "384",
  "ini": "う",
  "name": "ウイニングポスト",
  "maker": "光栄",
  "releaseDate": "1993/9/10",
  "price": "12800",
  "genre": "育成・シュミレーション",
  "icNo": "62"
 },
 {
  "id": "385",
  "ini": "さ",
  "name": "サンダーバード 国際救助隊出動せよ!!",
  "maker": "コブラチーム",
  "releaseDate": "1993/9/10",
  "price": "8800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "386",
  "ini": "み",
  "name": "ミスティッククエスト",
  "maker": "スクウェア",
  "releaseDate": "1993/9/10",
  "price": "7900",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "387",
  "ini": "ら",
  "name": "ラスベガスドリーム",
  "maker": "イマジニア",
  "releaseDate": "1993/9/10",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "388",
  "ini": "し",
  "name": "新日本プロレスリング 超戦士 IN 闘強導夢",
  "maker": "バリエ",
  "releaseDate": "1993/9/14",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "50"
 },
 {
  "id": "389",
  "ini": "え",
  "name": "NFL フットボール",
  "maker": "コナミ",
  "releaseDate": "1993/9/17",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "390",
  "ini": "か",
  "name": "課長 島耕作",
  "maker": "ユタカ",
  "releaseDate": "1993/9/17",
  "price": "9800",
  "genre": "アドベンチャー",
  "icNo": "61"
 },
 {
  "id": "391",
  "ini": "ふ",
  "name": "ファイナルセット",
  "maker": "フォーラム",
  "releaseDate": "1993/9/17",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "392",
  "ini": "と",
  "name": "トルネコの大冒険 不思議のダンジョン",
  "maker": "チュンソフト",
  "releaseDate": "1993/9/19",
  "price": "9600",
  "genre": "ローグライク・RPG",
  "icNo": "42"
 },
 {
  "id": "393",
  "ini": "せ",
  "name": "戦国伝承",
  "maker": "データイースト",
  "releaseDate": "1993/9/19",
  "price": "8800",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "394",
  "ini": "は",
  "name": "遙かなるオーガスタ2 マスターズ",
  "maker": "T&Eソフト",
  "releaseDate": "1993/9/22",
  "price": "9900",
  "genre": "スポーツ・3D",
  "icNo": "63"
 },
 {
  "id": "395",
  "ini": "え",
  "name": "SD機動戦士ガンダム2",
  "maker": "エンジェル",
  "releaseDate": "1993/9/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "396",
  "ini": "こ",
  "name": "GS美神 除霊師はナイスバディ",
  "maker": "バナレックス",
  "releaseDate": "1993/9/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "397",
  "ini": "こ",
  "name": "GO！GO！ドッジリーグ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/9/24",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "398",
  "ini": "た",
  "name": "ダライアスフォース",
  "maker": "タイトー",
  "releaseDate": "1993/9/24",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "56"
 },
 {
  "id": "399",
  "ini": "ほ",
  "name": "本格麻雀 徹萬",
  "maker": "ナグザット",
  "releaseDate": "1993/9/24",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "400",
  "ini": "ひ",
  "name": "東尾修監修 スーパープロ野球スタジアム",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1993/9/30",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "401",
  "ini": "す",
  "name": "スーパー3Dベースボール",
  "maker": "ジャレコ",
  "releaseDate": "1993/10/1",
  "price": "12800",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "402",
  "ini": "と",
  "name": "TRINEA",
  "maker": "やのまん",
  "releaseDate": "1993/10/1",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "53"
 },
 {
  "id": "403",
  "ini": "れ",
  "name": "レッド・オクトーバー",
  "maker": "アルトロン",
  "releaseDate": "1993/10/1",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "404",
  "ini": "す",
  "name": "スーパー競走馬 風のシルフィード",
  "maker": "キングレコード",
  "releaseDate": "1993/10/8",
  "price": "9300",
  "genre": "アクション・育成",
  "icNo": "64"
 },
 {
  "id": "405",
  "ini": "は",
  "name": "バイキングの大迷惑",
  "maker": "T&Eソフト",
  "releaseDate": "1993/10/8",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "406",
  "ini": "す",
  "name": "スズカエイトアワーズ",
  "maker": "ナムコ",
  "releaseDate": "1993/10/15",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "53"
 },
 {
  "id": "407",
  "ini": "す",
  "name": "スーパーカジノ シーザースパレス",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/10/21",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "61"
 },
 {
  "id": "408",
  "ini": "あ",
  "name": "アークススピリッツ",
  "maker": "サミー",
  "releaseDate": "1993/10/22",
  "price": "8900",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "409",
  "ini": "み",
  "name": "ミラクル☆ガールズ",
  "maker": "タカラ",
  "releaseDate": "1993/10/22",
  "price": "8800",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "410",
  "ini": "ら",
  "name": "らんま1/2 朱猫団的秘宝",
  "maker": "東宝・小学館プロダクション",
  "releaseDate": "1993/10/22",
  "price": "9800",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "411",
  "ini": "あ",
  "name": "アクトレイザー2 沈黙への聖戦",
  "maker": "エニックス",
  "releaseDate": "1993/10/29",
  "price": "9300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "412",
  "ini": "く",
  "name": "クラシック・ロード",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "育成・シュミレーション",
  "icNo": "61"
 },
 {
  "id": "413",
  "ini": "し",
  "name": "ジミーコナーズのプロテニスツアー",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1993/10/29",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "414",
  "ini": "し",
  "name": "将棋 風林火山",
  "maker": "ポニーキャニオン",
  "releaseDate": "1993/10/29",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "32"
 },
 {
  "id": "415",
  "ini": "す",
  "name": "スーパーチャイニーズワールド2 宇宙一武闘大会",
  "maker": "カルチャーブレーン",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "416",
  "ini": "す",
  "name": "スーパーニチブツマージャン2 全国制覇篇",
  "maker": "日本物産",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "417",
  "ini": "そ",
  "name": "装甲騎兵ボトムズ ザ・バトリングロード",
  "maker": "タカラ",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "55"
 },
 {
  "id": "418",
  "ini": "ち",
  "name": "超時空要塞マクロス スクランブルバルキリー",
  "maker": "バンプレスト",
  "releaseDate": "1993/10/29",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "13"
 },
 {
  "id": "419",
  "ini": "は",
  "name": "はた山ハッチのパロ野球ニュース！実名版",
  "maker": "エポック社",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "420",
  "ini": "ゆ",
  "name": "ユートピア",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1993/10/29",
  "price": "9500",
  "genre": "育成・シュミレーション",
  "icNo": "1"
 },
 {
  "id": "421",
  "ini": "り",
  "name": "龍虎の拳",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "43"
 },
 {
  "id": "422",
  "ini": "あ",
  "name": "アクタリオン",
  "maker": "テクモ",
  "releaseDate": "1993/11/5",
  "price": "8900",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "423",
  "ini": "ふ",
  "name": "ファイナルノックアウト",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1993/11/5",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "424",
  "ini": "か",
  "name": "仮面ライダー",
  "maker": "バンダイ",
  "releaseDate": "1993/11/12",
  "price": "9800",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "425",
  "ini": "す",
  "name": "スーパーUNO",
  "maker": "トミー",
  "releaseDate": "1993/11/12",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "32"
 },
 {
  "id": "426",
  "ini": "そ",
  "name": "ソルスティスⅡ",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1993/11/12",
  "price": "9500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "427",
  "ini": "ふ",
  "name": "ファイナル・ストレッチ",
  "maker": "ロジーク",
  "releaseDate": "1993/11/12",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "10"
 },
 {
  "id": "428",
  "ini": "わ",
  "name": "若貴大相撲 夢の兄弟対決",
  "maker": "イマジニア",
  "releaseDate": "1993/11/12",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "429",
  "ini": "い",
  "name": "イースⅣ MASK OF THE SUN",
  "maker": "トンキンハウス",
  "releaseDate": "1993/11/19",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "430",
  "ini": "は",
  "name": "パチスロ ラブストーリー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/11/19",
  "price": "9800",
  "genre": "スロット",
  "icNo": "44"
 },
 {
  "id": "431",
  "ini": "は",
  "name": "バトル・マスター 究極の戦士たち",
  "maker": "東芝EMI",
  "releaseDate": "1993/11/19",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "432",
  "ini": "ゆ",
  "name": "遊人 雀獣学園",
  "maker": "バリエ",
  "releaseDate": "1993/11/19",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "12"
 },
 {
  "id": "433",
  "ini": "り",
  "name": "リディック・ボウ ボクシング",
  "maker": "マイクロネット",
  "releaseDate": "1993/11/23",
  "price": "8400",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "434",
  "ini": "あ",
  "name": "アクセルブリッド",
  "maker": "トミー",
  "releaseDate": "1993/11/26",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "435",
  "ini": "あ",
  "name": "アラジン",
  "maker": "カプコン",
  "releaseDate": "1993/11/26",
  "price": "9000",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "436",
  "ini": "あ",
  "name": "アルディ ライトフット",
  "maker": "アスキー",
  "releaseDate": "1993/11/26",
  "price": "9800",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "437",
  "ini": "あ",
  "name": "アレサ",
  "maker": "やのまん",
  "releaseDate": "1993/11/26",
  "price": "9800",
  "genre": "RPG",
  "icNo": "42"
 },
 {
  "id": "438",
  "ini": "え",
  "name": "abc マンデーナイトフットボール",
  "maker": "データイースト",
  "releaseDate": "1993/11/26",
  "price": "9000",
  "genre": "スポーツ・3D",
  "icNo": "20"
 },
 {
  "id": "439",
  "ini": "え",
  "name": "F-15スーパーストライクイーグル",
  "maker": "アスミック",
  "releaseDate": "1993/11/26",
  "price": "9600",
  "genre": "シューティング・3D",
  "icNo": "55"
 },
 {
  "id": "440",
  "ini": "お",
  "name": "鬼塚勝也スーパーヴァーチャルボクシング 真拳闘王伝説",
  "maker": "ソフエル",
  "releaseDate": "1993/11/26",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "10"
 },
 {
  "id": "441",
  "ini": "か",
  "name": "餓狼伝説2 新たなる闘い",
  "maker": "タカラ",
  "releaseDate": "1993/11/26",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "442",
  "ini": "し",
  "name": "実戦！パチスロ必勝法！",
  "maker": "サミー",
  "releaseDate": "1993/11/26",
  "price": "9500",
  "genre": "スロット",
  "icNo": "0"
 },
 {
  "id": "443",
  "ini": "す",
  "name": "スーパーH.Q.クリミナルチェイサー",
  "maker": "タイトー",
  "releaseDate": "1993/11/26",
  "price": "8900",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "444",
  "ini": "た",
  "name": "ダイナミックスタジアム",
  "maker": "サミー",
  "releaseDate": "1993/11/26",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "445",
  "ini": "た",
  "name": "武田修宏のスーパーカップサッカー",
  "maker": "ジャレコ",
  "releaseDate": "1993/11/26",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "446",
  "ini": "て",
  "name": "テクモ スーパーボウル",
  "maker": "テクモ",
  "releaseDate": "1993/11/26",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "22"
 },
 {
  "id": "447",
  "ini": "や",
  "name": "ヤダモン ワンダランドドリーム",
  "maker": "徳間書店",
  "releaseDate": "1993/11/26",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "448",
  "ini": "か",
  "name": "ガイア幻想紀",
  "maker": "エニックス",
  "releaseDate": "1993/11/27",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "50"
 },
 {
  "id": "449",
  "ini": "そ",
  "name": "ソウル&ソード",
  "maker": "バンプレスト",
  "releaseDate": "1993/11/30",
  "price": "9800",
  "genre": "RPG",
  "icNo": "34"
 },
 {
  "id": "450",
  "ini": "す",
  "name": "スーパー麻雀2 本格4人打ち！",
  "maker": "アイマックス",
  "releaseDate": "1993/12/2",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "451",
  "ini": "え",
  "name": "NBAプロバスケットボール'94",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/12/3",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "452",
  "ini": "き",
  "name": "喜国雅彦の雀闘士 銅鑼王2",
  "maker": "POW",
  "releaseDate": "1993/12/3",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "54"
 },
 {
  "id": "453",
  "ini": "す",
  "name": "スーパー究極ハリキリスタジアム",
  "maker": "タイトー",
  "releaseDate": "1993/12/3",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "454",
  "ini": "て",
  "name": "ティーンエイジ ミュータント ニンジャ タートルズ ミュータントウォーリアーズ",
  "maker": "コナミ",
  "releaseDate": "1993/12/3",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "10"
 },
 {
  "id": "455",
  "ini": "の",
  "name": "信長の野望 覇王伝",
  "maker": "光栄",
  "releaseDate": "1993/12/9",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "50"
 },
 {
  "id": "456",
  "ini": "あ",
  "name": "R・TYPEⅢ",
  "maker": "アイレム",
  "releaseDate": "1993/12/10",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "52"
 },
 {
  "id": "457",
  "ini": "く",
  "name": "クールスポット",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1993/12/10",
  "price": "8900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "458",
  "ini": "け",
  "name": "マル秘馬券購入術 競馬エイト スペシャル",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1993/12/10",
  "price": "9000",
  "genre": "競馬・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "459",
  "ini": "け",
  "name": "決戦！ドカポン王国Ⅳ 伝説の勇者たち",
  "maker": "アスミック",
  "releaseDate": "1993/12/10",
  "price": "8900",
  "genre": "テーブル・RPG",
  "icNo": "0"
 },
 {
  "id": "460",
  "ini": "す",
  "name": "す～ぱ～ぷよぷよ",
  "maker": "バンプレスト",
  "releaseDate": "1993/12/10",
  "price": "8200",
  "genre": "パズル",
  "icNo": "53"
 },
 {
  "id": "461",
  "ini": "は",
  "name": "白熱プロ野球'94 ガンバリーグ3",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1993/12/10",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "462",
  "ini": "ふ",
  "name": "プロック",
  "maker": "アクティビジョンジャパン",
  "releaseDate": "1993/12/10",
  "price": "8900",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "463",
  "ini": "ろ",
  "name": "ロマンシング サ・ガ2",
  "maker": "スクウェア",
  "releaseDate": "1993/12/10",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "464",
  "ini": "あ",
  "name": "アルカエスト",
  "maker": "スクウェア",
  "releaseDate": "1993/12/17",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "56"
 },
 {
  "id": "465",
  "ini": "す",
  "name": "スーパー・スター・ウォーズ 帝国の逆襲",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "466",
  "ini": "た",
  "name": "ダウンタウン熱血べーすぼーる物語 野球で勝負だ！くにおくん",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "467",
  "ini": "と",
  "name": "ドラえもん2 のび太のトイズランド大冒険",
  "maker": "エポック社",
  "releaseDate": "1993/12/17",
  "price": "8000",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "468",
  "ini": "と",
  "name": "ドラゴンボールZ 超武闘伝2",
  "maker": "バンダイ",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "53"
 },
 {
  "id": "469",
  "ini": "と",
  "name": "ドラッキーの草やきう",
  "maker": "イマジニアズーム",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "470",
  "ini": "は",
  "name": "パチンコウォーズⅡ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "471",
  "ini": "ほ",
  "name": "ホーリーストライカー",
  "maker": "ヘクト",
  "releaseDate": "1993/12/17",
  "price": "8800",
  "genre": "ブロック崩し・アクション",
  "icNo": "2"
 },
 {
  "id": "472",
  "ini": "ゆ",
  "name": "夢幻の如く",
  "maker": "インテック",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "RPG",
  "icNo": "33"
 },
 {
  "id": "473",
  "ini": "ら",
  "name": "ラッシング・ビート修羅",
  "maker": "ジャレコ",
  "releaseDate": "1993/12/17",
  "price": "9700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "474",
  "ini": "ろ",
  "name": "ロックマンX",
  "maker": "カプコン",
  "releaseDate": "1993/12/17",
  "price": "9500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "475",
  "ini": "わ",
  "name": "ワンダラス マジック",
  "maker": "アスキー",
  "releaseDate": "1993/12/17",
  "price": "9800",
  "genre": "RPG",
  "icNo": "55"
 },
 {
  "id": "476",
  "ini": "と",
  "name": "ドラゴンクエストⅠ・Ⅱ",
  "maker": "エニックス",
  "releaseDate": "1993/12/18",
  "price": "9600",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "477",
  "ini": "え",
  "name": "エースをねらえ！",
  "maker": "日本テレネット",
  "releaseDate": "1993/12/22",
  "price": "9400",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "478",
  "ini": "か",
  "name": "がんばれゴエモン2 奇天烈将軍マッギネス",
  "maker": "コナミ",
  "releaseDate": "1993/12/22",
  "price": "9800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "479",
  "ini": "か",
  "name": "がんばれ！大工の源さん",
  "maker": "アイレム",
  "releaseDate": "1993/12/22",
  "price": "8900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "480",
  "ini": "き",
  "name": "KING OF THE MONSTERS2",
  "maker": "タカラ",
  "releaseDate": "1993/12/22",
  "price": "9800",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "481",
  "ini": "す",
  "name": "超ゴジラ",
  "maker": "東宝",
  "releaseDate": "1993/12/22",
  "price": "9800",
  "genre": "シミュレーション・アニメーションバトル",
  "icNo": "10"
 },
 {
  "id": "482",
  "ini": "す",
  "name": "スペースファンキー ビー・オー・ビー",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/12/22",
  "price": "8900",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "483",
  "ini": "と",
  "name": "トップレーサー2",
  "maker": "ケムコ",
  "releaseDate": "1993/12/22",
  "price": "8500",
  "genre": "レース・3D",
  "icNo": "10"
 },
 {
  "id": "484",
  "ini": "ひ",
  "name": "ビンビン！ビンゴ",
  "maker": "KSS",
  "releaseDate": "1993/12/22",
  "price": "8900",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "485",
  "ini": "ふ",
  "name": "フラッシュバック",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/12/22",
  "price": "9600",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "486",
  "ini": "へ",
  "name": "へべれけのぽぷーん",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/12/22",
  "price": "8200",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "487",
  "ini": "ゆ",
  "name": "幽☆遊☆白書",
  "maker": "ナムコ",
  "releaseDate": "1993/12/22",
  "price": "9600",
  "genre": "アニメーションバトル",
  "icNo": "20"
 },
 {
  "id": "488",
  "ini": "え",
  "name": "NFL プロフットボール'94",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1993/12/24",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "60"
 },
 {
  "id": "489",
  "ini": "し",
  "name": "新 桃太郎伝説",
  "maker": "ハドソン",
  "releaseDate": "1993/12/24",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "490",
  "ini": "て",
  "name": "テトリス 武闘外伝",
  "maker": "BPS",
  "releaseDate": "1993/12/24",
  "price": "8000",
  "genre": "パズル",
  "icNo": "60"
 },
 {
  "id": "491",
  "ini": "ひ",
  "name": "ヒューマングランプリ2",
  "maker": "ヒューマン",
  "releaseDate": "1993/12/24",
  "price": "9500",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "492",
  "ini": "ほ",
  "name": "北斗の拳7",
  "maker": "東映動画",
  "releaseDate": "1993/12/24",
  "price": "9700",
  "genre": "対戦格闘",
  "icNo": "55"
 },
 {
  "id": "493",
  "ini": "も",
  "name": "モータルコンバット",
  "maker": "アクレイムジャパン",
  "releaseDate": "1993/12/24",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "494",
  "ini": "も",
  "name": "モンスターメーカー3 光の魔術師",
  "maker": "ソフエル",
  "releaseDate": "1993/12/24",
  "price": "9800",
  "genre": "RPG",
  "icNo": "40"
 },
 {
  "id": "495",
  "ini": "り",
  "name": "リトル・マジック",
  "maker": "アルトロン",
  "releaseDate": "1993/12/24",
  "price": "8900",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "496",
  "ini": "ひ",
  "name": "美神伝説 Zoku",
  "maker": "マジファクト",
  "releaseDate": "1993/12/25",
  "price": "9800",
  "genre": "レース・3D・アクション",
  "icNo": "42"
 },
 {
  "id": "497",
  "ini": "さ",
  "name": "サッカーキッド",
  "maker": "やのまん",
  "releaseDate": "1993/12/28",
  "price": "8800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "498",
  "ini": "せ",
  "name": "全日本プロレスダッシュ 世界最強タッグ",
  "maker": "日本コンピュータシステム",
  "releaseDate": "1993/12/28",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "499",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング3 ファイナルバウト",
  "maker": "ヒューマン",
  "releaseDate": "1993/12/29",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "50"
 },
 {
  "id": "500",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンR",
  "maker": "バンダイ",
  "releaseDate": "1993/12/29",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "501",
  "ini": "よ",
  "name": "横山光輝 三国志2",
  "maker": "エンジェル",
  "releaseDate": "1993/12/29",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "43"
 },
 {
  "id": "502",
  "ini": "ろ",
  "name": "ロックンロールレーシング",
  "maker": "ナムコ",
  "releaseDate": "1994/1/3",
  "price": "7900",
  "genre": "レース",
  "icNo": "43"
 },
 {
  "id": "503",
  "ini": "つ",
  "name": "ツインビー レインボーベルアドベンチャー",
  "maker": "コナミ",
  "releaseDate": "1994/1/7",
  "price": "9000",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "504",
  "ini": "は",
  "name": "バトルトード イン バトルマニアック",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/1/7",
  "price": "9800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "505",
  "ini": "わ",
  "name": "ワールドクラスラグビー2 国内激闘編'93",
  "maker": "ミサワエンターテインメント",
  "releaseDate": "1994/1/7",
  "price": "9300",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "506",
  "ini": "す",
  "name": "スーパーピンボール ビハインド ザ マスク",
  "maker": "メルダック",
  "releaseDate": "1994/1/8",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "507",
  "ini": "ひ",
  "name": "必勝777ファイター パチスロ竜宮伝説",
  "maker": "バップ",
  "releaseDate": "1994/1/14",
  "price": "9500",
  "genre": "スロット",
  "icNo": "1"
 },
 {
  "id": "508",
  "ini": "す",
  "name": "スーパーテトリス2+ボンブリス 限定版",
  "maker": "BPS",
  "releaseDate": "1994/1/21",
  "price": "8500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "509",
  "ini": "ふ",
  "name": "ファイアーエムブレム 紋章の謎",
  "maker": "任天堂",
  "releaseDate": "1994/1/21",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "510",
  "ini": "か",
  "name": "ガイアセイバー ヒーロー最大の作戦",
  "maker": "バンプレスト",
  "releaseDate": "1994/1/28",
  "price": "9800",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "511",
  "ini": "こ",
  "name": "鋼鉄の騎士2 砂漠のロンメル軍団",
  "maker": "アスミック",
  "releaseDate": "1994/1/28",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "55"
 },
 {
  "id": "512",
  "ini": "さ",
  "name": "ザ・グレイトバトル外伝2 祭りだワッショイ",
  "maker": "バンプレスト",
  "releaseDate": "1994/1/28",
  "price": "9500",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "513",
  "ini": "さ",
  "name": "ザ・ニンジャウォーリアーズ アゲイン",
  "maker": "タイトー",
  "releaseDate": "1994/1/28",
  "price": "9300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "514",
  "ini": "は",
  "name": "バスタード 暗黒の破壊神",
  "maker": "コブラチーム",
  "releaseDate": "1994/1/28",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "515",
  "ini": "ふ",
  "name": "ブレインロード",
  "maker": "エニックス",
  "releaseDate": "1994/1/28",
  "price": "9600",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "516",
  "ini": "ま",
  "name": "魔神転生",
  "maker": "アトラス",
  "releaseDate": "1994/1/28",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "10"
 },
 {
  "id": "517",
  "ini": "い",
  "name": "伊藤果六段の将棋道場",
  "maker": "アスク講談社",
  "releaseDate": "1994/2/4",
  "price": "9600",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "518",
  "ini": "お",
  "name": "オリビアのミステリー",
  "maker": "アルトロン",
  "releaseDate": "1994/2/4",
  "price": "9800",
  "genre": "パズル",
  "icNo": "30"
 },
 {
  "id": "519",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング3 イージータイプ",
  "maker": "ヒューマン",
  "releaseDate": "1994/2/4",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "520",
  "ini": "う",
  "name": "ウルフェンシュタイン 3D",
  "maker": "イマジニア",
  "releaseDate": "1994/2/10",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "0"
 },
 {
  "id": "521",
  "ini": "す",
  "name": "スーパーヅガン ハコテン城からの招待状",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/2/11",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "56"
 },
 {
  "id": "522",
  "ini": "そ",
  "name": "ソード・マニアック",
  "maker": "東芝EMI",
  "releaseDate": "1994/2/11",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "523",
  "ini": "と",
  "name": "トップマネジメントⅡ",
  "maker": "光栄",
  "releaseDate": "1994/2/11",
  "price": "14800",
  "genre": "経営・シミュレーション",
  "icNo": "31"
 },
 {
  "id": "524",
  "ini": "は",
  "name": "バーチャルウォーズ",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/2/11",
  "price": "11000",
  "genre": "アクション・シューティング・3D",
  "icNo": "50"
 },
 {
  "id": "525",
  "ini": "ひ",
  "name": "緋王伝 魔物達との誓い",
  "maker": "ウルフ・チーム",
  "releaseDate": "1994/2/11",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "か",
  "name": "迦楼羅王",
  "maker": "エピック・ソニーレコード",
  "releaseDate": "1994/2/18",
  "price": "8900",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "527",
  "ini": "た",
  "name": "ダービースタリオンⅡ",
  "maker": "アスキー",
  "releaseDate": "1994/2/18",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "528",
  "ini": "た",
  "name": "戦え原始人3 主役はやっぱりJOE&MAC",
  "maker": "データイースト",
  "releaseDate": "1994/2/18",
  "price": "8500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "529",
  "ini": "て",
  "name": "デザートファイター 砂の嵐作戦",
  "maker": "セタ",
  "releaseDate": "1994/2/18",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "34"
 },
 {
  "id": "530",
  "ini": "て",
  "name": "鉄腕アトム",
  "maker": "バンプレスト",
  "releaseDate": "1994/2/18",
  "price": "9000",
  "genre": "アクション",
  "icNo": "65"
 },
 {
  "id": "531",
  "ini": "う",
  "name": "宇宙レース ASTRO GO！GO！",
  "maker": "メルダック",
  "releaseDate": "1994/2/25",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "55"
 },
 {
  "id": "532",
  "ini": "さ",
  "name": "サイボーグ009",
  "maker": "ベック",
  "releaseDate": "1994/2/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "533",
  "ini": "す",
  "name": "ストバスヤロウ将",
  "maker": "ビーアイ",
  "releaseDate": "1994/2/25",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "534",
  "ini": "そ",
  "name": "総合格闘技 アストラルバウト2",
  "maker": "キングレコード",
  "releaseDate": "1994/2/25",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "535",
  "ini": "た",
  "name": "大航海時代Ⅱ",
  "maker": "光栄",
  "releaseDate": "1994/2/25",
  "price": "11800",
  "genre": "シミュレーション・RPG",
  "icNo": "22"
 },
 {
  "id": "536",
  "ini": "て",
  "name": "T2 ザ・アーケードゲーム",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/2/25",
  "price": "8900",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "537",
  "ini": "は",
  "name": "パチスロランド ぱちぱちコインの伝説",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1994/2/25",
  "price": "9800",
  "genre": "スロット",
  "icNo": "1"
 },
 {
  "id": "538",
  "ini": "ら",
  "name": "ラモス瑠偉のワールドワイドサッカー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/2/25",
  "price": "9500",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "539",
  "ini": "い",
  "name": "いただきストリート2 ネオンサインはバラ色に",
  "maker": "エニックス",
  "releaseDate": "1994/2/26",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "540",
  "ini": "す",
  "name": "スーパー本命 GI制覇",
  "maker": "日本物産",
  "releaseDate": "1994/2/28",
  "price": "9800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "541",
  "ini": "か",
  "name": "カブキロックス",
  "maker": "アトラス",
  "releaseDate": "1994/3/4",
  "price": "9800",
  "genre": "RPG",
  "icNo": "13"
 },
 {
  "id": "542",
  "ini": "さ",
  "name": "ザ・キングオブドラゴンズ",
  "maker": "カプコン",
  "releaseDate": "1994/3/4",
  "price": "9800",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "543",
  "ini": "し",
  "name": "ジーコ サッカー",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/3/4",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "544",
  "ini": "す",
  "name": "スーパーファミスタ3",
  "maker": "ナムコ",
  "releaseDate": "1994/3/4",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "545",
  "ini": "す",
  "name": "スーパーループス",
  "maker": "イマジニア",
  "releaseDate": "1994/3/4",
  "price": "8900",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "546",
  "ini": "き",
  "name": "機動戦士Vガンダム",
  "maker": "バンダイ",
  "releaseDate": "1994/3/11",
  "price": "9800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "547",
  "ini": "き",
  "name": "ギャラクシーロボ",
  "maker": "イマジニア",
  "releaseDate": "1994/3/11",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "44"
 },
 {
  "id": "548",
  "ini": "さ",
  "name": "斬Ⅲスピリッツ",
  "maker": "ウルフ・チーム",
  "releaseDate": "1994/3/11",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "43"
 },
 {
  "id": "549",
  "ini": "し",
  "name": "実況パワフルプロ野球'94",
  "maker": "コナミ",
  "releaseDate": "1994/3/11",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "550",
  "ini": "す",
  "name": "すごいへべれけ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/3/11",
  "price": "8900",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "551",
  "ini": "ふ",
  "name": "ファーストクィーン オルニック戦記",
  "maker": "カルチャーブレーン",
  "releaseDate": "1994/3/11",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "552",
  "ini": "り",
  "name": "リーサルエンフォーサーズ",
  "maker": "コナミ",
  "releaseDate": "1994/3/11",
  "price": "9800",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "553",
  "ini": "あ",
  "name": "Advanced Dungeons & Dragons アイ・オブ・ザ・ビホルダー",
  "maker": "カプコン",
  "releaseDate": "1994/3/18",
  "price": "12800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "554",
  "ini": "あ",
  "name": "あさめしまえにゃんこ",
  "maker": "バンプレスト",
  "releaseDate": "1994/3/18",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "56"
 },
 {
  "id": "555",
  "ini": "い",
  "name": "イデアの日",
  "maker": "ショウエイシステム",
  "releaseDate": "1994/3/18",
  "price": "9700",
  "genre": "RPG",
  "icNo": "60"
 },
 {
  "id": "556",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派2 ドラポンクエスト",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/3/18",
  "price": "8900",
  "genre": "テーブル・RPG",
  "icNo": "53"
 },
 {
  "id": "557",
  "ini": "き",
  "name": "きんぎょ注意報！とびだせ！ゲーム学園",
  "maker": "ジャレコ",
  "releaseDate": "1994/3/18",
  "price": "9800",
  "genre": "ミニゲーム",
  "icNo": "55"
 },
 {
  "id": "558",
  "ini": "さ",
  "name": "サイドポケット",
  "maker": "データイースト",
  "releaseDate": "1994/3/18",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "559",
  "ini": "し",
  "name": "Jリーグ スーパーサッカー",
  "maker": "ハドソン",
  "releaseDate": "1994/3/18",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "560",
  "ini": "し",
  "name": "真・女神転生Ⅱ",
  "maker": "アトラス",
  "releaseDate": "1994/3/18",
  "price": "9990",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "561",
  "ini": "す",
  "name": "SUPER人生ゲーム",
  "maker": "タカラ",
  "releaseDate": "1994/3/18",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "562",
  "ini": "す",
  "name": "スーパーナグザットオープン ゴルフで勝負だ どらぼっちゃん",
  "maker": "ナグザット",
  "releaseDate": "1994/3/18",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "563",
  "ini": "そ",
  "name": "ソニックブラストマンⅡ",
  "maker": "タイトー",
  "releaseDate": "1994/3/18",
  "price": "9500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "564",
  "ini": "た",
  "name": "ダービージョッキー 騎手王への道",
  "maker": "アスミック",
  "releaseDate": "1994/3/18",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "565",
  "ini": "と",
  "name": "独立戦争 Liberty or Death",
  "maker": "光栄",
  "releaseDate": "1994/3/18",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "21"
 },
 {
  "id": "566",
  "ini": "す",
  "name": "スーパーメトロイド",
  "maker": "任天堂",
  "releaseDate": "1994/3/19",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "567",
  "ini": "あ",
  "name": "あらいぐま ラスカル",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/3/25",
  "price": "8900",
  "genre": "パズル",
  "icNo": "55"
 },
 {
  "id": "568",
  "ini": "け",
  "name": "けろけろけろっぴの冒険日記 眠れぬ森のけろりーぬ",
  "maker": "キャラクターソフト",
  "releaseDate": "1994/3/25",
  "price": "6980",
  "genre": "RPG",
  "icNo": "65"
 },
 {
  "id": "569",
  "ini": "け",
  "name": "剣勇伝説YAIBA",
  "maker": "バンプレスト",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "42"
 },
 {
  "id": "570",
  "ini": "さ",
  "name": "ザ ブルークリスタルロッド",
  "maker": "ナムコ",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "アドベンチャー",
  "icNo": "22"
 },
 {
  "id": "571",
  "ini": "し",
  "name": "シャドウラン",
  "maker": "データイースト",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "21"
 },
 {
  "id": "572",
  "ini": "す",
  "name": "スーパー五目ならべ 連珠",
  "maker": "ナグザット",
  "releaseDate": "1994/3/25",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "573",
  "ini": "す",
  "name": "スーパートロールアイランド",
  "maker": "ケムコ",
  "releaseDate": "1994/3/25",
  "price": "8900",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "574",
  "ini": "す",
  "name": "スーパーホッケー'94",
  "maker": "ヨネザワ",
  "releaseDate": "1994/3/25",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "575",
  "ini": "す",
  "name": "スーパーリアル麻雀PⅣ",
  "maker": "セタ",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "576",
  "ini": "す",
  "name": "スーパーロボット大戦EX",
  "maker": "バンプレスト",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "577",
  "ini": "す",
  "name": "スペースインベーダー",
  "maker": "タイトー",
  "releaseDate": "1994/3/25",
  "price": "4980",
  "genre": "シューティング",
  "icNo": "51"
 },
 {
  "id": "578",
  "ini": "す",
  "name": "スペースエース",
  "maker": "イマジニア",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "579",
  "ini": "ち",
  "name": "チャンピオンズ ワールドクラスサッカー",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/3/25",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "54"
 },
 {
  "id": "580",
  "ini": "な",
  "name": "南国少年パプワくん",
  "maker": "エニックス",
  "releaseDate": "1994/3/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "581",
  "ini": "め",
  "name": "メルファンドストーリーズ",
  "maker": "アスキー",
  "releaseDate": "1994/3/25",
  "price": "9800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "582",
  "ini": "ろ",
  "name": "ロックマンズサッカー",
  "maker": "カプコン",
  "releaseDate": "1994/3/25",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "583",
  "ini": "ふ",
  "name": "From TV animation SLUM DUNK 四強激突!!",
  "maker": "バンダイ",
  "releaseDate": "1994/3/26",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "584",
  "ini": "し",
  "name": "真・麻雀",
  "maker": "コナミ",
  "releaseDate": "1994/3/30",
  "price": "9000",
  "genre": "テーブル",
  "icNo": "31"
 },
 {
  "id": "585",
  "ini": "ま",
  "name": "マッスルボマー",
  "maker": "カプコン",
  "releaseDate": "1994/3/30",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "42"
 },
 {
  "id": "586",
  "ini": "あ",
  "name": "アンドレ・アガシ テニス",
  "maker": "日本物産",
  "releaseDate": "1994/3/31",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "587",
  "ini": "す",
  "name": "スーパー インディ チャンプ",
  "maker": "フォーラム",
  "releaseDate": "1994/4/1",
  "price": "9200",
  "genre": "レース・3D",
  "icNo": "42"
 },
 {
  "id": "588",
  "ini": "す",
  "name": "スーパーダブル役満",
  "maker": "バップ",
  "releaseDate": "1994/4/1",
  "price": "9000",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "589",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅥ",
  "maker": "スクウェア",
  "releaseDate": "1994/4/2",
  "price": "11400",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "590",
  "ini": "こ",
  "name": "項劉記",
  "maker": "光栄",
  "releaseDate": "1994/4/6",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "22"
 },
 {
  "id": "591",
  "ini": "え",
  "name": "NHL プロホッケー'94",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/4/8",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "592",
  "ini": "し",
  "name": "紫炎 THE BLADE CHASER",
  "maker": "ダイナミック企画",
  "releaseDate": "1994/4/8",
  "price": "9600",
  "genre": "ガンシューティング",
  "icNo": "55"
 },
 {
  "id": "593",
  "ini": "す",
  "name": "スーパー囲碁 碁王",
  "maker": "ナグザット",
  "releaseDate": "1994/4/8",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "594",
  "ini": "と",
  "name": "夢迷宮 きぐるみ大冒険",
  "maker": "ヘクト",
  "releaseDate": "1994/4/15",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "595",
  "ini": "ひ",
  "name": "ピンクパンサー",
  "maker": "アルトロン",
  "releaseDate": "1994/4/15",
  "price": "9500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "596",
  "ini": "ろ",
  "name": "ろくでなしBLUES 対決！東京四天王",
  "maker": "バンダイ",
  "releaseDate": "1994/4/15",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "597",
  "ini": "え",
  "name": "F-1 GRAND PRIX PARTⅢ",
  "maker": "ビデオシステム",
  "releaseDate": "1994/4/22",
  "price": "9900",
  "genre": "レース",
  "icNo": "4"
 },
 {
  "id": "598",
  "ini": "き",
  "name": "機動警察パトレイバー",
  "maker": "ベック",
  "releaseDate": "1994/4/22",
  "price": "9800",
  "genre": "シミュレーション・アニメーションバトル",
  "icNo": "0"
 },
 {
  "id": "599",
  "ini": "こ",
  "name": "コットン100%",
  "maker": "データム・ポリスター",
  "releaseDate": "1994/4/22",
  "price": "9300",
  "genre": "シューティング",
  "icNo": "44"
 },
 {
  "id": "600",
  "ini": "す",
  "name": "スーパーパチスロマージャン",
  "maker": "日本物産",
  "releaseDate": "1994/4/28",
  "price": "9500",
  "genre": "テーブル・スロット",
  "icNo": "2"
 },
 {
  "id": "601",
  "ini": "す",
  "name": "スーパーボンバーマン2",
  "maker": "ハドソン",
  "releaseDate": "1994/4/28",
  "price": "8500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "602",
  "ini": "た",
  "name": "ダイナマイト・ザ・ラスベガス",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1994/4/28",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "44"
 },
 {
  "id": "603",
  "ini": "ふ",
  "name": "Fortune Quest ダイスをころがせ",
  "maker": "バンプレスト",
  "releaseDate": "1994/4/28",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "604",
  "ini": "へ",
  "name": "平成イヌ物語バウ ポップンスマッシュ!!",
  "maker": "タカラ",
  "releaseDate": "1994/4/28",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "605",
  "ini": "ら",
  "name": "らんま1/2 超技乱舞篇",
  "maker": "東宝・小学館プロダクション",
  "releaseDate": "1994/4/28",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "10"
 },
 {
  "id": "606",
  "ini": "わ",
  "name": "笑っていいとも！タモリンピック",
  "maker": "アテナ",
  "releaseDate": "1994/4/28",
  "price": "9500",
  "genre": "ミニゲーム",
  "icNo": "43"
 },
 {
  "id": "607",
  "ini": "え",
  "name": "NBA JAM",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/4/29",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "608",
  "ini": "し",
  "name": "新・熱血硬派 くにおたちの挽歌",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/4/29",
  "price": "9800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "609",
  "ini": "た",
  "name": "ダークキングダム",
  "maker": "日本テレネット",
  "releaseDate": "1994/4/29",
  "price": "9800",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "610",
  "ini": "な",
  "name": "ナイス DE ショット",
  "maker": "アスク講談社",
  "releaseDate": "1994/4/29",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "611",
  "ini": "し",
  "name": "Jリーグエキサイトステージ'94",
  "maker": "エポック社",
  "releaseDate": "1994/5/1",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "612",
  "ini": "た",
  "name": "伊達公子のヴァーチャルテニス",
  "maker": "ビーアイ",
  "releaseDate": "1994/5/13",
  "price": "9000",
  "genre": "スポーツ・3D",
  "icNo": "61"
 },
 {
  "id": "613",
  "ini": "は",
  "name": "パチ夫くんSPECIAL2",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/5/20",
  "price": "9980",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "614",
  "ini": "え",
  "name": "SDガンダムGX",
  "maker": "バンダイ",
  "releaseDate": "1994/5/27",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "30"
 },
 {
  "id": "615",
  "ini": "く",
  "name": "くにおのおでん",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/5/27",
  "price": "6900",
  "genre": "パズル",
  "icNo": "64"
 },
 {
  "id": "616",
  "ini": "く",
  "name": "クレヨンしんちゃん2 大魔王の逆襲",
  "maker": "バンダイ",
  "releaseDate": "1994/5/27",
  "price": "6800",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "617",
  "ini": "か",
  "name": "勝ち馬予想ソフト 馬券錬金術",
  "maker": "KSS",
  "releaseDate": "1994/5/27",
  "price": "9500",
  "genre": "競馬予想",
  "icNo": "0"
 },
 {
  "id": "618",
  "ini": "す",
  "name": "スーパーバトルタンク2",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/5/27",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "22"
 },
 {
  "id": "619",
  "ini": "と",
  "name": "ドリフトキング 土屋圭市&坂東正明 首都高バトル'94",
  "maker": "BPS",
  "releaseDate": "1994/5/27",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "43"
 },
 {
  "id": "620",
  "ini": "ふ",
  "name": "ファイターズヒストリー",
  "maker": "データイースト",
  "releaseDate": "1994/5/27",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "50"
 },
 {
  "id": "621",
  "ini": "わ",
  "name": "ワイルドトラックス",
  "maker": "任天堂",
  "releaseDate": "1994/6/4",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "22"
 },
 {
  "id": "622",
  "ini": "さ",
  "name": "サラブレッドブリーダーⅡ",
  "maker": "ヘクト",
  "releaseDate": "1994/6/8",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "623",
  "ini": "な",
  "name": "ナイツ オブ ザ ラウンド",
  "maker": "カプコン",
  "releaseDate": "1994/6/10",
  "price": "9500",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "624",
  "ini": "ほ",
  "name": "ぽっぷるメイル",
  "maker": "日本ファルコム",
  "releaseDate": "1994/6/10",
  "price": "8800",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "625",
  "ini": "ゆ",
  "name": "幽☆遊☆白書2 格闘の章",
  "maker": "ナムコ",
  "releaseDate": "1994/6/10",
  "price": "9600",
  "genre": "対戦格闘",
  "icNo": "33"
 },
 {
  "id": "626",
  "ini": "う",
  "name": "ウルティマ外伝 黒騎士の陰謀",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "44"
 },
 {
  "id": "627",
  "ini": "え",
  "name": "SD飛龍の拳",
  "maker": "カルチャーブレーン",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "628",
  "ini": "き",
  "name": "奇々怪界 月夜草子",
  "maker": "ナツメ",
  "releaseDate": "1994/6/17",
  "price": "9500",
  "genre": "アクション・シューティング",
  "icNo": "10"
 },
 {
  "id": "629",
  "ini": "す",
  "name": "スーパー将棋2",
  "maker": "アイマックス",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "34"
 },
 {
  "id": "630",
  "ini": "す",
  "name": "スーパーフォーメーションサッカー94",
  "maker": "ヒューマン",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "631",
  "ini": "す",
  "name": "スーパー4WD The BAJA",
  "maker": "日本物産",
  "releaseDate": "1994/6/17",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "43"
 },
 {
  "id": "632",
  "ini": "ふ",
  "name": "FIFA インターナショナル・サッカー",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "633",
  "ini": "や",
  "name": "やまねこBUBSYの大冒険",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/6/17",
  "price": "9800",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "634",
  "ini": "わ",
  "name": "ワールドカップストライカー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/6/17",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "635",
  "ini": "し",
  "name": "ZIG ZAG CAT ダチョウ倶楽部も大騒ぎダ",
  "maker": "DEN'Z",
  "releaseDate": "1994/6/24",
  "price": "9500",
  "genre": "ブロック崩し",
  "icNo": "55"
 },
 {
  "id": "636",
  "ini": "し",
  "name": "ジュラシック・パーク",
  "maker": "ジャレコ",
  "releaseDate": "1994/6/24",
  "price": "9800",
  "genre": "アクションシューティング・3D",
  "icNo": "1"
 },
 {
  "id": "637",
  "ini": "す",
  "name": "スーパードッグファイト",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/6/24",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "56"
 },
 {
  "id": "638",
  "ini": "す",
  "name": "スーパービリヤード",
  "maker": "イマジニア",
  "releaseDate": "1994/6/24",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "639",
  "ini": "す",
  "name": "スレイヤーズ",
  "maker": "バンプレスト",
  "releaseDate": "1994/6/24",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "640",
  "ini": "る",
  "name": "ルーニー・テューンズ バックス・バニー はちゃめちゃ大冒険",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/6/24",
  "price": "9200",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "641",
  "ini": "す",
  "name": "スーパーストリートファイターⅡ",
  "maker": "カプコン",
  "releaseDate": "1994/6/25",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "33"
 },
 {
  "id": "642",
  "ini": "せ",
  "name": "全日本プロレス ファイトだポン！",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/6/25",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "41"
 },
 {
  "id": "643",
  "ini": "ふ",
  "name": "ブランディッシュ",
  "maker": "光栄",
  "releaseDate": "1994/6/25",
  "price": "10800",
  "genre": "アクション・RPG",
  "icNo": "32"
 },
 {
  "id": "644",
  "ini": "て",
  "name": "テコンドー",
  "maker": "ヒューマン",
  "releaseDate": "1994/6/28",
  "price": "8900",
  "genre": "スポーツ・対戦格闘",
  "icNo": "0"
 },
 {
  "id": "645",
  "ini": "ゆ",
  "name": "遊人のふりふりガールズ",
  "maker": "POW",
  "releaseDate": "1994/7/1",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "64"
 },
 {
  "id": "646",
  "ini": "わ",
  "name": "ワールドヒーローズ2",
  "maker": "ザウルス",
  "releaseDate": "1994/7/1",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "647",
  "ini": "す",
  "name": "スラップスティック",
  "maker": "エニックス",
  "releaseDate": "1994/7/8",
  "price": "9600",
  "genre": "RPG",
  "icNo": "30"
 },
 {
  "id": "648",
  "ini": "つ",
  "name": "つり太郎",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/7/8",
  "price": "9800",
  "genre": "釣り",
  "icNo": "43"
 },
 {
  "id": "649",
  "ini": "て",
  "name": "テトリスフラッシュ",
  "maker": "BPS",
  "releaseDate": "1994/7/8",
  "price": "8000",
  "genre": "パズル",
  "icNo": "44"
 },
 {
  "id": "650",
  "ini": "と",
  "name": "ドラッキーのAりーぐさっかー",
  "maker": "イマジニアズーム",
  "releaseDate": "1994/7/8",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "651",
  "ini": "ひ",
  "name": "美女と野獣",
  "maker": "ハドソン",
  "releaseDate": "1994/7/8",
  "price": "8500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "652",
  "ini": "か",
  "name": "歌舞伎町リーチ麻雀 東風戦",
  "maker": "ポニーキャニオン",
  "releaseDate": "1994/7/15",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "653",
  "ini": "き",
  "name": "キーパー",
  "maker": "データム・ポリスター",
  "releaseDate": "1994/7/15",
  "price": "7200",
  "genre": "パズル",
  "icNo": "56"
 },
 {
  "id": "654",
  "ini": "さ",
  "name": "サンサーラ・ナーガ2",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/7/15",
  "price": "9800",
  "genre": "RPG",
  "icNo": "40"
 },
 {
  "id": "655",
  "ini": "し",
  "name": "ジャングルブック",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1994/7/15",
  "price": "9800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "656",
  "ini": "す",
  "name": "スーパーF1サーカス3",
  "maker": "日本物産",
  "releaseDate": "1994/7/15",
  "price": "9900",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "657",
  "ini": "そ",
  "name": "ソード・ワールドSFC2 いにしえの巨人伝説",
  "maker": "T&Eソフト",
  "releaseDate": "1994/7/15",
  "price": "9900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "658",
  "ini": "は",
  "name": "パチスロ研究",
  "maker": "魔法",
  "releaseDate": "1994/7/15",
  "price": "9500",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "659",
  "ini": "は",
  "name": "バトルZEQUE伝",
  "maker": "アスミック",
  "releaseDate": "1994/7/15",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "660",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンS こんどはパズルでおしおきよ！",
  "maker": "バンダイ",
  "releaseDate": "1994/7/15",
  "price": "6800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "661",
  "ini": "く",
  "name": "グーフィーとマックス 海賊島の大冒険",
  "maker": "カプコン",
  "releaseDate": "1994/7/22",
  "price": "7800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "662",
  "ini": "し",
  "name": "ジグソーパ～ティ～",
  "maker": "ホリ電機",
  "releaseDate": "1994/7/22",
  "price": "8200",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "663",
  "ini": "す",
  "name": "超原人",
  "maker": "ハドソン",
  "releaseDate": "1994/7/22",
  "price": "8800",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "664",
  "ini": "せ",
  "name": "ゼロヨンチャンプRR",
  "maker": "メディアリング",
  "releaseDate": "1994/7/22",
  "price": "9980",
  "genre": "レース・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "665",
  "ini": "ふ",
  "name": "ファイプロ女子 オールスタードリームスラム",
  "maker": "ヒューマン",
  "releaseDate": "1994/7/22",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "666",
  "ini": "ふ",
  "name": "プロ麻雀 極Ⅱ",
  "maker": "アテナ",
  "releaseDate": "1994/7/22",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "667",
  "ini": "す",
  "name": "スーパーウルトラベースボール2",
  "maker": "カルチャーブレーン",
  "releaseDate": "1994/7/28",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "668",
  "ini": "か",
  "name": "餓狼伝説SPECIAL",
  "maker": "タカラ",
  "releaseDate": "1994/7/29",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "44"
 },
 {
  "id": "669",
  "ini": "こ",
  "name": "甲子園3",
  "maker": "魔法",
  "releaseDate": "1994/7/29",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "670",
  "ini": "す",
  "name": "スーパーニチブツマージャン3 吉本劇場篇",
  "maker": "日本物産",
  "releaseDate": "1994/7/29",
  "price": "10800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "671",
  "ini": "す",
  "name": "SUPER!!パチンコ",
  "maker": "アイマックス",
  "releaseDate": "1994/7/29",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "672",
  "ini": "す",
  "name": "ズールのゆめぼうけん",
  "maker": "インフォコム",
  "releaseDate": "1994/7/29",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "673",
  "ini": "て",
  "name": "天使の詩 白き翼の祈り",
  "maker": "日本テレネット",
  "releaseDate": "1994/7/29",
  "price": "9980",
  "genre": "RPG",
  "icNo": "13"
 },
 {
  "id": "674",
  "ini": "は",
  "name": "パチスロ物語 ユニバーサル・スペシャル",
  "maker": "KSS",
  "releaseDate": "1994/7/29",
  "price": "9800",
  "genre": "スロット",
  "icNo": "1"
 },
 {
  "id": "675",
  "ini": "は",
  "name": "ハットトリックヒーロー2",
  "maker": "タイトー",
  "releaseDate": "1994/7/29",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "676",
  "ini": "ろ",
  "name": "ロードランナーツイン ジャスティとリバティの大冒険",
  "maker": "T&Eソフト",
  "releaseDate": "1994/7/29",
  "price": "8800",
  "genre": "アクション・パズル",
  "icNo": "56"
 },
 {
  "id": "677",
  "ini": "わ",
  "name": "ワールドカップUSA94",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/7/29",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "678",
  "ini": "す",
  "name": "スーパーパワーリーグ2",
  "maker": "ハドソン",
  "releaseDate": "1994/8/3",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "679",
  "ini": "う",
  "name": "うごく絵Ver.2.0 アリョール",
  "maker": "アルトロン",
  "releaseDate": "1994/8/5",
  "price": "9800",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "680",
  "ini": "き",
  "name": "鬼神降臨伝ONI",
  "maker": "バンプレスト",
  "releaseDate": "1994/8/5",
  "price": "9800",
  "genre": "RPG",
  "icNo": "65"
 },
 {
  "id": "681",
  "ini": "し",
  "name": "ジェノサイド2",
  "maker": "ケムコ",
  "releaseDate": "1994/8/5",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "682",
  "ini": "し",
  "name": "Jリーグサッカー プライムゴール2",
  "maker": "ナムコ",
  "releaseDate": "1994/8/5",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "683",
  "ini": "す",
  "name": "スーパー高校野球 一球入魂",
  "maker": "アイマックス",
  "releaseDate": "1994/8/5",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "684",
  "ini": "す",
  "name": "す～ぱ～忍者くん",
  "maker": "ジャレコ",
  "releaseDate": "1994/8/5",
  "price": "7900",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "685",
  "ini": "す",
  "name": "スーパー花札",
  "maker": "アイマックス",
  "releaseDate": "1994/8/5",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "686",
  "ini": "ひ",
  "name": "ピンボール・ピンボール",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/8/5",
  "price": "9980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "687",
  "ini": "ま",
  "name": "魔法ぽいぽい ぽいっと！",
  "maker": "タカラ",
  "releaseDate": "1994/8/5",
  "price": "8800",
  "genre": "パズル",
  "icNo": "55"
 },
 {
  "id": "688",
  "ini": "さ",
  "name": "ザ・フリントストーンズ The Treasure of Sierra Madrock ",
  "maker": "タイトー",
  "releaseDate": "1994/8/12",
  "price": "8500",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "689",
  "ini": "し",
  "name": "新日本プロレスリング'94 バトルフィールド IN 闘強導夢",
  "maker": "バリエ",
  "releaseDate": "1994/8/12",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "690",
  "ini": "す",
  "name": "スーパー究極ハリキリスタジアム2",
  "maker": "タイトー",
  "releaseDate": "1994/8/12",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "691",
  "ini": "す",
  "name": "スーパー三國志",
  "maker": "光栄",
  "releaseDate": "1994/8/12",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "692",
  "ini": "す",
  "name": "スーパーファイナルマッチテニス",
  "maker": "ヒューマン",
  "releaseDate": "1994/8/12",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "62"
 },
 {
  "id": "693",
  "ini": "ほ",
  "name": "ポパイ いじわる魔女シーハッグの巻",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/8/12",
  "price": "9500",
  "genre": "テーブル・アクション",
  "icNo": "22"
 },
 {
  "id": "694",
  "ini": "れ",
  "name": "レミングス2",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/8/12",
  "price": "9800",
  "genre": "アクション・パズル",
  "icNo": "42"
 },
 {
  "id": "695",
  "ini": "わ",
  "name": "ワイルドガンズ",
  "maker": "ナツメ",
  "releaseDate": "1994/8/12",
  "price": "9200",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "696",
  "ini": "ひ",
  "name": "必勝777ファイター2 パチスロマル秘情報",
  "maker": "バップ",
  "releaseDate": "1994/8/19",
  "price": "9500",
  "genre": "スロット",
  "icNo": "1"
 },
 {
  "id": "697",
  "ini": "ま",
  "name": "麻雀悟空 天竺",
  "maker": "シャノアール",
  "releaseDate": "1994/8/19",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "698",
  "ini": "あ",
  "name": "アップルシード",
  "maker": "ヴィジット",
  "releaseDate": "1994/8/26",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "699",
  "ini": "お",
  "name": "押忍!! 空手部",
  "maker": "カルチャーブレーン",
  "releaseDate": "1994/8/26",
  "price": "10800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "700",
  "ini": "さ",
  "name": "サイバーナイトⅡ 地球帝国の野望",
  "maker": "トンキンハウス",
  "releaseDate": "1994/8/26",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "701",
  "ini": "す",
  "name": "スーパードラッケン",
  "maker": "ケムコ",
  "releaseDate": "1994/8/26",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "10"
 },
 {
  "id": "702",
  "ini": "は",
  "name": "ハロー！パックマン",
  "maker": "ナムコ",
  "releaseDate": "1994/8/26",
  "price": "8300",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "703",
  "ini": "ま",
  "name": "松村邦洋伝 最強の歴史をぬりかえろ！",
  "maker": "ショウエイシステム",
  "releaseDate": "1994/8/26",
  "price": "9900",
  "genre": "対戦格闘",
  "icNo": "30"
 },
 {
  "id": "704",
  "ini": "よ",
  "name": "横綱物語",
  "maker": "KSS",
  "releaseDate": "1994/8/26",
  "price": "9800",
  "genre": "育成・経営・シミュレーション",
  "icNo": "2"
 },
 {
  "id": "705",
  "ini": "ま",
  "name": "MOTHER2 ギーグの逆襲",
  "maker": "任天堂",
  "releaseDate": "1994/8/27",
  "price": "9800",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "706",
  "ini": "さ",
  "name": "サンリオワールド サンリオ上海",
  "maker": "キャラクターソフト",
  "releaseDate": "1994/8/31",
  "price": "6980",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "707",
  "ini": "へ",
  "name": "へべれけのおいしいパズルはいりませんか",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/8/31",
  "price": "8900",
  "genre": "パズル・テーブル",
  "icNo": "0"
 },
 {
  "id": "708",
  "ini": "ら",
  "name": "ライブ・ア・ライブ",
  "maker": "スクウェア",
  "releaseDate": "1994/9/2",
  "price": "9900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "709",
  "ini": "さ",
  "name": "ザ・ファイヤーメン",
  "maker": "ヒューマン",
  "releaseDate": "1994/9/9",
  "price": "9300",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "710",
  "ini": "ち",
  "name": "チョップリフターⅢ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/9/9",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "10"
 },
 {
  "id": "711",
  "ini": "し",
  "name": "上海Ⅲ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/9/15",
  "price": "8900",
  "genre": "パズル",
  "icNo": "33"
 },
 {
  "id": "712",
  "ini": "す",
  "name": "スパークスター",
  "maker": "コナミ",
  "releaseDate": "1994/9/15",
  "price": "9800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "713",
  "ini": "お",
  "name": "おでかけレスタ～ れれれのれ",
  "maker": "アスミック",
  "releaseDate": "1994/9/16",
  "price": "8900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "714",
  "ini": "し",
  "name": "実戦！パチスロ必勝法！2",
  "maker": "サミー",
  "releaseDate": "1994/9/16",
  "price": "9980",
  "genre": "スロット",
  "icNo": "52"
 },
 {
  "id": "715",
  "ini": "り",
  "name": "リーディングジョッキー",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1994/9/16",
  "price": "9800",
  "genre": "育成・シミュレーション・レース",
  "icNo": "0"
 },
 {
  "id": "716",
  "ini": "し",
  "name": "ジャングルの王者ターちゃん 世界漫遊大格闘の巻",
  "maker": "バンダイ",
  "releaseDate": "1994/9/18",
  "price": "8800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "717",
  "ini": "え",
  "name": "描いて・作って・遊べる デザエモン",
  "maker": "アテナ",
  "releaseDate": "1994/9/20",
  "price": "12900",
  "genre": "ゲーム制作・シューティング",
  "icNo": "30"
 },
 {
  "id": "718",
  "ini": "か",
  "name": "カービィボウル",
  "maker": "任天堂",
  "releaseDate": "1994/9/21",
  "price": "7900",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "719",
  "ini": "う",
  "name": "ウィザップ 暗黒の王",
  "maker": "アスキー",
  "releaseDate": "1994/9/22",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "720",
  "ini": "さ",
  "name": "サムライスピリッツ",
  "maker": "タカラ",
  "releaseDate": "1994/9/22",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "42"
 },
 {
  "id": "721",
  "ini": "す",
  "name": "スーパーフォーメーションサッカー94 ワールドカップファイナルデータ",
  "maker": "ヒューマン",
  "releaseDate": "1994/9/22",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "722",
  "ini": "な",
  "name": "中嶋悟監修 F-1 HERO'94",
  "maker": "バリエ",
  "releaseDate": "1994/9/22",
  "price": "9800",
  "genre": "レース",
  "icNo": "30"
 },
 {
  "id": "723",
  "ini": "ほ",
  "name": "本家 花札",
  "maker": "イマジニア",
  "releaseDate": "1994/9/22",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "724",
  "ini": "ら",
  "name": "ラリー・ニクソン スーパー・バス・フィッシング",
  "maker": "キングレコード",
  "releaseDate": "1994/9/22",
  "price": "9800",
  "genre": "釣り",
  "icNo": "30"
 },
 {
  "id": "725",
  "ini": "り",
  "name": "リブルラブル",
  "maker": "ナムコ",
  "releaseDate": "1994/9/22",
  "price": "6300",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "726",
  "ini": "あ",
  "name": "アンジェリーク",
  "maker": "光栄",
  "releaseDate": "1994/9/23",
  "price": "9800",
  "genre": "恋愛・シミュレーション",
  "icNo": "43"
 },
 {
  "id": "727",
  "ini": "こ",
  "name": "ゴーストチェイサー 電精",
  "maker": "バンプレスト",
  "releaseDate": "1994/9/23",
  "price": "9800",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "728",
  "ini": "す",
  "name": "スーパーブラックバス2",
  "maker": "スターフィッシュデータ",
  "releaseDate": "1994/9/23",
  "price": "9800",
  "genre": "釣り",
  "icNo": "0"
 },
 {
  "id": "729",
  "ini": "と",
  "name": "TOKORO'S マージャン",
  "maker": "ビック東海",
  "releaseDate": "1994/9/23",
  "price": "9500",
  "genre": "テーブル",
  "icNo": "61"
 },
 {
  "id": "730",
  "ini": "ま",
  "name": "麻雀戦国物語",
  "maker": "四次元",
  "releaseDate": "1994/9/23",
  "price": "9300",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "731",
  "ini": "と",
  "name": "ドラゴンボールZ 超武闘伝3",
  "maker": "バンダイ",
  "releaseDate": "1994/9/29",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "61"
 },
 {
  "id": "732",
  "ini": "く",
  "name": "クラッシュ・ダミー Dr.ザブを救い出せ",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/9/30",
  "price": "8900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "733",
  "ini": "ま",
  "name": "マル秘馬券購入術 競馬エイトスペシャル2",
  "maker": "イマジニア",
  "releaseDate": "1994/9/30",
  "price": "9980",
  "genre": "競馬予想",
  "icNo": "1"
 },
 {
  "id": "734",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ ドタバタ大運動会",
  "maker": "コナミ",
  "releaseDate": "1994/9/30",
  "price": "9000",
  "genre": "ミニゲーム",
  "icNo": "32"
 },
 {
  "id": "735",
  "ini": "た",
  "name": "ダウン・ザ・ワールド",
  "maker": "アスキー",
  "releaseDate": "1994/9/30",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "736",
  "ini": "て",
  "name": "天龍源一郎のプロレスレヴォリューション",
  "maker": "ジャレコ",
  "releaseDate": "1994/9/30",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "737",
  "ini": "は",
  "name": "バークレーのパワーダンク",
  "maker": "DEN'Z",
  "releaseDate": "1994/9/30",
  "price": "9200",
  "genre": "スポーツ",
  "icNo": "40"
 },
 {
  "id": "738",
  "ini": "は",
  "name": "バーチャルバート",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/9/30",
  "price": "9800",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "739",
  "ini": "は",
  "name": "バイク大好き！走り屋魂",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/9/30",
  "price": "9600",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "740",
  "ini": "ひ",
  "name": "ヒューマングランプリ3 F1トリプルバトル",
  "maker": "ヒューマン",
  "releaseDate": "1994/9/30",
  "price": "10500",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "741",
  "ini": "ま",
  "name": "麻雀大会Ⅱ",
  "maker": "光栄",
  "releaseDate": "1994/9/30",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "742",
  "ini": "し",
  "name": "シヴィライゼーション 世界七大文明",
  "maker": "アスミック",
  "releaseDate": "1994/10/7",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "50"
 },
 {
  "id": "743",
  "ini": "の",
  "name": "ノスフェラトゥ",
  "maker": "セタ",
  "releaseDate": "1994/10/7",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "744",
  "ini": "み",
  "name": "ミスターナッツ",
  "maker": "ソフエル",
  "releaseDate": "1994/10/7",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "745",
  "ini": "こ",
  "name": "豪血寺一族",
  "maker": "アトラス",
  "releaseDate": "1994/10/14",
  "price": "10500",
  "genre": "対戦格闘",
  "icNo": "50"
 },
 {
  "id": "746",
  "ini": "ゆ",
  "name": "U.F.O.仮面ヤキソバン ケトラーの黒い陰謀",
  "maker": "DEN'Z",
  "releaseDate": "1994/10/14",
  "price": "5890",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "747",
  "ini": "は",
  "name": "パチンコファン 勝利宣言",
  "maker": "POW",
  "releaseDate": "1994/10/15",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "748",
  "ini": "し",
  "name": "下野正希のFishing To Bassing",
  "maker": "ナツメ",
  "releaseDate": "1994/10/16",
  "price": "9800",
  "genre": "釣り",
  "icNo": "0"
 },
 {
  "id": "749",
  "ini": "は",
  "name": "はらぺこバッカ",
  "maker": "魔法",
  "releaseDate": "1994/10/19",
  "price": "4980",
  "genre": "パズル",
  "icNo": "11"
 },
 {
  "id": "750",
  "ini": "き",
  "name": "キッドクラウンのクレイジーチェイス",
  "maker": "ケムコ",
  "releaseDate": "1994/10/21",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "751",
  "ini": "す",
  "name": "スーパーファミリーサーキット",
  "maker": "ナムコ",
  "releaseDate": "1994/10/21",
  "price": "8800",
  "genre": "レース",
  "icNo": "52"
 },
 {
  "id": "752",
  "ini": "す",
  "name": "スーパーラグビー",
  "maker": "トンキンハウス",
  "releaseDate": "1994/10/21",
  "price": "9000",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "753",
  "ini": "て",
  "name": "デモンズ・ブレイゾン 魔界村 紋章編",
  "maker": "カプコン",
  "releaseDate": "1994/10/21",
  "price": "9800",
  "genre": "アクション",
  "icNo": "41"
 },
 {
  "id": "754",
  "ini": "ひ",
  "name": "必殺パチンココレクション",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/10/21",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "43"
 },
 {
  "id": "755",
  "ini": "ふ",
  "name": "船木誠勝 HYBRID WRESTLER 闘技伝承",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/10/21",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "756",
  "ini": "へ",
  "name": "ヘラクレスの栄光Ⅳ 神々からの贈り物",
  "maker": "データイースト",
  "releaseDate": "1994/10/21",
  "price": "9900",
  "genre": "RPG",
  "icNo": "51"
 },
 {
  "id": "757",
  "ini": "ほ",
  "name": "本格麻雀 徹萬Ⅱ",
  "maker": "ナグザット",
  "releaseDate": "1994/10/21",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "758",
  "ini": "い",
  "name": "イルバニアンの城",
  "maker": "日本クラリービジネス",
  "releaseDate": "1994/10/28",
  "price": "9980",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "759",
  "ini": "か",
  "name": "化学者ハリーの波乱万丈",
  "maker": "アルトロン",
  "releaseDate": "1994/10/28",
  "price": "8900",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "760",
  "ini": "さ",
  "name": "SANKYO Fever！フィーバー！パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "22"
 },
 {
  "id": "761",
  "ini": "し",
  "name": "少年忍者サスケ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "762",
  "ini": "し",
  "name": "真・女神転生if...",
  "maker": "アトラス",
  "releaseDate": "1994/10/28",
  "price": "9990",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "763",
  "ini": "す",
  "name": "スーパーカジノ2",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "764",
  "ini": "そ",
  "name": "卒業番外篇 ねぇ麻雀しよ！",
  "maker": "KSS",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "765",
  "ini": "て",
  "name": "DEAR BOYS",
  "maker": "ユタカ",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "766",
  "ini": "て",
  "name": "テクモスーパーベースボール",
  "maker": "テクモ",
  "releaseDate": "1994/10/28",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "767",
  "ini": "と",
  "name": "ドラッキーのパズルツアー'94",
  "maker": "イマジニアズーム",
  "releaseDate": "1994/10/28",
  "price": "8200",
  "genre": "シューティング・パズル",
  "icNo": "0"
 },
 {
  "id": "768",
  "ini": "は",
  "name": "爆投ドッチャーズ バンプス島は大さわぎ",
  "maker": "BPS",
  "releaseDate": "1994/10/28",
  "price": "8500",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "769",
  "ini": "ふ",
  "name": "フェーダ THE EMBLEM OF JUSTICE",
  "maker": "やのまん",
  "releaseDate": "1994/10/28",
  "price": "9990",
  "genre": "シミュレーション・RPG",
  "icNo": "2"
 },
 {
  "id": "770",
  "ini": "ほ",
  "name": "本格派囲碁 碁聖",
  "maker": "タイトー",
  "releaseDate": "1994/10/28",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "63"
 },
 {
  "id": "771",
  "ini": "ま",
  "name": "マルチプレイバレーボール",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/10/28",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "772",
  "ini": "こ",
  "name": "ゴン",
  "maker": "バンダイ",
  "releaseDate": "1994/11/11",
  "price": "8800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "773",
  "ini": "し",
  "name": "実況ワールドサッカー PERFECT ELEVEN",
  "maker": "コナミ",
  "releaseDate": "1994/11/11",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "774",
  "ini": "ふ",
  "name": "ファイヤー・ファイティング",
  "maker": "ジャレコ",
  "releaseDate": "1994/11/11",
  "price": "9890",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "775",
  "ini": "み",
  "name": "ミッキーとミニー マジカルアドベンチャー2",
  "maker": "カプコン",
  "releaseDate": "1994/11/11",
  "price": "9500",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "776",
  "ini": "も",
  "name": "モータルコンバットⅡ 究極神拳",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/11/11",
  "price": "11800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "777",
  "ini": "う",
  "name": "ウルティマⅦ ザ・ブラックゲート",
  "maker": "ポニーキャニオン",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "778",
  "ini": "し",
  "name": "GP-1RS RAPID STREAM",
  "maker": "アトラス",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "779",
  "ini": "す",
  "name": "スーパー五目・将棋 定跡研究篇",
  "maker": "日本物産",
  "releaseDate": "1994/11/18",
  "price": "9500",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "780",
  "ini": "つ",
  "name": "ツヨシしっかりしなさい 対戦ぱずるだま",
  "maker": "コナミ",
  "releaseDate": "1994/11/18",
  "price": "8500",
  "genre": "パズル",
  "icNo": "32"
 },
 {
  "id": "781",
  "ini": "と",
  "name": "ドリームバスケットボール ダンク&フープ",
  "maker": "ヒューマン",
  "releaseDate": "1994/11/18",
  "price": "9600",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "782",
  "ini": "な",
  "name": "中野浩一監修 競輪王",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/11/18",
  "price": "9980",
  "genre": "育成・シミュレーション",
  "icNo": "30"
 },
 {
  "id": "783",
  "ini": "は",
  "name": "鋼 HAGANE",
  "maker": "ハドソン",
  "releaseDate": "1994/11/18",
  "price": "9500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "784",
  "ini": "は",
  "name": "パチンコ マル秘必勝法",
  "maker": "バップ",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "11"
 },
 {
  "id": "785",
  "ini": "は",
  "name": "花の慶次 雲のかなたに",
  "maker": "四次元",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "786",
  "ini": "は",
  "name": "パニックイン なかよしワールド",
  "maker": "バンダイ",
  "releaseDate": "1994/11/18",
  "price": "6800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "787",
  "ini": "ま",
  "name": "マグナブラバン 遍歴の勇者",
  "maker": "アスク講談社",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "788",
  "ini": "み",
  "name": "ミリティア",
  "maker": "ナムコ",
  "releaseDate": "1994/11/18",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "21"
 },
 {
  "id": "789",
  "ini": "も",
  "name": "モンスターメーカーキッズ 王様になりたい",
  "maker": "ソフエル",
  "releaseDate": "1994/11/18",
  "price": "9200",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "790",
  "ini": "ゆ",
  "name": "遊人 雀獣学園2",
  "maker": "バリエ",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "791",
  "ini": "か",
  "name": "かまいたちの夜",
  "maker": "チュンソフト",
  "releaseDate": "1994/11/25",
  "price": "10800",
  "genre": "サウンドノベル",
  "icNo": "11"
 },
 {
  "id": "792",
  "ini": "か",
  "name": "元祖パチスロ日本一 創刊号",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/11/25",
  "price": "9980",
  "genre": "スロット",
  "icNo": "40"
 },
 {
  "id": "793",
  "ini": "こ",
  "name": "極上パロディウス",
  "maker": "コナミ",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "794",
  "ini": "す",
  "name": "スーパー麻雀3 辛口",
  "maker": "アイマックス",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "795",
  "ini": "せ",
  "name": "全国高校サッカー",
  "maker": "四次元",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "796",
  "ini": "た",
  "name": "大爆笑 人生劇場 大江戸日記",
  "maker": "タイトー",
  "releaseDate": "1994/11/25",
  "price": "9000",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "797",
  "ini": "た",
  "name": "武田修宏のスーパーリーグサッカー",
  "maker": "ジャレコ",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "798",
  "ini": "た",
  "name": "ただいま勇者募集中 おかわり",
  "maker": "ヒューマン",
  "releaseDate": "1994/11/25",
  "price": "9900",
  "genre": "テーブル",
  "icNo": "65"
 },
 {
  "id": "799",
  "ini": "な",
  "name": "なるほど！ザ・ワールド",
  "maker": "トミー",
  "releaseDate": "1994/11/25",
  "price": "9500",
  "genre": "クイズ",
  "icNo": "20"
 },
 {
  "id": "800",
  "ini": "の",
  "name": "ノンタンといっしょ くるくるぱずる",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/11/25",
  "price": "7800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "801",
  "ini": "は",
  "name": "バトルサッカー2",
  "maker": "バンプレスト",
  "releaseDate": "1994/11/25",
  "price": "8800",
  "genre": "スポーツ・アクション",
  "icNo": "61"
 },
 {
  "id": "802",
  "ini": "す",
  "name": "スーパードンキーコング",
  "maker": "任天堂",
  "releaseDate": "1994/11/26",
  "price": "9800",
  "genre": "アクション",
  "icNo": "60"
 },
 {
  "id": "803",
  "ini": "あ",
  "name": "アレサⅡ アリエルの不思議な旅",
  "maker": "やのまん",
  "releaseDate": "1994/12/2",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "804",
  "ini": "さ",
  "name": "ザ・ラストバトル",
  "maker": "テイチク",
  "releaseDate": "1994/12/2",
  "price": "9800",
  "genre": "RPG",
  "icNo": "30"
 },
 {
  "id": "805",
  "ini": "す",
  "name": "ストリートレーサー",
  "maker": "UBIソフト",
  "releaseDate": "1994/12/2",
  "price": "9200",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "806",
  "ini": "せ",
  "name": "制服伝説 プリティ・ファイター",
  "maker": "イマジニア",
  "releaseDate": "1994/12/2",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "52"
 },
 {
  "id": "807",
  "ini": "と",
  "name": "ドカポン3・2・1 嵐を呼ぶ友情",
  "maker": "アスミック",
  "releaseDate": "1994/12/2",
  "price": "9600",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "808",
  "ini": "ふ",
  "name": "ブレス オブ ファイアⅡ 使命の子",
  "maker": "カプコン",
  "releaseDate": "1994/12/2",
  "price": "9980",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "809",
  "ini": "う",
  "name": "ヴォルテックス",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/12/9",
  "price": "9900",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "810",
  "ini": "き",
  "name": "キャプテン翼Ⅴ 覇者の称号カンピオーネ",
  "maker": "テクモ",
  "releaseDate": "1994/12/9",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "811",
  "ini": "こ",
  "name": "ゴジラ 怪獣大決戦",
  "maker": "東宝",
  "releaseDate": "1994/12/9",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "812",
  "ini": "さ",
  "name": "三國志Ⅳ",
  "maker": "光栄",
  "releaseDate": "1994/12/9",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "51"
 },
 {
  "id": "813",
  "ini": "す",
  "name": "スーパー桃太郎電鉄Ⅲ",
  "maker": "ハドソン",
  "releaseDate": "1994/12/9",
  "price": "9500",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "814",
  "ini": "す",
  "name": "すごろクエスト++ ダイスニクス",
  "maker": "テクノスジャパン",
  "releaseDate": "1994/12/9",
  "price": "9900",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "815",
  "ini": "と",
  "name": "牌砦",
  "maker": "タカラ",
  "releaseDate": "1994/12/9",
  "price": "8800",
  "genre": "パズル",
  "icNo": "52"
 },
 {
  "id": "816",
  "ini": "は",
  "name": "バトルクロス",
  "maker": "イマジニア",
  "releaseDate": "1994/12/9",
  "price": "9800",
  "genre": "レース",
  "icNo": "55"
 },
 {
  "id": "817",
  "ini": "ら",
  "name": "ライオン・キング",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1994/12/9",
  "price": "9980",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "818",
  "ini": "わ",
  "name": "ワンダープロジェクトJ 機械の少年ピーノ",
  "maker": "エニックス",
  "releaseDate": "1994/12/9",
  "price": "11800",
  "genre": "育成・シミュレーション・アドベンチャー",
  "icNo": "56"
 },
 {
  "id": "819",
  "ini": "あ",
  "name": "蒼き伝説シュート！",
  "maker": "KSS",
  "releaseDate": "1994/12/16",
  "price": "10800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "820",
  "ini": "う",
  "name": "海釣り名人 スズキ編",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "釣り",
  "icNo": "52"
 },
 {
  "id": "821",
  "ini": "え",
  "name": "NBA LIVE 95",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "822",
  "ini": "か",
  "name": "がんばれゴエモン3 獅子重禄兵衛のからくり卍固め",
  "maker": "コナミ",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "823",
  "ini": "き",
  "name": "祇園花",
  "maker": "日本物産",
  "releaseDate": "1994/12/16",
  "price": "7980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "824",
  "ini": "す",
  "name": "スーパー・スネーキー",
  "maker": "四次元",
  "releaseDate": "1994/12/16",
  "price": "7800",
  "genre": "パズル",
  "icNo": "53"
 },
 {
  "id": "825",
  "ini": "す",
  "name": "スーパー詰将棋1000",
  "maker": "ボトムアップ",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "826",
  "ini": "す",
  "name": "スーパーテトリス3",
  "maker": "BPS",
  "releaseDate": "1994/12/16",
  "price": "8500",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "827",
  "ini": "す",
  "name": "スーパーフィッシング ビッグファイト",
  "maker": "ナグザット",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "釣り",
  "icNo": "0"
 },
 {
  "id": "828",
  "ini": "す",
  "name": "SUPERレッスルエンジェルス",
  "maker": "イマジニア",
  "releaseDate": "1994/12/16",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "829",
  "ini": "す",
  "name": "スキーパラダイス WITH スノーボード",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1994/12/16",
  "price": "8900",
  "genre": "スポーツ・3D",
  "icNo": "2"
 },
 {
  "id": "830",
  "ini": "と",
  "name": "どっすん！岩石バトル",
  "maker": "アイマックス",
  "releaseDate": "1994/12/16",
  "price": "8800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "831",
  "ini": "と",
  "name": "ドラえもん3 のび太と時の宝玉",
  "maker": "エポック社",
  "releaseDate": "1994/12/16",
  "price": "9500",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "832",
  "ini": "な",
  "name": "ナイジェルマンセル・インディカー",
  "maker": "アクレイムジャパン",
  "releaseDate": "1994/12/16",
  "price": "10900",
  "genre": "レース・3D",
  "icNo": "44"
 },
 {
  "id": "833",
  "ini": "は",
  "name": "花札王",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/12/16",
  "price": "8980",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "834",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンS 場外乱闘? 主役争奪戦",
  "maker": "エンジェル",
  "releaseDate": "1994/12/16",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "31"
 },
 {
  "id": "835",
  "ini": "ひ",
  "name": "ビッグ一撃！パチスロ大攻略",
  "maker": "アスク講談社",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "スロット",
  "icNo": "0"
 },
 {
  "id": "836",
  "ini": "ひ",
  "name": "必勝!パチスロファン",
  "maker": "POW",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "スロット",
  "icNo": "42"
 },
 {
  "id": "837",
  "ini": "ふ",
  "name": "フルパワー",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/12/16",
  "price": "11000",
  "genre": "レース・3D",
  "icNo": "30"
 },
 {
  "id": "838",
  "ini": "み",
  "name": "ミッキーの東京ディズニーランド大冒険",
  "maker": "トミー",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "839",
  "ini": "ろ",
  "name": "ロックマンX2",
  "maker": "カプコン",
  "releaseDate": "1994/12/16",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "840",
  "ini": "わ",
  "name": "ワギャンパラダイス",
  "maker": "ナムコ",
  "releaseDate": "1994/12/16",
  "price": "8800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "841",
  "ini": "さ",
  "name": "ザ・グレイトバトルⅣ",
  "maker": "バンプレスト",
  "releaseDate": "1994/12/17",
  "price": "9600",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "842",
  "ini": "て",
  "name": "テクモスーパーボウルⅡ スペシャルエディション",
  "maker": "テクモ",
  "releaseDate": "1994/12/20",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "843",
  "ini": "お",
  "name": "岡本綾子とマッチプレイゴルフ コ・オリナゴルフクラブ in ハワイ",
  "maker": "ツクダオリジナル",
  "releaseDate": "1994/12/21",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "63"
 },
 {
  "id": "844",
  "ini": "り",
  "name": "龍虎の拳2",
  "maker": "ザウルス",
  "releaseDate": "1994/12/21",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "845",
  "ini": "あ",
  "name": "アニマル武乱伝 ブルータル",
  "maker": "ケムコ",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "846",
  "ini": "あ",
  "name": "アルバートオデッセイ2 邪神の胎動",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/12/22",
  "price": "9980",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "847",
  "ini": "う",
  "name": "ウルトラベースボール実名版2",
  "maker": "カルチャーブレーン",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "848",
  "ini": "か",
  "name": "元祖パチンコ王",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1994/12/22",
  "price": "9980",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "849",
  "ini": "さ",
  "name": "サンスポフィッシング 渓流王",
  "maker": "イマジニア",
  "releaseDate": "1994/12/22",
  "price": "9980",
  "genre": "釣り",
  "icNo": "1"
 },
 {
  "id": "850",
  "ini": "す",
  "name": "スーパーキャッスルズ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "22"
 },
 {
  "id": "851",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング スペシャル",
  "maker": "ヒューマン",
  "releaseDate": "1994/12/22",
  "price": "11500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "852",
  "ini": "た",
  "name": "大貝獣物語",
  "maker": "ハドソン",
  "releaseDate": "1994/12/22",
  "price": "10900",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "853",
  "ini": "は",
  "name": "覇王大系リューナイト ロード オブ パラディン",
  "maker": "バンダイ",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "32"
 },
 {
  "id": "854",
  "ini": "は",
  "name": "はしれ へべれけ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/12/22",
  "price": "9500",
  "genre": "レース",
  "icNo": "42"
 },
 {
  "id": "855",
  "ini": "は",
  "name": "バトルジョッキー",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "856",
  "ini": "は",
  "name": "パワー オブ ザ ハイアード",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "60"
 },
 {
  "id": "857",
  "ini": "ほ",
  "name": "ポコニャン！",
  "maker": "東宝",
  "releaseDate": "1994/12/22",
  "price": "8800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "858",
  "ini": "ほ",
  "name": "本格将棋 風雲児龍王",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "859",
  "ini": "ま",
  "name": "麻雀倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1994/12/22",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "860",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 特別篇",
  "maker": "ナムコ",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "アニメーションバトル",
  "icNo": "10"
 },
 {
  "id": "861",
  "ini": "よ",
  "name": "横山光輝 三国志盤戯 スゴロク英雄記",
  "maker": "エンジェル",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "64"
 },
 {
  "id": "862",
  "ini": "ら",
  "name": "ライズ オブ ザ ロボッツ",
  "maker": "T&Eソフト",
  "releaseDate": "1994/12/22",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "863",
  "ini": "う",
  "name": "海腹川背",
  "maker": "TNN",
  "releaseDate": "1994/12/23",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "864",
  "ini": "こ",
  "name": "GO GO ACKMAN",
  "maker": "バンプレスト",
  "releaseDate": "1994/12/23",
  "price": "9000",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "865",
  "ini": "し",
  "name": "JWP女子プロレス ピュア・レッスル・クイーンズ",
  "maker": "ジャレコ",
  "releaseDate": "1994/12/23",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "866",
  "ini": "す",
  "name": "SUPER燃えろ!! プロ野球",
  "maker": "ジャレコ",
  "releaseDate": "1994/12/23",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "44"
 },
 {
  "id": "867",
  "ini": "は",
  "name": "パチスロ勝負師",
  "maker": "日本物産",
  "releaseDate": "1994/12/23",
  "price": "8980",
  "genre": "スロット",
  "icNo": "55"
 },
 {
  "id": "868",
  "ini": "き",
  "name": "機動武闘伝Gガンダム",
  "maker": "バンダイ",
  "releaseDate": "1994/12/27",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "869",
  "ini": "る",
  "name": "ルパン三世 伝説の秘宝を追え！",
  "maker": "エポック社",
  "releaseDate": "1994/12/27",
  "price": "9800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "870",
  "ini": "て",
  "name": "デュアルオーブⅡ",
  "maker": "アイマックス",
  "releaseDate": "1994/12/29",
  "price": "10800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "871",
  "ini": "す",
  "name": "スーパーヅガン2 ツカンポファイター 明菜コレクション",
  "maker": "J・ウイング",
  "releaseDate": "1994/12/30",
  "price": "9980",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "872",
  "ini": "え",
  "name": "X-MEN",
  "maker": "カプコン",
  "releaseDate": "1995/1/3",
  "price": "9980",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "873",
  "ini": "す",
  "name": "スーパーチャイニーズファイター",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/1/3",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "874",
  "ini": "た",
  "name": "高橋名人の大冒険島Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1995/1/3",
  "price": "9500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "875",
  "ini": "は",
  "name": "パックインタイム",
  "maker": "ナムコ",
  "releaseDate": "1995/1/3",
  "price": "7900",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "876",
  "ini": "ほ",
  "name": "ボンカーズ ハリウッド大作戦！",
  "maker": "カプコン",
  "releaseDate": "1995/1/3",
  "price": "8500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "877",
  "ini": "よ",
  "name": "YOGI BEAR",
  "maker": "マジファクト",
  "releaseDate": "1995/1/3",
  "price": "8800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "878",
  "ini": "き",
  "name": "ギャラクシーウォーズ",
  "maker": "イマジニア",
  "releaseDate": "1995/1/13",
  "price": "5980",
  "genre": "シューティング",
  "icNo": "52"
 },
 {
  "id": "879",
  "ini": "し",
  "name": "実戦！麻雀指南",
  "maker": "アスク講談社",
  "releaseDate": "1995/1/13",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "880",
  "ini": "し",
  "name": "雀遊記 悟空乱打",
  "maker": "ヴァージンゲーム",
  "releaseDate": "1995/1/13",
  "price": "8900",
  "genre": "テーブル・ミニゲーム",
  "icNo": "0"
 },
 {
  "id": "881",
  "ini": "は",
  "name": "パズルボブル",
  "maker": "タイトー",
  "releaseDate": "1995/1/13",
  "price": "6800",
  "genre": "パズル",
  "icNo": "56"
 },
 {
  "id": "882",
  "ini": "わ",
  "name": "わくわくスキー わんだぁシュプール",
  "maker": "ヒューマン",
  "releaseDate": "1995/1/13",
  "price": "9600",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "883",
  "ini": "す",
  "name": "スターダストスープレックス",
  "maker": "バリエ",
  "releaseDate": "1995/1/20",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "53"
 },
 {
  "id": "884",
  "ini": "た",
  "name": "ダービースタリオンⅢ",
  "maker": "アスキー",
  "releaseDate": "1995/1/20",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "885",
  "ini": "ま",
  "name": "マイケル・アンドレッティ インディカーチャレンジ",
  "maker": "BPS",
  "releaseDate": "1995/1/20",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "56"
 },
 {
  "id": "886",
  "ini": "う",
  "name": "ウルヴァリン",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/1/27",
  "price": "10900",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "887",
  "ini": "き",
  "name": "キテレツ大百科 超時空すごろく",
  "maker": "ビデオシステム",
  "releaseDate": "1995/1/27",
  "price": "8900",
  "genre": "テーブル・ミニゲーム",
  "icNo": "12"
 },
 {
  "id": "888",
  "ini": "こ",
  "name": "鋼鉄の騎士3 激突ヨーロッパ戦線",
  "maker": "アスミック",
  "releaseDate": "1995/1/27",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "22"
 },
 {
  "id": "889",
  "ini": "は",
  "name": "パチンコ物語2 名古屋シャチホコの帝王",
  "maker": "KSS",
  "releaseDate": "1995/1/27",
  "price": "9800",
  "genre": "パチンコ・ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "890",
  "ini": "み",
  "name": "みらくるカジノパラダイス",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1995/1/27",
  "price": "9800",
  "genre": "テーブル・ミニゲーム",
  "icNo": "31"
 },
 {
  "id": "891",
  "ini": "ほ",
  "name": "放課後 in Beppin 女学院",
  "maker": "イマジニア",
  "releaseDate": "1995/2/3",
  "price": "9980",
  "genre": "育成・シミュレーション",
  "icNo": "2"
 },
 {
  "id": "892",
  "ini": "あ",
  "name": "アイアンコマンドー 鋼鉄の戦士",
  "maker": "ポッポ",
  "releaseDate": "1995/2/10",
  "price": "9800",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "893",
  "ini": "き",
  "name": "機動戦士ガンダム CROSS DIMENSION 0079",
  "maker": "バンダイ",
  "releaseDate": "1995/2/10",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "43"
 },
 {
  "id": "894",
  "ini": "さ",
  "name": "さいばらりえこのまあじゃんほうろうき",
  "maker": "タイトー",
  "releaseDate": "1995/2/10",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "895",
  "ini": "さ",
  "name": "ザ・心理ゲーム2 マジカルトリップ",
  "maker": "ヴィジット",
  "releaseDate": "1995/2/10",
  "price": "8800",
  "genre": "その他",
  "icNo": "30"
 },
 {
  "id": "896",
  "ini": "し",
  "name": "ジャムズ",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1995/2/10",
  "price": "7800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "897",
  "ini": "た",
  "name": "だるま道場",
  "maker": "DEN'Z",
  "releaseDate": "1995/2/10",
  "price": "8800",
  "genre": "パズル",
  "icNo": "42"
 },
 {
  "id": "898",
  "ini": "か",
  "name": "柏木重孝のトップウォーターバッシング",
  "maker": "バップ",
  "releaseDate": "1995/2/17",
  "price": "14800",
  "genre": "釣り",
  "icNo": "50"
 },
 {
  "id": "899",
  "ini": "き",
  "name": "銀玉親方の実戦パチンコ必勝法",
  "maker": "サミー",
  "releaseDate": "1995/2/17",
  "price": "10500",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "900",
  "ini": "た",
  "name": "タイムコップ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/2/17",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "901",
  "ini": "ち",
  "name": "チェスマスター",
  "maker": "アルトロン",
  "releaseDate": "1995/2/17",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "902",
  "ini": "て",
  "name": "提督の決断Ⅱ",
  "maker": "光栄",
  "releaseDate": "1995/2/17",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "43"
 },
 {
  "id": "903",
  "ini": "ふ",
  "name": "ファイターズヒストリー 溝口危機一髪!!",
  "maker": "データイースト",
  "releaseDate": "1995/2/17",
  "price": "9900",
  "genre": "対戦格闘",
  "icNo": "52"
 },
 {
  "id": "904",
  "ini": "や",
  "name": "ヤムヤム",
  "maker": "バンダイ",
  "releaseDate": "1995/2/17",
  "price": "9800",
  "genre": "シューティング・3D・RPG",
  "icNo": "54"
 },
 {
  "id": "905",
  "ini": "ま",
  "name": "魔神転生ⅡSPIRAL NEMESIS",
  "maker": "アトラス",
  "releaseDate": "1995/2/19",
  "price": "10800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "906",
  "ini": "え",
  "name": "エストポリス伝記Ⅱ",
  "maker": "タイトー",
  "releaseDate": "1995/2/24",
  "price": "9980",
  "genre": "RPG",
  "icNo": "42"
 },
 {
  "id": "907",
  "ini": "え",
  "name": "NFLクォーターバッククラブ'95",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/2/24",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "908",
  "ini": "え",
  "name": "NBA JAM トーナメントエディション",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/2/24",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "909",
  "ini": "く",
  "name": "クラシック・ロードⅡ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/2/24",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "44"
 },
 {
  "id": "910",
  "ini": "し",
  "name": "実況パワフルプロ野球2",
  "maker": "コナミ",
  "releaseDate": "1995/2/24",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "911",
  "ini": "と",
  "name": "ドリフトキング 土屋圭市&坂東正明 首都高バトル2",
  "maker": "BPS",
  "releaseDate": "1995/2/24",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "912",
  "ini": "し",
  "name": "将棋倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1995/2/24",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "913",
  "ini": "す",
  "name": "スーパードリフトアウト",
  "maker": "ビスコ",
  "releaseDate": "1995/2/24",
  "price": "9980",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "914",
  "ini": "た",
  "name": "Turf Memories",
  "maker": "ベック",
  "releaseDate": "1995/2/24",
  "price": "11600",
  "genre": "レース",
  "icNo": "32"
 },
 {
  "id": "915",
  "ini": "ふ",
  "name": "From TV animation SLAM DUNK2 IH予選完全版!!",
  "maker": "バンダイ",
  "releaseDate": "1995/2/24",
  "price": "10800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "916",
  "ini": "な",
  "name": "NAGE LIBRE 静寂の水深",
  "maker": "バリエ",
  "releaseDate": "1995/2/24",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "62"
 },
 {
  "id": "917",
  "ini": "は",
  "name": "バトルピンボール",
  "maker": "バンプレスト",
  "releaseDate": "1995/2/24",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "12"
 },
 {
  "id": "918",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンS くるっくりん",
  "maker": "バンダイ",
  "releaseDate": "1995/2/24",
  "price": "6800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "919",
  "ini": "ふ",
  "name": "ファーランドストーリー",
  "maker": "バンプレスト",
  "releaseDate": "1995/2/24",
  "price": "10800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "920",
  "ini": "ふ",
  "name": "フロントミッション",
  "maker": "スクウェア",
  "releaseDate": "1995/2/24",
  "price": "11400",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "921",
  "ini": "へ",
  "name": "HEIWA パチンコワールド",
  "maker": "ショウエイシステム",
  "releaseDate": "1995/2/24",
  "price": "9900",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "922",
  "ini": "え",
  "name": "永遠のフィレーナ",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1995/2/25",
  "price": "9800",
  "genre": "RPG",
  "icNo": "13"
 },
 {
  "id": "923",
  "ini": "お",
  "name": "おとぼけ忍者コロシアム",
  "maker": "インテック",
  "releaseDate": "1995/2/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "924",
  "ini": "す",
  "name": "スーパーボンバーマン ぱにっくボンバーW",
  "maker": "ハドソン",
  "releaseDate": "1995/3/1",
  "price": "8900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "925",
  "ini": "あ",
  "name": "アンダーカバーコップス",
  "maker": "バリエ",
  "releaseDate": "1995/3/3",
  "price": "9800",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "926",
  "ini": "す",
  "name": "スーパーエアダイバー2",
  "maker": "アスミック",
  "releaseDate": "1995/3/3",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "30"
 },
 {
  "id": "927",
  "ini": "す",
  "name": "スーパーファミスタ4",
  "maker": "ナムコ",
  "releaseDate": "1995/3/3",
  "price": "9500",
  "genre": "スポーツ",
  "icNo": "53"
 },
 {
  "id": "928",
  "ini": "す",
  "name": "スーパーマッドチャンプ",
  "maker": "ツクダオリジナル",
  "releaseDate": "1995/3/4",
  "price": "10080",
  "genre": "レース・3D",
  "icNo": "52"
 },
 {
  "id": "929",
  "ini": "ら",
  "name": "ラストバイブルⅢ",
  "maker": "アトラス",
  "releaseDate": "1995/3/4",
  "price": "10800",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "930",
  "ini": "ま",
  "name": "マジカルポップン",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/3/10",
  "price": "9800",
  "genre": "アクション",
  "icNo": "41"
 },
 {
  "id": "931",
  "ini": "く",
  "name": "クロノ・トリガー",
  "maker": "スクウェア",
  "releaseDate": "1995/3/11",
  "price": "11400",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "932",
  "ini": "お",
  "name": "おらがランド主催 ベストファーマー収穫祭",
  "maker": "ビック東海",
  "releaseDate": "1995/3/17",
  "price": "7800",
  "genre": "アクション・パズル",
  "icNo": "0"
 },
 {
  "id": "933",
  "ini": "き",
  "name": "キャプテンコマンドー",
  "maker": "カプコン",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "934",
  "ini": "し",
  "name": "Jリーグ スーパーサッカー'95 実況スタジアム",
  "maker": "ハドソン",
  "releaseDate": "1995/3/17",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "935",
  "ini": "す",
  "name": "スーパー雀豪",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "936",
  "ini": "す",
  "name": "スーパーピンボールⅡ ザ・アメイジング・オデッセイ",
  "maker": "メルダック",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "937",
  "ini": "す",
  "name": "スーパーボンブリス",
  "maker": "BPS",
  "releaseDate": "1995/3/17",
  "price": "7500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "938",
  "ini": "す",
  "name": "スパイダーマン リーサルフォーズ",
  "maker": "エポック社",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "939",
  "ini": "す",
  "name": "スプリンター物語 めざせ!!一攫千金",
  "maker": "バップ",
  "releaseDate": "1995/3/17",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "43"
 },
 {
  "id": "940",
  "ini": "た",
  "name": "第4次スーパーロボット大戦",
  "maker": "バンプレスト",
  "releaseDate": "1995/3/17",
  "price": "12800",
  "genre": "シミュレーション・RPG",
  "icNo": "52"
 },
 {
  "id": "941",
  "ini": "ね",
  "name": "熱血大陸バーニングヒーローズ",
  "maker": "エニックス",
  "releaseDate": "1995/3/17",
  "price": "10800",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "942",
  "ini": "は",
  "name": "バトルレーサーズ",
  "maker": "バンプレスト",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "32"
 },
 {
  "id": "943",
  "ini": "ま",
  "name": "負けるな！魔剣道2 決めろ！妖怪総理大臣",
  "maker": "データム・ポリスター",
  "releaseDate": "1995/3/17",
  "price": "9500",
  "genre": "対戦格闘",
  "icNo": "2"
 },
 {
  "id": "944",
  "ini": "ら",
  "name": "ラブクエスト",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1995/3/17",
  "price": "9800",
  "genre": "RPG",
  "icNo": "12"
 },
 {
  "id": "945",
  "ini": "う",
  "name": "ウイニングポスト2",
  "maker": "光栄",
  "releaseDate": "1995/3/18",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "54"
 },
 {
  "id": "946",
  "ini": "た",
  "name": "TURF HERO",
  "maker": "テクモ",
  "releaseDate": "1995/3/21",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "44"
 },
 {
  "id": "947",
  "ini": "こ",
  "name": "高速思考 将棋皇",
  "maker": "イマジニア",
  "releaseDate": "1995/3/24",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "948",
  "ini": "し",
  "name": "THE ATLUS",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/3/24",
  "price": "10800",
  "genre": "探索・シミュレーション",
  "icNo": "32"
 },
 {
  "id": "949",
  "ini": "す",
  "name": "スーパー馬券王'95",
  "maker": "テイチク",
  "releaseDate": "1995/3/24",
  "price": "9980",
  "genre": "競馬予想",
  "icNo": "21"
 },
 {
  "id": "950",
  "ini": "と",
  "name": "ドラゴンボールZ 超悟空伝 突撃編",
  "maker": "バンダイ",
  "releaseDate": "1995/3/24",
  "price": "10800",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "951",
  "ini": "ひ",
  "name": "必殺パチンココレクション2",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/3/24",
  "price": "9980",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "952",
  "ini": "ゆ",
  "name": "幽☆遊☆白書FINAL 魔界最強列伝",
  "maker": "ナムコ",
  "releaseDate": "1995/3/24",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "43"
 },
 {
  "id": "953",
  "ini": "ろ",
  "name": "ロックマン7 宿命の対決！",
  "maker": "カプコン",
  "releaseDate": "1995/3/24",
  "price": "9800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "954",
  "ini": "え",
  "name": "EMIT Vol.1 時の迷子",
  "maker": "光栄",
  "releaseDate": "1995/3/25",
  "price": "11800",
  "genre": "アニメ・学習",
  "icNo": "44"
 },
 {
  "id": "955",
  "ini": "え",
  "name": "EMIT Vol.2 命がけの旅",
  "maker": "光栄",
  "releaseDate": "1995/3/25",
  "price": "11800",
  "genre": "アニメ・学習",
  "icNo": "40"
 },
 {
  "id": "956",
  "ini": "え",
  "name": "EMIT Vol.3 私にさよならを",
  "maker": "光栄",
  "releaseDate": "1995/3/25",
  "price": "11800",
  "genre": "アニメ・学習",
  "icNo": "52"
 },
 {
  "id": "957",
  "ini": "は",
  "name": "Parlor！パーラー！パチンコ3社・実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1995/3/30",
  "price": "11800",
  "genre": "パチンコ",
  "icNo": "56"
 },
 {
  "id": "958",
  "ini": "ふ",
  "name": "復刻版 スーパー三國志II",
  "maker": "光栄",
  "releaseDate": "1995/3/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "959",
  "ini": "あ",
  "name": "RPGツクール SUPER DANTE",
  "maker": "アスキー",
  "releaseDate": "1995/3/31",
  "price": "9800",
  "genre": "ゲーム制作",
  "icNo": "32"
 },
 {
  "id": "960",
  "ini": "え",
  "name": "エスパークス 異次空からの来訪者",
  "maker": "トミー",
  "releaseDate": "1995/3/31",
  "price": "9500",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "961",
  "ini": "き",
  "name": "旧約・女神転生",
  "maker": "アトラス",
  "releaseDate": "1995/3/31",
  "price": "10800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "962",
  "ini": "き",
  "name": "近代麻雀スペシャル",
  "maker": "イマジニア",
  "releaseDate": "1995/3/31",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "963",
  "ini": "さ",
  "name": "ザ・モノポリーゲーム2",
  "maker": "トミー",
  "releaseDate": "1995/3/31",
  "price": "11500",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "964",
  "ini": "し",
  "name": "史上最強リーグ セリエA エースストライカー",
  "maker": "TNN",
  "releaseDate": "1995/3/31",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "965",
  "ini": "さ",
  "name": "最高速思考 将棋 麻雀",
  "maker": "バリエ",
  "releaseDate": "1995/3/31",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "55"
 },
 {
  "id": "966",
  "ini": "す",
  "name": "スーパーフォーメーションサッカー95 della セリエA",
  "maker": "ヒューマン",
  "releaseDate": "1995/3/31",
  "price": "9980",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "967",
  "ini": "て",
  "name": "であえ殿様 あっぱれ一番",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/3/31",
  "price": "9800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "968",
  "ini": "は",
  "name": "羽生名人のおもしろ将棋",
  "maker": "トミー",
  "releaseDate": "1995/3/31",
  "price": "12000",
  "genre": "テーブル",
  "icNo": "20"
 },
 {
  "id": "969",
  "ini": "み",
  "name": "ミッキーマニア",
  "maker": "カプコン",
  "releaseDate": "1995/3/31",
  "price": "9500",
  "genre": "アクション",
  "icNo": "62"
 },
 {
  "id": "970",
  "ini": "れ",
  "name": "レディーストーカー 過去からの挑戦",
  "maker": "タイトー",
  "releaseDate": "1995/4/1",
  "price": "9980",
  "genre": "アクション・RPG",
  "icNo": "12"
 },
 {
  "id": "971",
  "ini": "せ",
  "name": "全日本プロレス2　3・4武闘館",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1995/4/7",
  "price": "10080",
  "genre": "スポーツ",
  "icNo": "43"
 },
 {
  "id": "972",
  "ini": "は",
  "name": "ぱずるんでス！",
  "maker": "日本物産",
  "releaseDate": "1995/4/14",
  "price": "8980",
  "genre": "パズル",
  "icNo": "43"
 },
 {
  "id": "973",
  "ini": "て",
  "name": "ディアーナ・レイ 占いの迷宮",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1995/4/14",
  "price": "9500",
  "genre": "その他",
  "icNo": "43"
 },
 {
  "id": "974",
  "ini": "し",
  "name": "新SD戦国伝 大将軍列伝",
  "maker": "ベック",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "975",
  "ini": "す",
  "name": "スーパートランプコレクション",
  "maker": "ボトムアップ",
  "releaseDate": "1995/4/21",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "976",
  "ini": "す",
  "name": "スーパーリアル麻雀PVパラダイス オールスター4人打ち",
  "maker": "セタ",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "31"
 },
 {
  "id": "977",
  "ini": "た",
  "name": "タクティカル・サッカー",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "978",
  "ini": "な",
  "name": "なつきクライシスバトル",
  "maker": "エンジェル",
  "releaseDate": "1995/4/21",
  "price": "10800",
  "genre": "対戦格闘",
  "icNo": "44"
 },
 {
  "id": "979",
  "ini": "ま",
  "name": "魔法陣グルグル",
  "maker": "エニックス",
  "releaseDate": "1995/4/21",
  "price": "10800",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "980",
  "ini": "ま",
  "name": "ママレード・ボーイ",
  "maker": "バンダイ",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "恋愛・シミュレーション",
  "icNo": "31"
 },
 {
  "id": "981",
  "ini": "み",
  "name": "宮路社長のパチンコファン勝利宣言2",
  "maker": "POW",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "982",
  "ini": "ら",
  "name": "真・聖刻",
  "maker": "ユタカ",
  "releaseDate": "1995/4/21",
  "price": "9800",
  "genre": "RPG",
  "icNo": "44"
 },
 {
  "id": "983",
  "ini": "り",
  "name": "リジョイス　アレサ王国の彼方",
  "maker": "やのまん",
  "releaseDate": "1995/4/21",
  "price": "9900",
  "genre": "アクション・RPG",
  "icNo": "34"
 },
 {
  "id": "984",
  "ini": "か",
  "name": "川のぬし釣り2",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/4/28",
  "price": "10800",
  "genre": "釣り・RPG",
  "icNo": "62"
 },
 {
  "id": "985",
  "ini": "さ",
  "name": "3次元格闘ボールズ",
  "maker": "メディアリング",
  "releaseDate": "1995/4/28",
  "price": "9800",
  "genre": "対戦格闘・3D",
  "icNo": "41"
 },
 {
  "id": "986",
  "ini": "し",
  "name": "Jリーグ エキサイトステージ'95",
  "maker": "エポック社",
  "releaseDate": "1995/4/28",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "987",
  "ini": "し",
  "name": "シミュレーションプロ野球",
  "maker": "ヘクト",
  "releaseDate": "1995/4/28",
  "price": "12800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "988",
  "ini": "し",
  "name": "初段位認定 初段プロ麻雀",
  "maker": "ギャップス",
  "releaseDate": "1995/4/28",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "989",
  "ini": "す",
  "name": "スーパーパチンコ大戦",
  "maker": "バンプレスト",
  "releaseDate": "1995/4/28",
  "price": "6900",
  "genre": "パチンコ",
  "icNo": "32"
 },
 {
  "id": "990",
  "ini": "す",
  "name": "スーパーボンバーマン3",
  "maker": "ハドソン",
  "releaseDate": "1995/4/28",
  "price": "8900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "991",
  "ini": "す",
  "name": "ストーンプロテクターズ",
  "maker": "ケムコ",
  "releaseDate": "1995/4/28",
  "price": "9800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "992",
  "ini": "た",
  "name": "タロットミステリー",
  "maker": "ヴィジット",
  "releaseDate": "1995/4/28",
  "price": "9800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "993",
  "ini": "と",
  "name": "トゥルーライズ",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/4/28",
  "price": "10900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "994",
  "ini": "は",
  "name": "牌勢麻雀 凌駕",
  "maker": "アスキー",
  "releaseDate": "1995/4/28",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "61"
 },
 {
  "id": "995",
  "ini": "ふ",
  "name": "プラネットチャンプ TG3000",
  "maker": "ケムコ",
  "releaseDate": "1995/4/28",
  "price": "9500",
  "genre": "レース・3D",
  "icNo": "10"
 },
 {
  "id": "996",
  "ini": "し",
  "name": "シンジケート",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1995/5/19",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "997",
  "ini": "す",
  "name": "スーパー競馬2",
  "maker": "アイマックス",
  "releaseDate": "1995/5/19",
  "price": "11800",
  "genre": "育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "998",
  "ini": "す",
  "name": "スヌーピーコンサート",
  "maker": "三井不動産、電通",
  "releaseDate": "1995/5/19",
  "price": "9800",
  "genre": "アクション・アドベンチャー",
  "icNo": "10"
 },
 {
  "id": "999",
  "ini": "は",
  "name": "バトルタイクーン",
  "maker": "ライトスタッフ",
  "releaseDate": "1995/5/19",
  "price": "10980",
  "genre": "対戦格闘",
  "icNo": "63"
 },
 {
  "id": "1000",
  "ini": "う",
  "name": "ウォーロック",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/5/26",
  "price": "10900",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "1001",
  "ini": "く",
  "name": "グール・パトロール",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/5/26",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1002",
  "ini": "こ",
  "name": "コンピュータ脳力解析 ウルトラ馬券",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/5/26",
  "price": "12800",
  "genre": "競馬予想",
  "icNo": "0"
 },
 {
  "id": "1003",
  "ini": "し",
  "name": "シムシティ2000",
  "maker": "イマジニア",
  "releaseDate": "1995/5/26",
  "price": "12800",
  "genre": "開発・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1004",
  "ini": "す",
  "name": "す～ぱ～なぞぷよ ルルーのルー",
  "maker": "バンプレスト",
  "releaseDate": "1995/5/26",
  "price": "9200",
  "genre": "パズル",
  "icNo": "53"
 },
 {
  "id": "1005",
  "ini": "す",
  "name": "スターゲイト",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/5/26",
  "price": "10900",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "1006",
  "ini": "す",
  "name": "スパーク・ワールド",
  "maker": "DEN'Z",
  "releaseDate": "1995/5/26",
  "price": "8500",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "1007",
  "ini": "に",
  "name": "ニチブツ アーケードクラシックス",
  "maker": "日本物産",
  "releaseDate": "1995/5/26",
  "price": "5980",
  "genre": "シューティング・アクション",
  "icNo": "43"
 },
 {
  "id": "1008",
  "ini": "は",
  "name": "パチンコ連チャン天国 スーパーCRスペシャル",
  "maker": "バップ",
  "releaseDate": "1995/5/26",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "32"
 },
 {
  "id": "1009",
  "ini": "は",
  "name": "早指し二段 森田将棋2",
  "maker": "セタ",
  "releaseDate": "1995/5/26",
  "price": "14900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1010",
  "ini": "し",
  "name": "真髄対局囲碁 碁仙人",
  "maker": "J・ウイング",
  "releaseDate": "1995/6/2",
  "price": "15500",
  "genre": "テーブル",
  "icNo": "33"
 },
 {
  "id": "1011",
  "ini": "え",
  "name": "エルファリアⅡ",
  "maker": "ハドソン",
  "releaseDate": "1995/6/9",
  "price": "9980",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1012",
  "ini": "よ",
  "name": "妖怪バスター ルカの大冒険",
  "maker": "角川書店",
  "releaseDate": "1995/6/9",
  "price": "8800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1013",
  "ini": "ほ",
  "name": "本家SANKYO FEVER 実機シミュレーション",
  "maker": "DEN'Z",
  "releaseDate": "1995/6/10",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "43"
 },
 {
  "id": "1014",
  "ini": "や",
  "name": "夜光虫",
  "maker": "アテナ",
  "releaseDate": "1995/6/16",
  "price": "10800",
  "genre": "サウンドノベル",
  "icNo": "1"
 },
 {
  "id": "1015",
  "ini": "あ",
  "name": "アースワーム・ジム",
  "maker": "タカラ",
  "releaseDate": "1995/6/23",
  "price": "9800",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "1016",
  "ini": "し",
  "name": "実戦競艇",
  "maker": "イマジニア",
  "releaseDate": "1995/6/23",
  "price": "11800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1017",
  "ini": "す",
  "name": "スーパー・スター・ウォーズ ジェダイの復讐",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/6/23",
  "price": "10800",
  "genre": "アクション・シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "1018",
  "ini": "と",
  "name": "トランプアイランド",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/6/23",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "55"
 },
 {
  "id": "1019",
  "ini": "に",
  "name": "西陣パチンコ物語",
  "maker": "KSS",
  "releaseDate": "1995/6/23",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1020",
  "ini": "ひ",
  "name": "Pマン",
  "maker": "ケムコ",
  "releaseDate": "1995/6/23",
  "price": "9200",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "1021",
  "ini": "ふ",
  "name": "プリンセス・ミネルバ",
  "maker": "ビック東海",
  "releaseDate": "1995/6/23",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1022",
  "ini": "み",
  "name": "Mr.Do！",
  "maker": "イマジニア",
  "releaseDate": "1995/6/23",
  "price": "5980",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1023",
  "ini": "る",
  "name": "ルインアーム",
  "maker": "バンダイ",
  "releaseDate": "1995/6/23",
  "price": "10800",
  "genre": "アクション・RPG",
  "icNo": "54"
 },
 {
  "id": "1024",
  "ini": "お",
  "name": "大物ブラックバスフィッシング 人造湖編",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/6/30",
  "price": "12800",
  "genre": "釣り",
  "icNo": "30"
 },
 {
  "id": "1025",
  "ini": "か",
  "name": "ガメラ ギャオス撃滅作戦",
  "maker": "サミー",
  "releaseDate": "1995/6/30",
  "price": "9980",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "1026",
  "ini": "く",
  "name": "グランヒストリア 幻史世界記",
  "maker": "バンプレスト",
  "releaseDate": "1995/6/30",
  "price": "11400",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1027",
  "ini": "さ",
  "name": "サーキットUSA",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1995/6/30",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "0"
 },
 {
  "id": "1028",
  "ini": "し",
  "name": "新日本プロレスリング公認 '95闘強導夢BATTLE7",
  "maker": "バリエ",
  "releaseDate": "1995/6/30",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1029",
  "ini": "す",
  "name": "スーパー競艇",
  "maker": "日本物産",
  "releaseDate": "1995/6/30",
  "price": "9500",
  "genre": "レース・シミュレーション",
  "icNo": "56"
 },
 {
  "id": "1030",
  "ini": "す",
  "name": "スーパーファイヤープロレスリング クイーンズスペシャル",
  "maker": "ヒューマン",
  "releaseDate": "1995/6/30",
  "price": "11400",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1031",
  "ini": "て",
  "name": "デア ラングリッサー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1995/6/30",
  "price": "10800",
  "genre": "シミュレーション・RPG",
  "icNo": "22"
 },
 {
  "id": "1032",
  "ini": "と",
  "name": "とっても！ラッキーマン ラッキークッキールーレットで突撃",
  "maker": "バンダイ",
  "releaseDate": "1995/6/30",
  "price": "8800",
  "genre": "テーブル・RPG",
  "icNo": "22"
 },
 {
  "id": "1033",
  "ini": "ふ",
  "name": "復刻版 提督の決断",
  "maker": "光栄",
  "releaseDate": "1995/6/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "1034",
  "ini": "ふ",
  "name": "プロ麻雀 極Ⅲ",
  "maker": "アテナ",
  "releaseDate": "1995/6/30",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "32"
 },
 {
  "id": "1035",
  "ini": "り",
  "name": "リトルマスター 虹色の魔石",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1995/6/30",
  "price": "9900",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "1036",
  "ini": "き",
  "name": "キャラバンシューティングコレクション",
  "maker": "ハドソン",
  "releaseDate": "1995/7/7",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "1037",
  "ini": "し",
  "name": "史記英雄伝",
  "maker": "アウトリガー工房",
  "releaseDate": "1995/7/7",
  "price": "10800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1038",
  "ini": "し",
  "name": "実戦！パチスロ必勝法！クラシック",
  "maker": "サミー",
  "releaseDate": "1995/7/7",
  "price": "9500",
  "genre": "スロット",
  "icNo": "0"
 },
 {
  "id": "1039",
  "ini": "し",
  "name": "真 一攫千金",
  "maker": "バップ",
  "releaseDate": "1995/7/7",
  "price": "9800",
  "genre": "テーブル・その他",
  "icNo": "11"
 },
 {
  "id": "1040",
  "ini": "す",
  "name": "スーパーF1サーカス外伝",
  "maker": "日本物産",
  "releaseDate": "1995/7/7",
  "price": "9900",
  "genre": "レース・3D",
  "icNo": "54"
 },
 {
  "id": "1041",
  "ini": "て",
  "name": "できたてハイスクール",
  "maker": "BPS",
  "releaseDate": "1995/7/7",
  "price": "9990",
  "genre": "育成・シミュレーション",
  "icNo": "54"
 },
 {
  "id": "1042",
  "ini": "は",
  "name": "パチンコチャレンジャー",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1995/7/7",
  "price": "9800",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1043",
  "ini": "か",
  "name": "キャッツラン 全日本Kカー選手権",
  "maker": "アトラス",
  "releaseDate": "1995/7/14",
  "price": "10800",
  "genre": "レース・3D",
  "icNo": "13"
 },
 {
  "id": "1044",
  "ini": "こ",
  "name": "甲子園4",
  "maker": "魔法",
  "releaseDate": "1995/7/14",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "1045",
  "ini": "こ",
  "name": "攻略カジノバー",
  "maker": "日本物産",
  "releaseDate": "1995/7/14",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "40"
 },
 {
  "id": "1046",
  "ini": "す",
  "name": "スーパー競輪",
  "maker": "アイマックス",
  "releaseDate": "1995/7/14",
  "price": "9800",
  "genre": "競輪・シミュレーション",
  "icNo": "53"
 },
 {
  "id": "1047",
  "ini": "ひ",
  "name": "ピットフォール マヤの大冒険",
  "maker": "ポニーキャニオン",
  "releaseDate": "1995/7/14",
  "price": "9800",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "1048",
  "ini": "み",
  "name": "ミスティックアーク",
  "maker": "エニックス",
  "releaseDate": "1995/7/14",
  "price": "11800",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "1049",
  "ini": "よ",
  "name": "4人将棋",
  "maker": "POW",
  "releaseDate": "1995/7/14",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1050",
  "ini": "ら",
  "name": "ラプラスの魔",
  "maker": "ビック東海",
  "releaseDate": "1995/7/14",
  "price": "9900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1051",
  "ini": "あ",
  "name": "悪魔城ドラキュラXX",
  "maker": "コナミ",
  "releaseDate": "1995/7/21",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1052",
  "ini": "こ",
  "name": "GO GO ACKMAN2",
  "maker": "バンプレスト",
  "releaseDate": "1995/7/21",
  "price": "9500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1053",
  "ini": "し",
  "name": "将棋最強",
  "maker": "魔法",
  "releaseDate": "1995/7/21",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "1054",
  "ini": "す",
  "name": "SUPERヴァリアブル・ジオ",
  "maker": "テイジイエル",
  "releaseDate": "1995/7/21",
  "price": "9980",
  "genre": "対戦格闘",
  "icNo": "32"
 },
 {
  "id": "1055",
  "ini": "た",
  "name": "武豊 GⅠメモリー",
  "maker": "NGP",
  "releaseDate": "1995/7/21",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "56"
 },
 {
  "id": "1056",
  "ini": "た",
  "name": "ダンクエスト 魔神封印の伝説",
  "maker": "テクノスジャパン",
  "releaseDate": "1995/7/21",
  "price": "9900",
  "genre": "アクション・RPG",
  "icNo": "22"
 },
 {
  "id": "1057",
  "ini": "ひ",
  "name": "ビッグ一撃！パチスロ大攻略2 ユニバーサル・コレクション",
  "maker": "アスク講談社",
  "releaseDate": "1995/7/21",
  "price": "10800",
  "genre": "パチスロ",
  "icNo": "0"
 },
 {
  "id": "1058",
  "ini": "ら",
  "name": "らんま1/2 奥義邪暗拳",
  "maker": "東宝、小学館プロダクション",
  "releaseDate": "1995/7/21",
  "price": "8800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1059",
  "ini": "い",
  "name": "インディ・ジョーンズ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/7/28",
  "price": "10800",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "1060",
  "ini": "う",
  "name": "ウルティマ 恐竜帝国",
  "maker": "ポニーキャニオン",
  "releaseDate": "1995/7/28",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "10"
 },
 {
  "id": "1061",
  "ini": "う",
  "name": "ウルトラリーグ 燃えろ！サッカー大決戦!!",
  "maker": "ユタカ",
  "releaseDate": "1995/7/28",
  "price": "9800",
  "genre": "スポーツ・アクション",
  "icNo": "0"
 },
 {
  "id": "1062",
  "ini": "え",
  "name": "エメラルドドラゴン",
  "maker": "メディアワークス",
  "releaseDate": "1995/7/28",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1063",
  "ini": "き",
  "name": "キャリアエース",
  "maker": "ユーメディア",
  "releaseDate": "1995/7/28",
  "price": "12800",
  "genre": "シューティング・3D",
  "icNo": "56"
 },
 {
  "id": "1064",
  "ini": "し",
  "name": "就職ゲーム",
  "maker": "イマジニア",
  "releaseDate": "1995/7/28",
  "price": "11800",
  "genre": "アドベンチャー",
  "icNo": "43"
 },
 {
  "id": "1065",
  "ini": "す",
  "name": "超原人2",
  "maker": "ハドソン",
  "releaseDate": "1995/7/28",
  "price": "8900",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "1066",
  "ini": "に",
  "name": "忍たま乱太郎",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/7/28",
  "price": "9800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "1067",
  "ini": "は",
  "name": "バスマスターズ クラシック",
  "maker": "アルトロン",
  "releaseDate": "1995/7/28",
  "price": "11800",
  "genre": "釣り",
  "icNo": "0"
 },
 {
  "id": "1068",
  "ini": "ほ",
  "name": "ポポイっと へべれけ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/7/28",
  "price": "8900",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "1069",
  "ini": "ま",
  "name": "麻雀繁盛記",
  "maker": "日本物産",
  "releaseDate": "1995/7/28",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1070",
  "ini": "い",
  "name": "稲妻サーブだ!!スーパービーチバレー",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1995/8/4",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "1071",
  "ini": "か",
  "name": "学校であった怖い話",
  "maker": "バンプレスト",
  "releaseDate": "1995/8/4",
  "price": "11800",
  "genre": "サウンドノベル",
  "icNo": "0"
 },
 {
  "id": "1072",
  "ini": "き",
  "name": "鬼神童子ZENKI 烈闘雷伝",
  "maker": "ハドソン",
  "releaseDate": "1995/8/4",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1073",
  "ini": "し",
  "name": "Jリーグサッカー プライムゴール3",
  "maker": "ナムコ",
  "releaseDate": "1995/8/4",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1074",
  "ini": "ち",
  "name": "超魔法大陸WOZZ",
  "maker": "BPS",
  "releaseDate": "1995/8/4",
  "price": "10800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1075",
  "ini": "す",
  "name": "スーパーマリオ ヨッシーアイランド",
  "maker": "任天堂",
  "releaseDate": "1995/8/5",
  "price": "9800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "1076",
  "ini": "す",
  "name": "スーパーパワーリーグ3",
  "maker": "ハドソン",
  "releaseDate": "1995/8/10",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "50"
 },
 {
  "id": "1077",
  "ini": "け",
  "name": "ゲームの達人",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/8/11",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "21"
 },
 {
  "id": "1078",
  "ini": "す",
  "name": "すーぱーぐっすんおよよ",
  "maker": "バンプレスト",
  "releaseDate": "1995/8/11",
  "price": "9980",
  "genre": "パズル",
  "icNo": "13"
 },
 {
  "id": "1079",
  "ini": "た",
  "name": "武宮正樹九段の囲碁大将",
  "maker": "KSS",
  "releaseDate": "1995/8/11",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "21"
 },
 {
  "id": "1080",
  "ini": "て",
  "name": "天地を喰らう 三国志群雄伝",
  "maker": "カプコン",
  "releaseDate": "1995/8/11",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "20"
 },
 {
  "id": "1081",
  "ini": "と",
  "name": "ドナルドダックの魔法のぼうし",
  "maker": "エポック社",
  "releaseDate": "1995/8/11",
  "price": "9800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "1082",
  "ini": "に",
  "name": "忍者龍剣伝 巴",
  "maker": "テクモ",
  "releaseDate": "1995/8/11",
  "price": "7980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1083",
  "ini": "ふ",
  "name": "ファイティング ベースボール",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1995/8/11",
  "price": "9400",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1084",
  "ini": "ふ",
  "name": "ブラックソーン 復讐の黒き棘",
  "maker": "ケムコ",
  "releaseDate": "1995/8/11",
  "price": "9400",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "1085",
  "ini": "ふ",
  "name": "ブランディッシュ2",
  "maker": "光栄",
  "releaseDate": "1995/8/11",
  "price": "10800",
  "genre": "アクション・RPG",
  "icNo": "50"
 },
 {
  "id": "1086",
  "ini": "は",
  "name": "Parlor！パーラー！2 パチンコ5社・実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1995/8/25",
  "price": "11800",
  "genre": "パチンコ",
  "icNo": "22"
 },
 {
  "id": "1087",
  "ini": "こ",
  "name": "ころんらんど",
  "maker": "ユーメディア",
  "releaseDate": "1995/8/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "1088",
  "ini": "さ",
  "name": "ザ・心理ゲーム3",
  "maker": "ヴィジット",
  "releaseDate": "1995/8/25",
  "price": "9800",
  "genre": "その他",
  "icNo": "44"
 },
 {
  "id": "1089",
  "ini": "し",
  "name": "実戦!バスフィッシング必勝法 in USA",
  "maker": "サミー",
  "releaseDate": "1995/8/25",
  "price": "9800",
  "genre": "釣り",
  "icNo": "43"
 },
 {
  "id": "1090",
  "ini": "ひ",
  "name": "ヒューマングランプリ4 F1ドリームバトル",
  "maker": "ヒューマン",
  "releaseDate": "1995/8/25",
  "price": "11400",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "1091",
  "ini": "ま",
  "name": "魔獣王",
  "maker": "KSS",
  "releaseDate": "1995/8/25",
  "price": "10800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1092",
  "ini": "ま",
  "name": "松方弘樹のスーパートローリング",
  "maker": "トンキンハウス",
  "releaseDate": "1995/8/25",
  "price": "9900",
  "genre": "釣り",
  "icNo": "32"
 },
 {
  "id": "1093",
  "ini": "か",
  "name": "柿木将棋",
  "maker": "アスキー",
  "releaseDate": "1995/9/1",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1094",
  "ini": "は",
  "name": "バトルロボット烈伝",
  "maker": "バンプレスト",
  "releaseDate": "1995/9/1",
  "price": "12800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "1095",
  "ini": "す",
  "name": "SUPER人生ゲーム2",
  "maker": "タカラ",
  "releaseDate": "1995/9/8",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1096",
  "ini": "は",
  "name": "バウンティ・ソード",
  "maker": "パイオニアLDC",
  "releaseDate": "1995/9/8",
  "price": "11400",
  "genre": "シミュレーション・RPG",
  "icNo": "42"
 },
 {
  "id": "1097",
  "ini": "く",
  "name": "クロックタワー",
  "maker": "ヒューマン",
  "releaseDate": "1995/9/14",
  "price": "11400",
  "genre": "アクション・アドベンチャー",
  "icNo": "44"
 },
 {
  "id": "1098",
  "ini": "さ",
  "name": "桜井章一の雀鬼流麻雀必勝法",
  "maker": "サミー",
  "releaseDate": "1995/9/14",
  "price": "9980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1099",
  "ini": "ま",
  "name": "マリオのスーパーピクロス",
  "maker": "任天堂",
  "releaseDate": "1995/9/14",
  "price": "7900",
  "genre": "パズル",
  "icNo": "30"
 },
 {
  "id": "1100",
  "ini": "あ",
  "name": "アリスのペイントアドベンチャー",
  "maker": "エポック社",
  "releaseDate": "1995/9/15",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "10"
 },
 {
  "id": "1101",
  "ini": "す",
  "name": "スーパー鉄球ファイト！",
  "maker": "バンプレスト",
  "releaseDate": "1995/9/15",
  "price": "9000",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "1102",
  "ini": "せ",
  "name": "セント・アンドリュース 栄光と歴史のオールドコース",
  "maker": "エポック社",
  "releaseDate": "1995/9/15",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "54"
 },
 {
  "id": "1103",
  "ini": "ひ",
  "name": "必勝777ファイターⅢ 黒竜王の復活",
  "maker": "バップ",
  "releaseDate": "1995/9/15",
  "price": "10800",
  "genre": "パチスロ",
  "icNo": "1"
 },
 {
  "id": "1104",
  "ini": "あ",
  "name": "朝日新聞連載 加藤一二三九段将棋 心技流",
  "maker": "バリエ",
  "releaseDate": "1995/9/22",
  "price": "12300",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1105",
  "ini": "し",
  "name": "四柱推命学入門 真桃源郷",
  "maker": "バンプレスト",
  "releaseDate": "1995/9/22",
  "price": "11800",
  "genre": "その他・ミニゲーム",
  "icNo": "42"
 },
 {
  "id": "1106",
  "ini": "し",
  "name": "実況ワールドサッカー2 FIGHTING ELEVEN",
  "maker": "コナミ",
  "releaseDate": "1995/9/22",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "32"
 },
 {
  "id": "1107",
  "ini": "し",
  "name": "ジャングルストライク 受け継がれた狂気",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1995/9/22",
  "price": "9900",
  "genre": "シューティング",
  "icNo": "44"
 },
 {
  "id": "1108",
  "ini": "し",
  "name": "新・将棋倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1995/9/22",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "56"
 },
 {
  "id": "1109",
  "ini": "ち",
  "name": "超兄貴 爆烈乱闘篇",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1995/9/22",
  "price": "11800",
  "genre": "対戦格闘",
  "icNo": "52"
 },
 {
  "id": "1110",
  "ini": "ち",
  "name": "陳牌",
  "maker": "バンプレスト",
  "releaseDate": "1995/9/22",
  "price": "7800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "1111",
  "ini": "と",
  "name": "ドラゴンボールZ 超悟空伝 覚醒編",
  "maker": "バンダイ",
  "releaseDate": "1995/9/22",
  "price": "10800",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "1112",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン ANOTHER STORY",
  "maker": "エンジェル",
  "releaseDate": "1995/9/22",
  "price": "11800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1113",
  "ini": "う",
  "name": "ウィザードリィⅥ 禁断の魔筆",
  "maker": "アスキー",
  "releaseDate": "1995/9/29",
  "price": "12800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1114",
  "ini": "う",
  "name": "ウェディングピーチ",
  "maker": "KSS",
  "releaseDate": "1995/9/29",
  "price": "9800",
  "genre": "ミニゲーム",
  "icNo": "32"
 },
 {
  "id": "1115",
  "ini": "う",
  "name": "ヴェルヌワールド",
  "maker": "バンプレスト",
  "releaseDate": "1995/9/29",
  "price": "11800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1116",
  "ini": "え",
  "name": "A列車で行こう3 スーパーバージョン",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/9/29",
  "price": "10800",
  "genre": "開発・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1117",
  "ini": "え",
  "name": "NBA実況バスケット ウイニングダンク",
  "maker": "コナミ",
  "releaseDate": "1995/9/29",
  "price": "10800",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "1118",
  "ini": "く",
  "name": "美食戦隊 薔薇野郎",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1995/9/29",
  "price": "9800",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "1119",
  "ini": "さ",
  "name": "サージェントサンダース コンバット",
  "maker": "アスキー",
  "releaseDate": "1995/9/29",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "1120",
  "ini": "し",
  "name": "常勝麻雀 天牌",
  "maker": "エニックス",
  "releaseDate": "1995/9/29",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "100"
 },
 {
  "id": "1121",
  "ini": "せ",
  "name": "全日本GT選手権",
  "maker": "KANEKO",
  "releaseDate": "1995/9/29",
  "price": "9980",
  "genre": "レース",
  "icNo": "10"
 },
 {
  "id": "1122",
  "ini": "た",
  "name": "ダービージョッキー2",
  "maker": "アスミック",
  "releaseDate": "1995/9/29",
  "price": "9800",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1123",
  "ini": "の",
  "name": "ノーマーク爆牌党 史上最強の雀士達",
  "maker": "エンジェル",
  "releaseDate": "1995/9/29",
  "price": "10800",
  "genre": "テーブル",
  "icNo": "42"
 },
 {
  "id": "1124",
  "ini": "は",
  "name": "ハーメルンのバイオリン弾き",
  "maker": "エニックス",
  "releaseDate": "1995/9/29",
  "price": "9600",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "1125",
  "ini": "へ",
  "name": "平安風雲伝",
  "maker": "KSS",
  "releaseDate": "1995/9/29",
  "price": "11800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1126",
  "ini": "へ",
  "name": "HEIWA パチンコワールド2",
  "maker": "ショウエイシステム",
  "releaseDate": "1995/9/29",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "64"
 },
 {
  "id": "1127",
  "ini": "ほ",
  "name": "ホーリーアンブレラ ドンデラの無謀!!",
  "maker": "ナグザット",
  "releaseDate": "1995/9/29",
  "price": "9800",
  "genre": "アクション・アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "1128",
  "ini": "ま",
  "name": "魔法騎士レイアース",
  "maker": "トミー",
  "releaseDate": "1995/9/29",
  "price": "9800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1129",
  "ini": "め",
  "name": "メタルマックス リターンズ",
  "maker": "データイースト",
  "releaseDate": "1995/9/29",
  "price": "12800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1130",
  "ini": "ひ",
  "name": "火の皇子 ヤマトタケル",
  "maker": "東宝",
  "releaseDate": "1995/9/29",
  "price": "10800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1131",
  "ini": "せ",
  "name": "聖剣伝説3",
  "maker": "スクウェア",
  "releaseDate": "1995/9/30",
  "price": "11400",
  "genre": "アクション・RPG",
  "icNo": "44"
 },
 {
  "id": "1132",
  "ini": "け",
  "name": "激闘バーニング・プロレス",
  "maker": "BPS",
  "releaseDate": "1995/10/6",
  "price": "10800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1133",
  "ini": "し",
  "name": "神聖紀オデッセリアⅡ",
  "maker": "ビック東海",
  "releaseDate": "1995/10/6",
  "price": "10800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1134",
  "ini": "す",
  "name": "すぱぽ～ん",
  "maker": "ユタカ",
  "releaseDate": "1995/10/6",
  "price": "5800",
  "genre": "ブロック崩し",
  "icNo": "56"
 },
 {
  "id": "1135",
  "ini": "た",
  "name": "タクティクスオウガ",
  "maker": "クエスト",
  "releaseDate": "1995/10/6",
  "price": "11400",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1136",
  "ini": "け",
  "name": "ゲームの鉄人 THE上海",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/10/13",
  "price": "9980",
  "genre": "パズル",
  "icNo": "43"
 },
 {
  "id": "1137",
  "ini": "は",
  "name": "ハイパーイリア",
  "maker": "バンプレスト",
  "releaseDate": "1995/10/13",
  "price": "9600",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "1138",
  "ini": "す",
  "name": "SUPER花札弐",
  "maker": "アイマックス",
  "releaseDate": "1995/10/20",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "1139",
  "ini": "そ",
  "name": "総合格闘技RINGS アストラルバウト3",
  "maker": "キングレコード",
  "releaseDate": "1995/10/20",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1140",
  "ini": "て",
  "name": "天地創造",
  "maker": "エニックス",
  "releaseDate": "1995/10/20",
  "price": "11800",
  "genre": "アクション・RPG",
  "icNo": "52"
 },
 {
  "id": "1141",
  "ini": "ま",
  "name": "マジカルドロップ",
  "maker": "データイースト",
  "releaseDate": "1995/10/20",
  "price": "8500",
  "genre": "パズル",
  "icNo": "56"
 },
 {
  "id": "1142",
  "ini": "う",
  "name": "ウルトラベースボール実名版3",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/10/27",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "1143",
  "ini": "え",
  "name": "SD F-1グランプリ",
  "maker": "ビデオシステム",
  "releaseDate": "1995/10/27",
  "price": "10900",
  "genre": "レース・3D",
  "icNo": "22"
 },
 {
  "id": "1144",
  "ini": "か",
  "name": "ガンガンガンチャン",
  "maker": "マジファクト",
  "releaseDate": "1995/10/27",
  "price": "8800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "1145",
  "ini": "く",
  "name": "クリスタルビーンズ フロム ダンジョンエクスプローラー",
  "maker": "ハドソン",
  "releaseDate": "1995/10/27",
  "price": "9500",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1146",
  "ini": "し",
  "name": "ジャスティスリーグ",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "11800",
  "genre": "対戦格闘",
  "icNo": "10"
 },
 {
  "id": "1147",
  "ini": "し",
  "name": "ジャッジ・ドレッド",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "10900",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "1148",
  "ini": "し",
  "name": "白いリングへ Twinkle Little Star",
  "maker": "ポニーキャニオン",
  "releaseDate": "1995/10/27",
  "price": "9980",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1149",
  "ini": "て",
  "name": "From TV animation SLAM DUNK SDヒートアップ!!",
  "maker": "バンダイ",
  "releaseDate": "1995/10/27",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "32"
 },
 {
  "id": "1150",
  "ini": "て",
  "name": "天地無用！げ～む編",
  "maker": "バンプレスト",
  "releaseDate": "1995/10/27",
  "price": "10800",
  "genre": "シミュレーション・RPG",
  "icNo": "20"
 },
 {
  "id": "1151",
  "ini": "は",
  "name": "パチスロ物語 パル工業スペシャル",
  "maker": "KSS",
  "releaseDate": "1995/10/27",
  "price": "10800",
  "genre": "アドベンチャー・スロット",
  "icNo": "1"
 },
 {
  "id": "1152",
  "ini": "は",
  "name": "バットマン フォーエヴァー",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "11800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1153",
  "ini": "は",
  "name": "パネルでポン",
  "maker": "任天堂",
  "releaseDate": "1995/10/27",
  "price": "5800",
  "genre": "パズル",
  "icNo": "42"
 },
 {
  "id": "1154",
  "ini": "ふ",
  "name": "フォアマン フォーリアル",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/10/27",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1155",
  "ini": "ま",
  "name": "麻雀飛翔伝 真 哭きの竜",
  "maker": "ベック",
  "releaseDate": "1995/10/27",
  "price": "8900",
  "genre": "テーブル",
  "icNo": "52"
 },
 {
  "id": "1156",
  "ini": "ま",
  "name": "魔天伝説 戦慄のオーパーツ",
  "maker": "タカラ",
  "releaseDate": "1995/10/27",
  "price": "10800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1157",
  "ini": "ら",
  "name": "ライトファンタジーⅡ",
  "maker": "トンキンハウス",
  "releaseDate": "1995/10/27",
  "price": "9900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1158",
  "ini": "こ",
  "name": "紺碧の艦隊",
  "maker": "エンジェル",
  "releaseDate": "1995/11/2",
  "price": "10800",
  "genre": "シミュレーション",
  "icNo": "54"
 },
 {
  "id": "1159",
  "ini": "ひ",
  "name": "必殺パチンココレクション3",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/11/2",
  "price": "9980",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "1160",
  "ini": "せ",
  "name": "全国縦断ウルトラ心理ゲーム",
  "maker": "ヴィジット",
  "releaseDate": "1995/11/10",
  "price": "9800",
  "genre": "その他",
  "icNo": "56"
 },
 {
  "id": "1161",
  "ini": "ろ",
  "name": "ロマンシング サ・ガ3",
  "maker": "スクウェア",
  "releaseDate": "1995/11/11",
  "price": "11400",
  "genre": "RPG",
  "icNo": "22"
 },
 {
  "id": "1162",
  "ini": "き",
  "name": "キャプテン翼J THE WAY TO WORLD YOUTH",
  "maker": "バンダイ",
  "releaseDate": "1995/11/17",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "1163",
  "ini": "し",
  "name": "上海 万里の長城",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/11/17",
  "price": "9800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1164",
  "ini": "し",
  "name": "新スタートレック 大いなる遺産 IFDの謎を追え",
  "maker": "徳間書店",
  "releaseDate": "1995/11/17",
  "price": "9800",
  "genre": "アクション・アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "1165",
  "ini": "せ",
  "name": "全国高校サッカー2",
  "maker": "四次元",
  "releaseDate": "1995/11/17",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "55"
 },
 {
  "id": "1166",
  "ini": "ふ",
  "name": "ブロックくずし",
  "maker": "POW",
  "releaseDate": "1995/11/17",
  "price": "5980",
  "genre": "ブロック崩し",
  "icNo": "43"
 },
 {
  "id": "1167",
  "ini": "れ",
  "name": "レンダリング・レンジャーR2",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1995/11/17",
  "price": "10800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "1168",
  "ini": "ろ",
  "name": "ロゴスパニック ごあいさつ",
  "maker": "ユタカ",
  "releaseDate": "1995/11/17",
  "price": "9800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1169",
  "ini": "す",
  "name": "スーパードンキーコング2 ディクシー&ディディー",
  "maker": "任天堂",
  "releaseDate": "1995/11/21",
  "price": "9800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "1170",
  "ini": "き",
  "name": "鬼神童子ZENKI 電影雷舞",
  "maker": "ハドソン",
  "releaseDate": "1995/11/24",
  "price": "9980",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "1171",
  "ini": "す",
  "name": "SUPER億万長者ゲーム",
  "maker": "タカラ",
  "releaseDate": "1995/11/24",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1172",
  "ini": "ま",
  "name": "MIGHTY MORPHIN POWER RANGERS",
  "maker": "バンダイ",
  "releaseDate": "1995/11/24",
  "price": "9800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1173",
  "ini": "あ",
  "name": "赤川次郎 魔女たちの眠り",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/11/24",
  "price": "10800",
  "genre": "サウンドノベル",
  "icNo": "50"
 },
 {
  "id": "1174",
  "ini": "り",
  "name": "リーディングジョッキー2",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1995/11/24",
  "price": "9800",
  "genre": "育成・シミュレーション・レース",
  "icNo": "1"
 },
 {
  "id": "1175",
  "ini": "せ",
  "name": "ゼロヨンチャンプ ダブルアール・ヅィー",
  "maker": "メディアリング",
  "releaseDate": "1995/11/25",
  "price": "11900",
  "genre": "レース・ミニゲーム",
  "icNo": "10"
 },
 {
  "id": "1176",
  "ini": "お",
  "name": "おーちゃんのお絵かきロジック",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/12/1",
  "price": "6980",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1177",
  "ini": "ち",
  "name": "ちびまる子ちゃん めざせ！南のアイランド!!",
  "maker": "コナミ",
  "releaseDate": "1995/12/1",
  "price": "9000",
  "genre": "ミニゲーム",
  "icNo": "22"
 },
 {
  "id": "1178",
  "ini": "と",
  "name": "ドカポン外伝 炎のオーディション",
  "maker": "アスミック",
  "releaseDate": "1995/12/1",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "1179",
  "ini": "は",
  "name": "パチ夫くんSPECIAL3",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1995/12/1",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "32"
 },
 {
  "id": "1180",
  "ini": "ひ",
  "name": "ビッグ・ハート",
  "maker": "アクレイムジャパン",
  "releaseDate": "1995/12/1",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1181",
  "ini": "ふ",
  "name": "不思議のダンジョン2 風来のシレン",
  "maker": "チュンソフト",
  "releaseDate": "1995/12/1",
  "price": "11800",
  "genre": "ローグライク・RPG",
  "icNo": "0"
 },
 {
  "id": "1182",
  "ini": "ほ",
  "name": "ボール・ブレット・ガン",
  "maker": "アイマックス",
  "releaseDate": "1995/12/1",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "1183",
  "ini": "ろ",
  "name": "ロックマンX3",
  "maker": "カプコン",
  "releaseDate": "1995/12/1",
  "price": "9800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "1184",
  "ini": "あ",
  "name": "アメリカン バトルドーム",
  "maker": "ツクダオリジナル",
  "releaseDate": "1995/12/8",
  "price": "9800",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "1185",
  "ini": "あ",
  "name": "アンジェリーク プレミアムBOX",
  "maker": "光栄",
  "releaseDate": "1995/12/8",
  "price": "9800",
  "genre": "恋愛・シミュレーション",
  "icNo": "43"
 },
 {
  "id": "1186",
  "ini": "く",
  "name": "クロックワークス",
  "maker": "徳間書店",
  "releaseDate": "1995/12/8",
  "price": "7800",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "1187",
  "ini": "す",
  "name": "す～ぱ～ぷよぷよ通",
  "maker": "コンパイル",
  "releaseDate": "1995/12/8",
  "price": "8800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1188",
  "ini": "す",
  "name": "スーパー桃太郎電鉄DX",
  "maker": "ハドソン",
  "releaseDate": "1995/12/8",
  "price": "9500",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "1189",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンSuperS ふわふわパニック",
  "maker": "バンダイ",
  "releaseDate": "1995/12/8",
  "price": "7980",
  "genre": "パズル",
  "icNo": "12"
 },
 {
  "id": "1190",
  "ini": "ま",
  "name": "MASTERS New 遙かなるオーガスタ3",
  "maker": "T&Eソフト",
  "releaseDate": "1995/12/8",
  "price": "11800",
  "genre": "スポーツ・3D",
  "icNo": "33"
 },
 {
  "id": "1191",
  "ini": "み",
  "name": "ミッキーとドナルド マジカルアドベンチャー3",
  "maker": "カプコン",
  "releaseDate": "1995/12/8",
  "price": "9800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1192",
  "ini": "と",
  "name": "ドラゴンクエストⅥ 幻の大地",
  "maker": "エニックス",
  "releaseDate": "1995/12/9",
  "price": "11400",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1193",
  "ini": "え",
  "name": "EMIT バリューセット",
  "maker": "光栄",
  "releaseDate": "1995/12/15",
  "price": "19800",
  "genre": "英語・学習",
  "icNo": "51"
 },
 {
  "id": "1194",
  "ini": "こ",
  "name": "GO GO ACKMAN3",
  "maker": "バンプレスト",
  "releaseDate": "1995/12/15",
  "price": "9800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "1195",
  "ini": "し",
  "name": "JB ザ・スーパーバス",
  "maker": "NGP",
  "releaseDate": "1995/12/15",
  "price": "11800",
  "genre": "釣り",
  "icNo": "56"
 },
 {
  "id": "1196",
  "ini": "し",
  "name": "実況おしゃべりパロディウス",
  "maker": "コナミ",
  "releaseDate": "1995/12/15",
  "price": "9980",
  "genre": "シューティング",
  "icNo": "53"
 },
 {
  "id": "1197",
  "ini": "し",
  "name": "商人よ、大志を抱け!!",
  "maker": "バンダイ",
  "releaseDate": "1995/12/15",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "20"
 },
 {
  "id": "1198",
  "ini": "す",
  "name": "スーパーブラックバス3",
  "maker": "スターフィッシュデータ",
  "releaseDate": "1995/12/15",
  "price": "11800",
  "genre": "釣り",
  "icNo": "0"
 },
 {
  "id": "1199",
  "ini": "せ",
  "name": "聖獣魔伝ビースト&ブレイド",
  "maker": "BPS",
  "releaseDate": "1995/12/15",
  "price": "11000",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1200",
  "ini": "て",
  "name": "テイルズ オブ ファンタジア",
  "maker": "ナムコ",
  "releaseDate": "1995/12/15",
  "price": "11800",
  "genre": "RPG・アクション",
  "icNo": "55"
 },
 {
  "id": "1201",
  "ini": "て",
  "name": "テーマパーク",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1995/12/15",
  "price": "11800",
  "genre": "経営・シミュレーション",
  "icNo": "65"
 },
 {
  "id": "1202",
  "ini": "と",
  "name": "ドラえもん4 のび太と月の王国",
  "maker": "エポック社",
  "releaseDate": "1995/12/15",
  "price": "9500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1203",
  "ini": "に",
  "name": "ニチブツアーケードクラシックス2 平安京エイリアン",
  "maker": "日本物産",
  "releaseDate": "1995/12/15",
  "price": "5980",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "1204",
  "ini": "ふ",
  "name": "プリンセスメーカー　Legend of Another World",
  "maker": "タカラ",
  "releaseDate": "1995/12/15",
  "price": "9980",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1205",
  "ini": "ほ",
  "name": "本家SANKYO FEVER 実機シミュレーション2",
  "maker": "BOSSコミュニケーションズ",
  "releaseDate": "1995/12/15",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "44"
 },
 {
  "id": "1206",
  "ini": "も",
  "name": "もってけ Oh！ドロボー",
  "maker": "データイースト",
  "releaseDate": "1995/12/15",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "1207",
  "ini": "み",
  "name": "水木しげるの妖怪百鬼夜行",
  "maker": "KSS",
  "releaseDate": "1995/12/20",
  "price": "11800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "1208",
  "ini": "え",
  "name": "SDガンダムGNEXT",
  "maker": "バンダイ",
  "releaseDate": "1995/12/22",
  "price": "12800",
  "genre": "シミュレーション・アクション",
  "icNo": "100"
 },
 {
  "id": "1209",
  "ini": "か",
  "name": "がんばれゴエモン きらきら道中 僕がダンサーになった理由",
  "maker": "コナミ",
  "releaseDate": "1995/12/22",
  "price": "9980",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1210",
  "ini": "け",
  "name": "月面のアヌビス",
  "maker": "イマジニア",
  "releaseDate": "1995/12/22",
  "price": "11800",
  "genre": "サウンドノベル",
  "icNo": "1"
 },
 {
  "id": "1211",
  "ini": "こ",
  "name": "黄龍の耳",
  "maker": "バップ",
  "releaseDate": "1995/12/22",
  "price": "9980",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "1212",
  "ini": "さ",
  "name": "ザ・グレイトバトルⅤ",
  "maker": "バンプレスト",
  "releaseDate": "1995/12/22",
  "price": "9800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1213",
  "ini": "さ",
  "name": "ざくろの味",
  "maker": "イマジニア",
  "releaseDate": "1995/12/22",
  "price": "11800",
  "genre": "サウンドノベル",
  "icNo": "0"
 },
 {
  "id": "1214",
  "ini": "さ",
  "name": "3×3EYES 獣魔奉還",
  "maker": "バンプレスト",
  "releaseDate": "1995/12/22",
  "price": "10800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "1215",
  "ini": "し",
  "name": "将棋三昧",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1995/12/22",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "1216",
  "ini": "す",
  "name": "スーパーチャイニーズワールド3 超次元大作戦",
  "maker": "カルチャーブレーン",
  "releaseDate": "1995/12/22",
  "price": "9800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1217",
  "ini": "す",
  "name": "スーパーファイヤープロレスリングX",
  "maker": "ヒューマン",
  "releaseDate": "1995/12/22",
  "price": "11900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1218",
  "ini": "せ",
  "name": "戦国の覇者 天下布武への道",
  "maker": "バンプレスト",
  "releaseDate": "1995/12/22",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1219",
  "ini": "て",
  "name": "テクモスーパーボウルⅢ FINAL EDITION",
  "maker": "テクモ",
  "releaseDate": "1995/12/22",
  "price": "12800",
  "genre": "スポーツ",
  "icNo": "44"
 },
 {
  "id": "1220",
  "ini": "て",
  "name": "天外魔境ZERO",
  "maker": "ハドソン",
  "releaseDate": "1995/12/22",
  "price": "9980",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1221",
  "ini": "は",
  "name": "バトルサブマリン",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1995/12/22",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "50"
 },
 {
  "id": "1222",
  "ini": "ふ",
  "name": "ファーランドストーリー2",
  "maker": "バンプレスト",
  "releaseDate": "1995/12/22",
  "price": "12800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "1223",
  "ini": "ふ",
  "name": "ファイナルファイト タフ",
  "maker": "カプコン",
  "releaseDate": "1995/12/22",
  "price": "9980",
  "genre": "アクション",
  "icNo": "34"
 },
 {
  "id": "1224",
  "ini": "ろ",
  "name": "ロードス島戦記",
  "maker": "角川書店",
  "releaseDate": "1995/12/22",
  "price": "10800",
  "genre": "RPG",
  "icNo": "61"
 },
 {
  "id": "1225",
  "ini": "さ",
  "name": "最強 高田延彦",
  "maker": "ハドソン",
  "releaseDate": "1995/12/27",
  "price": "10900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1226",
  "ini": "さ",
  "name": "三國志英傑伝",
  "maker": "光栄",
  "releaseDate": "1995/12/28",
  "price": "12800",
  "genre": "シミュレーション・RPG",
  "icNo": "10"
 },
 {
  "id": "1227",
  "ini": "い",
  "name": "イースⅤ 失われた砂の都ケフィン",
  "maker": "日本ファルコム",
  "releaseDate": "1995/12/29",
  "price": "12800",
  "genre": "アクション・RPG",
  "icNo": "2"
 },
 {
  "id": "1228",
  "ini": "は",
  "name": "Parlor！パーラー！Ⅳ CR パチンコ6社・CR実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1995/12/29",
  "price": "11800",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "1229",
  "ini": "す",
  "name": "スーパー将棋3 棋太平",
  "maker": "アイマックス",
  "releaseDate": "1995/12/29",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1230",
  "ini": "た",
  "name": "対局囲碁 韋駄天",
  "maker": "BPS",
  "releaseDate": "1995/12/29",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "1231",
  "ini": "た",
  "name": "大爆笑 人生劇場 ずっこけサラリーマン編",
  "maker": "タイトー",
  "releaseDate": "1995/12/29",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "1232",
  "ini": "ま",
  "name": "魔導物語 はなまる大幼稚園児",
  "maker": "徳間書店インターメディア",
  "releaseDate": "1996/1/12",
  "price": "9900",
  "genre": "RPG",
  "icNo": "56"
 },
 {
  "id": "1233",
  "ini": "い",
  "name": "磯釣り 離島編",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1996/1/19",
  "price": "10800",
  "genre": "釣り",
  "icNo": "51"
 },
 {
  "id": "1234",
  "ini": "は",
  "name": "Parlor！パーラー！3 パチンコ5社・実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/1/19",
  "price": "11800",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "1235",
  "ini": "て",
  "name": "的中競馬塾",
  "maker": "バンプレスト",
  "releaseDate": "1996/1/19",
  "price": "9980",
  "genre": "競馬予想・レース",
  "icNo": "56"
 },
 {
  "id": "1236",
  "ini": "い",
  "name": "囲碁倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1996/1/26",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "33"
 },
 {
  "id": "1237",
  "ini": "え",
  "name": "SDガンダム Power Formation Puzzle",
  "maker": "バンダイ",
  "releaseDate": "1996/1/26",
  "price": "8800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "1238",
  "ini": "す",
  "name": "スーパー野球道",
  "maker": "バンプレスト",
  "releaseDate": "1996/1/26",
  "price": "12800",
  "genre": "スポーツ・育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "1239",
  "ini": "の",
  "name": "信長の野望 天翔記",
  "maker": "光栄",
  "releaseDate": "1996/1/26",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "60"
 },
 {
  "id": "1240",
  "ini": "へ",
  "name": "平成 軍人将棋",
  "maker": "カロッツェリアジャパン",
  "releaseDate": "1996/1/26",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1241",
  "ini": "ま",
  "name": "MADARA SAGA 幼稚園戦記まだら",
  "maker": "データム・ポリスター",
  "releaseDate": "1996/1/26",
  "price": "9800",
  "genre": "RPG",
  "icNo": "56"
 },
 {
  "id": "1242",
  "ini": "む",
  "name": "無人島物語",
  "maker": "KSS",
  "releaseDate": "1996/1/26",
  "price": "12800",
  "genre": "探索・シミュレーション",
  "icNo": "33"
 },
 {
  "id": "1243",
  "ini": "あ",
  "name": "RPGツクール2",
  "maker": "アスキー",
  "releaseDate": "1996/1/31",
  "price": "12800",
  "genre": "ゲーム制作",
  "icNo": "100"
 },
 {
  "id": "1244",
  "ini": "は",
  "name": "幕末降臨伝ONI",
  "maker": "バンプレスト",
  "releaseDate": "1996/2/2",
  "price": "12800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1245",
  "ini": "し",
  "name": "将棋最強Ⅱ",
  "maker": "魔法",
  "releaseDate": "1996/2/9",
  "price": "10800",
  "genre": "テーブル",
  "icNo": "21"
 },
 {
  "id": "1246",
  "ini": "と",
  "name": "ときめきメモリアル 伝説の樹の下で",
  "maker": "コナミ",
  "releaseDate": "1996/2/9",
  "price": "9980",
  "genre": "恋愛・シミュレーション",
  "icNo": "12"
 },
 {
  "id": "1247",
  "ini": "は",
  "name": "バハムートラグーン",
  "maker": "スクウェア",
  "releaseDate": "1996/2/9",
  "price": "11400",
  "genre": "シミュレーション・RPG",
  "icNo": "56"
 },
 {
  "id": "1248",
  "ini": "ふ",
  "name": "プロ棋士人生シミュレーション 将棋の花道",
  "maker": "アトラス",
  "releaseDate": "1996/2/16",
  "price": "12800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "1249",
  "ini": "ふ",
  "name": "フロントミッションシリーズ ガンハザード",
  "maker": "スクウェア",
  "releaseDate": "1996/2/23",
  "price": "11400",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1250",
  "ini": "き",
  "name": "鬼神童子ZENKI 天地鳴動",
  "maker": "ハドソン",
  "releaseDate": "1996/2/23",
  "price": "9980",
  "genre": "テーブル",
  "icNo": "44"
 },
 {
  "id": "1251",
  "ini": "は",
  "name": "バトルテック3050",
  "maker": "アスク講談社",
  "releaseDate": "1996/2/23",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "1252",
  "ini": "し",
  "name": "実況パワフルプロ野球3",
  "maker": "コナミ",
  "releaseDate": "1996/2/29",
  "price": "7500",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "1253",
  "ini": "す",
  "name": "スーパーファミスタ5",
  "maker": "ナムコ",
  "releaseDate": "1996/2/29",
  "price": "6980",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1254",
  "ini": "え",
  "name": "NFL クォーターバッククラブ'96",
  "maker": "アクレイムジャパン",
  "releaseDate": "1996/3/1",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1255",
  "ini": "き",
  "name": "機動戦士Zガンダム AWAY TO THE NEWTYPE",
  "maker": "バンダイ",
  "releaseDate": "1996/3/1",
  "price": "11800",
  "genre": "シミュレーション・シューティング・3D",
  "icNo": "0"
 },
 {
  "id": "1256",
  "ini": "さ",
  "name": "鮫亀",
  "maker": "ハドソン",
  "releaseDate": "1996/3/1",
  "price": "8980",
  "genre": "パズル",
  "icNo": "100"
 },
 {
  "id": "1257",
  "ini": "つ",
  "name": "晦 つきこもり",
  "maker": "バンプレスト",
  "releaseDate": "1996/3/1",
  "price": "7800",
  "genre": "サウンドノベル",
  "icNo": "0"
 },
 {
  "id": "1258",
  "ini": "と",
  "name": "DOOM",
  "maker": "イマジニア",
  "releaseDate": "1996/3/1",
  "price": "12800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "1259",
  "ini": "れ",
  "name": "レッスルマニア ジ・アーケードゲーム",
  "maker": "アクレイムジャパン",
  "releaseDate": "1996/3/1",
  "price": "11800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1260",
  "ini": "れ",
  "name": "レボリューションX",
  "maker": "アクレイムジャパン",
  "releaseDate": "1996/3/1",
  "price": "11800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "1261",
  "ini": "き",
  "name": "銀河戦国群雄伝ライ",
  "maker": "エンジェル",
  "releaseDate": "1996/3/8",
  "price": "10800",
  "genre": "シミュレーション",
  "icNo": "44"
 },
 {
  "id": "1262",
  "ini": "し",
  "name": "実戦パチンコ必勝法！2",
  "maker": "サミー",
  "releaseDate": "1996/3/8",
  "price": "7800",
  "genre": "パチンコ",
  "icNo": "43"
 },
 {
  "id": "1263",
  "ini": "す",
  "name": "す～ぱ～ぷよぷよ通 リミックス",
  "maker": "コンパイル",
  "releaseDate": "1996/3/8",
  "price": "6800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1264",
  "ini": "す",
  "name": "スーパーマリオRPG",
  "maker": "任天堂",
  "releaseDate": "1996/3/9",
  "price": "7500",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1265",
  "ini": "か",
  "name": "カオスシード 風水回廊記",
  "maker": "タイトー",
  "releaseDate": "1996/3/15",
  "price": "9980",
  "genre": "アクション・RPG",
  "icNo": "55"
 },
 {
  "id": "1266",
  "ini": "た",
  "name": "ダービースタリオン96",
  "maker": "アスキー",
  "releaseDate": "1996/3/15",
  "price": "12800",
  "genre": "育成・シミュレーション",
  "icNo": "100"
 },
 {
  "id": "1267",
  "ini": "と",
  "name": "峠・伝説 最速バトル",
  "maker": "BPS",
  "releaseDate": "1996/3/15",
  "price": "10800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "1268",
  "ini": "ふ",
  "name": "ブランディッシュ2 エキスパート",
  "maker": "光栄",
  "releaseDate": "1996/3/15",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1269",
  "ini": "か",
  "name": "星のカービィ スーパーデラックス",
  "maker": "任天堂",
  "releaseDate": "1996/3/21",
  "price": "7500",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "1270",
  "ini": "い",
  "name": "イースⅤ エキスパート",
  "maker": "光栄",
  "releaseDate": "1996/3/22",
  "price": "11800",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "1271",
  "ini": "き",
  "name": "ギャンブル放浪記",
  "maker": "バップ",
  "releaseDate": "1996/3/22",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1272",
  "ini": "き",
  "name": "96全国高校サッカー選手権",
  "maker": "魔法",
  "releaseDate": "1996/3/22",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "51"
 },
 {
  "id": "1273",
  "ini": "す",
  "name": "ステイブルスター 厩舎物語",
  "maker": "コナミ",
  "releaseDate": "1996/3/22",
  "price": "11800",
  "genre": "育成・シミュレーション",
  "icNo": "56"
 },
 {
  "id": "1274",
  "ini": "す",
  "name": "スーパーロボット大戦外伝 魔装機神 THE LOAD OF ELEMENTAL",
  "maker": "バンプレスト",
  "releaseDate": "1996/3/22",
  "price": "7800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1275",
  "ini": "と",
  "name": "ドレミファンタジー ミロンのドキドキ大冒険",
  "maker": "ハドソン",
  "releaseDate": "1996/3/22",
  "price": "6800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1276",
  "ini": "に",
  "name": "NEWヤッターマン 難題かんだいヤジロベエ",
  "maker": "ユタカ",
  "releaseDate": "1996/3/22",
  "price": "8800",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "1277",
  "ini": "は",
  "name": "林海峯九段の囲碁大道",
  "maker": "アスク講談社",
  "releaseDate": "1996/3/22",
  "price": "14800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1278",
  "ini": "あ",
  "name": "アンジェリーク ヴォイス・ファンタジー",
  "maker": "光栄",
  "releaseDate": "1996/3/29",
  "price": "9800",
  "genre": "恋愛・シミュレーション",
  "icNo": "55"
 },
 {
  "id": "1279",
  "ini": "え",
  "name": "SDガンダム GNEXT専用ロムパック ユニット&マップコレクション",
  "maker": "バンダイ",
  "releaseDate": "1996/3/29",
  "price": "3800",
  "genre": "その他",
  "icNo": "99"
 },
 {
  "id": "1280",
  "ini": "は",
  "name": "Parlor！パーラー！5 パチンコ3社・実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/3/29",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "40"
 },
 {
  "id": "1281",
  "ini": "し",
  "name": "GTレーシング",
  "maker": "イマジニア",
  "releaseDate": "1996/3/29",
  "price": "10800",
  "genre": "レース・3D",
  "icNo": "44"
 },
 {
  "id": "1282",
  "ini": "し",
  "name": "新機動戦記ガンダムW エンドレス デュエル",
  "maker": "バンダイ",
  "releaseDate": "1996/3/29",
  "price": "7500",
  "genre": "対戦格闘",
  "icNo": "30"
 },
 {
  "id": "1283",
  "ini": "す",
  "name": "スーパーファイヤープロレスリングX プレミアム",
  "maker": "ヒューマン",
  "releaseDate": "1996/3/29",
  "price": "8000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1284",
  "ini": "す",
  "name": "スーパーフォーメーションサッカー96 ワールドクラブエディション",
  "maker": "ヒューマン",
  "releaseDate": "1996/3/29",
  "price": "7500",
  "genre": "スポーツ",
  "icNo": "60"
 },
 {
  "id": "1285",
  "ini": "そ",
  "name": "それいけエビス丸 からくり迷路 消えたゴエモンの謎!!",
  "maker": "コナミ",
  "releaseDate": "1996/3/29",
  "price": "5800",
  "genre": "パズル",
  "icNo": "22"
 },
 {
  "id": "1286",
  "ini": "と",
  "name": "ドラゴンボールZ HYPER DIMENSION",
  "maker": "バンダイ",
  "releaseDate": "1996/3/29",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "1287",
  "ini": "に",
  "name": "忍たま乱太郎2",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/3/29",
  "price": "7800",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "1288",
  "ini": "ひ",
  "name": "美少女戦士セーラームーンSuperS 全員参加!! 主役争奪戦",
  "maker": "エンジェル",
  "releaseDate": "1996/3/29",
  "price": "7980",
  "genre": "対戦格闘",
  "icNo": "40"
 },
 {
  "id": "1289",
  "ini": "ひ",
  "name": "美少女レスラー列伝 ブリザードYuki乱入!!",
  "maker": "KSS",
  "releaseDate": "1996/3/29",
  "price": "8000",
  "genre": "育成・シミュレーション",
  "icNo": "31"
 },
 {
  "id": "1290",
  "ini": "し",
  "name": "実戦パチスロ必勝法！山佐伝説",
  "maker": "サミー",
  "releaseDate": "1996/4/5",
  "price": "6900",
  "genre": "スロット",
  "icNo": "0"
 },
 {
  "id": "1291",
  "ini": "る",
  "name": "ルドラの秘宝",
  "maker": "スクウェア",
  "releaseDate": "1996/4/5",
  "price": "8000",
  "genre": "RPG",
  "icNo": "42"
 },
 {
  "id": "1292",
  "ini": "お",
  "name": "音楽ツクール かなでーる",
  "maker": "アスキー",
  "releaseDate": "1996/4/12",
  "price": "9980",
  "genre": "その他",
  "icNo": "100"
 },
 {
  "id": "1293",
  "ini": "ま",
  "name": "魔法陣グルグル2",
  "maker": "エニックス",
  "releaseDate": "1996/4/12",
  "price": "8000",
  "genre": "アクション・RPG",
  "icNo": "22"
 },
 {
  "id": "1294",
  "ini": "ほ",
  "name": "蓬莱学園の冒険！",
  "maker": "J・ウイング",
  "releaseDate": "1996/4/19",
  "price": "7980",
  "genre": "RPG",
  "icNo": "42"
 },
 {
  "id": "1295",
  "ini": "い",
  "name": "一発逆転 競馬 競輪 競艇",
  "maker": "POW",
  "releaseDate": "1996/4/26",
  "price": "9800",
  "genre": "レース・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1296",
  "ini": "し",
  "name": "Jリーグエキサイトステージ'96",
  "maker": "エポック社",
  "releaseDate": "1996/4/26",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "1297",
  "ini": "し",
  "name": "ジャンピンダービー",
  "maker": "ナグザット",
  "releaseDate": "1996/4/26",
  "price": "9800",
  "genre": "レース・育成・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "1298",
  "ini": "す",
  "name": "スーパー競艇2",
  "maker": "日本物産",
  "releaseDate": "1996/4/26",
  "price": "8500",
  "genre": "レース・シミュレーション",
  "icNo": "54"
 },
 {
  "id": "1299",
  "ini": "す",
  "name": "スーパーボンバーマン4",
  "maker": "ハドソン",
  "releaseDate": "1996/4/26",
  "price": "7777",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1300",
  "ini": "と",
  "name": "トイ・ストーリー",
  "maker": "カプコン",
  "releaseDate": "1996/4/26",
  "price": "7500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "1301",
  "ini": "は",
  "name": "Parlor！Mini パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/4/26",
  "price": "4900",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1302",
  "ini": "へ",
  "name": "HEIWA パチンコワールド3",
  "maker": "ショウエイシステム",
  "releaseDate": "1996/4/26",
  "price": "7800",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "1303",
  "ini": "ふ",
  "name": "ファイアーエムブレム 聖戦の系譜",
  "maker": "任天堂",
  "releaseDate": "1996/5/14",
  "price": "7500",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1304",
  "ini": "こ",
  "name": "ごきんじょ冒険隊",
  "maker": "パイオニアLDC",
  "releaseDate": "1996/5/24",
  "price": "7980",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1305",
  "ini": "す",
  "name": "すーぱーぐっすんおよよ2",
  "maker": "バンプレスト",
  "releaseDate": "1996/5/24",
  "price": "7800",
  "genre": "アクション・パズル",
  "icNo": "64"
 },
 {
  "id": "1306",
  "ini": "と",
  "name": "トレジャーハンターG",
  "maker": "スクウェア",
  "releaseDate": "1996/5/24",
  "price": "7900",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "1307",
  "ini": "さ",
  "name": "サウンドノベルツクール",
  "maker": "アスキー",
  "releaseDate": "1996/5/31",
  "price": "8200",
  "genre": "ゲーム制作",
  "icNo": "100"
 },
 {
  "id": "1308",
  "ini": "す",
  "name": "すぱぽーんDX",
  "maker": "ユタカ",
  "releaseDate": "1996/5/31",
  "price": "5800",
  "genre": "ブロック崩し",
  "icNo": "55"
 },
 {
  "id": "1309",
  "ini": "た",
  "name": "ダークハーフ",
  "maker": "エニックス",
  "releaseDate": "1996/5/31",
  "price": "8000",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "1310",
  "ini": "ふ",
  "name": "フィッシング甲子園",
  "maker": "キングレコード",
  "releaseDate": "1996/5/31",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "1311",
  "ini": "し",
  "name": "Jリーグ'96 ドリームスタジアム",
  "maker": "ハドソン",
  "releaseDate": "1996/6/1",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1312",
  "ini": "あ",
  "name": "アラビアンナイト 砂漠の精霊王",
  "maker": "タカラ",
  "releaseDate": "1996/6/14",
  "price": "7800",
  "genre": "RPG",
  "icNo": "32"
 },
 {
  "id": "1313",
  "ini": "へ",
  "name": "ベストショットプロゴルフ",
  "maker": "アスキー",
  "releaseDate": "1996/6/14",
  "price": "8200",
  "genre": "育成・シミュレーション",
  "icNo": "61"
 },
 {
  "id": "1314",
  "ini": "く",
  "name": "空想科学世界ガリバーボーイ",
  "maker": "バンダイ",
  "releaseDate": "1996/6/28",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "20"
 },
 {
  "id": "1315",
  "ini": "す",
  "name": "す～ぱ～なぞぷよ通 ルルーの鉄腕繁盛記",
  "maker": "コンパイル",
  "releaseDate": "1996/6/28",
  "price": "9800",
  "genre": "パズル",
  "icNo": "41"
 },
 {
  "id": "1316",
  "ini": "す",
  "name": "スーファミターボ専用 SDウルトラバトル ウルトラマン伝説",
  "maker": "バンダイ",
  "releaseDate": "1996/6/28",
  "price": "3980",
  "genre": "対戦格闘",
  "icNo": "98"
 },
 {
  "id": "1317",
  "ini": "す",
  "name": "スーファミターボ専用 SDウルトラバトル セブン伝説",
  "maker": "バンダイ",
  "releaseDate": "1996/6/28",
  "price": "3980",
  "genre": "対戦格闘",
  "icNo": "98"
 },
 {
  "id": "1318",
  "ini": "す",
  "name": "スーファミターボ専用 ぽいぽい忍者ワールド",
  "maker": "バンダイ",
  "releaseDate": "1996/6/28",
  "price": "3980",
  "genre": "アクション",
  "icNo": "98"
 },
 {
  "id": "1319",
  "ini": "と",
  "name": "トラバース",
  "maker": "バンプレスト",
  "releaseDate": "1996/6/28",
  "price": "7800",
  "genre": "RPG",
  "icNo": "44"
 },
 {
  "id": "1320",
  "ini": "に",
  "name": "西陣パチンコ物語2",
  "maker": "KSS",
  "releaseDate": "1996/6/28",
  "price": "10800",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1321",
  "ini": "に",
  "name": "忍たま乱太郎 忍術学園パズル大会の段",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/6/28",
  "price": "6980",
  "genre": "パズル",
  "icNo": "22"
 },
 {
  "id": "1322",
  "ini": "は",
  "name": "Parlor！Mini2 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/6/28",
  "price": "4900",
  "genre": "パチスロ",
  "icNo": "60"
 },
 {
  "id": "1323",
  "ini": "う",
  "name": "海のぬし釣り",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1996/7/19",
  "price": "7800",
  "genre": "釣り・RPG",
  "icNo": "0"
 },
 {
  "id": "1324",
  "ini": "し",
  "name": "実況パワフルプロ野球 '96開幕版",
  "maker": "コナミ",
  "releaseDate": "1996/7/19",
  "price": "7500",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "1325",
  "ini": "す",
  "name": "スーパートランプコレクション2",
  "maker": "ボトムアップ",
  "releaseDate": "1996/7/19",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1326",
  "ini": "す",
  "name": "スーファミターボ専用 ゲゲゲの鬼太郎 妖怪ドンジャラ",
  "maker": "バンダイ",
  "releaseDate": "1996/7/19",
  "price": "3980",
  "genre": "テーブル",
  "icNo": "98"
 },
 {
  "id": "1327",
  "ini": "す",
  "name": "スターオーシャン",
  "maker": "エニックス",
  "releaseDate": "1996/7/19",
  "price": "8500",
  "genre": "RPG・アクション",
  "icNo": "50"
 },
 {
  "id": "1328",
  "ini": "あ",
  "name": "アースライト ルナ・ストライク",
  "maker": "ハドソン",
  "releaseDate": "1996/7/26",
  "price": "7980",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "1329",
  "ini": "え",
  "name": "エナジーブレイカー",
  "maker": "タイトー",
  "releaseDate": "1996/7/26",
  "price": "7980",
  "genre": "シミュレーション・RPG",
  "icNo": "43"
 },
 {
  "id": "1330",
  "ini": "し",
  "name": "シムシティJr.",
  "maker": "イマジニア",
  "releaseDate": "1996/7/26",
  "price": "8200",
  "genre": "開発・シミュレーション",
  "icNo": "53"
 },
 {
  "id": "1331",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション 一年戦争記",
  "maker": "バンダイ",
  "releaseDate": "1996/7/26",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1332",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション グリプス戦記",
  "maker": "バンダイ",
  "releaseDate": "1996/7/26",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1333",
  "ini": "す",
  "name": "スプリガン・パワード",
  "maker": "ナグザット",
  "releaseDate": "1996/7/26",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "1334",
  "ini": "て",
  "name": "テーブルゲーム大集合!! 将棋・麻雀・花札・トゥーサイド",
  "maker": "バリエ",
  "releaseDate": "1996/7/26",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "20"
 },
 {
  "id": "1335",
  "ini": "れ",
  "name": "レナスⅡ 封印の使徒",
  "maker": "アスミック",
  "releaseDate": "1996/7/26",
  "price": "9980",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1336",
  "ini": "た",
  "name": "大貝獣物語Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1996/8/2",
  "price": "8200",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "1337",
  "ini": "あ",
  "name": "赤ずきんチャチャ",
  "maker": "トミー",
  "releaseDate": "1996/8/9",
  "price": "7800",
  "genre": "RPG",
  "icNo": "12"
 },
 {
  "id": "1338",
  "ini": "す",
  "name": "スーパーパワーリーグ4",
  "maker": "ハドソン",
  "releaseDate": "1996/8/9",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1339",
  "ini": "に",
  "name": "忍たま乱太郎 すぺしゃる",
  "maker": "カルチャーブレーン",
  "releaseDate": "1996/8/9",
  "price": "8800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1340",
  "ini": "ほ",
  "name": "牧場物語",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1996/8/9",
  "price": "7800",
  "genre": "育成・シミュレーション",
  "icNo": "22"
 },
 {
  "id": "1341",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション アクシズ戦記",
  "maker": "バンダイ",
  "releaseDate": "1996/8/23",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1342",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション バビロニア建国戦記",
  "maker": "バンダイ",
  "releaseDate": "1996/8/23",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1343",
  "ini": "す",
  "name": "スーファミターボ専用 激走戦隊カーレンジャー 全開！レーサー戦士",
  "maker": "バンダイ",
  "releaseDate": "1996/8/23",
  "price": "3980",
  "genre": "アクション",
  "icNo": "98"
 },
 {
  "id": "1344",
  "ini": "ふ",
  "name": "古田敦也のシミュレーションプロ野球2",
  "maker": "ヘクト",
  "releaseDate": "1996/8/24",
  "price": "8000",
  "genre": "スポーツ・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1345",
  "ini": "た",
  "name": "大戦略エキスパートWWⅡ",
  "maker": "アスキー",
  "releaseDate": "1996/8/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1346",
  "ini": "な",
  "name": "ナンバーズパラダイス",
  "maker": "アクレイムジャパン",
  "releaseDate": "1996/8/30",
  "price": "8800",
  "genre": "その他",
  "icNo": "32"
 },
 {
  "id": "1347",
  "ini": "ひ",
  "name": "必殺パチンココレクション4",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1996/8/30",
  "price": "9980",
  "genre": "パチンコ",
  "icNo": "0"
 },
 {
  "id": "1348",
  "ini": "ほ",
  "name": "本家SANKYO FEVER 実機シミュレーション3",
  "maker": "BOSSコミュニケーションズ",
  "releaseDate": "1996/8/30",
  "price": "7480",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "1349",
  "ini": "し",
  "name": "実況パワープロレスリング'96 マックスボルテージ",
  "maker": "コナミ",
  "releaseDate": "1996/9/13",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1350",
  "ini": "へ",
  "name": "ペブルビーチの波濤 New TOUNAMENT EDITIOM",
  "maker": "T&Eソフト",
  "releaseDate": "1996/9/13",
  "price": "8000",
  "genre": "スポーツ・3D",
  "icNo": "0"
 },
 {
  "id": "1351",
  "ini": "う",
  "name": "ウィザードリィ・外伝IV 胎魔の鼓動",
  "maker": "アスキー",
  "releaseDate": "1996/9/20",
  "price": "8000",
  "genre": "RPG",
  "icNo": "21"
 },
 {
  "id": "1352",
  "ini": "ま",
  "name": "マジカルドロップ2",
  "maker": "データイースト",
  "releaseDate": "1996/9/20",
  "price": "7800",
  "genre": "パズル",
  "icNo": "32"
 },
 {
  "id": "1353",
  "ini": "す",
  "name": "スーパーニチブツマージャン4 基礎研究編",
  "maker": "日本物産",
  "releaseDate": "1996/9/27",
  "price": "7500",
  "genre": "テーブル",
  "icNo": "55"
 },
 {
  "id": "1354",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション コロニー格闘記",
  "maker": "バンダイ",
  "releaseDate": "1996/9/27",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1355",
  "ini": "す",
  "name": "スーファミターボ専用 SDガンダムジェネレーション ザンスカール戦記",
  "maker": "バンダイ",
  "releaseDate": "1996/9/27",
  "price": "3980",
  "genre": "シミュレーション",
  "icNo": "98"
 },
 {
  "id": "1356",
  "ini": "す",
  "name": "スーファミターボ専用 クレヨンしんちゃん 長ぐつドボン!!",
  "maker": "バンダイ",
  "releaseDate": "1996/9/27",
  "price": "3980",
  "genre": "アクション",
  "icNo": "98"
 },
 {
  "id": "1357",
  "ini": "す",
  "name": "スーファミターボ専用 美少女戦士セーラームーン セーラースターズ ふわふわパニック2",
  "maker": "バンダイ",
  "releaseDate": "1996/9/27",
  "price": "3980",
  "genre": "パズル",
  "icNo": "98"
 },
 {
  "id": "1358",
  "ini": "は",
  "name": "Parlor！Mini3 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/9/27",
  "price": "4900",
  "genre": "パチンコ",
  "icNo": "44"
 },
 {
  "id": "1359",
  "ini": "も",
  "name": "モンスタニア",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1996/9/27",
  "price": "7800",
  "genre": "シミュレーション・RPG",
  "icNo": "0"
 },
 {
  "id": "1360",
  "ini": "う",
  "name": "ウイニングポスト2 プログラム'96",
  "maker": "光栄",
  "releaseDate": "1996/10/4",
  "price": "9800",
  "genre": "育成・シミュレーション",
  "icNo": "54"
 },
 {
  "id": "1361",
  "ini": "さ",
  "name": "サラブレッドブリーダーⅢ",
  "maker": "ヘクト",
  "releaseDate": "1996/10/18",
  "price": "8200",
  "genre": "育成・シミュレーション",
  "icNo": "0"
 },
 {
  "id": "1362",
  "ini": "ま",
  "name": "マーヴル・スーパーヒーローズ ウォー オブ ザ ジェム",
  "maker": "カプコン",
  "releaseDate": "1996/10/18",
  "price": "7800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1363",
  "ini": "た",
  "name": "タワードリーム",
  "maker": "アスキー",
  "releaseDate": "1996/10/25",
  "price": "8000",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1364",
  "ini": "ま",
  "name": "マーヴェラス もうひとつの宝島",
  "maker": "任天堂",
  "releaseDate": "1996/10/26",
  "price": "6800",
  "genre": "アクション・アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "1365",
  "ini": "す",
  "name": "スーパードンキーコング3 謎のクレミス島",
  "maker": "任天堂",
  "releaseDate": "1996/11/23",
  "price": "6800",
  "genre": "アクション",
  "icNo": "34"
 },
 {
  "id": "1366",
  "ini": "す",
  "name": "SUPER人生ゲーム3",
  "maker": "タカラ",
  "releaseDate": "1996/11/29",
  "price": "5980",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1367",
  "ini": "に",
  "name": "ニチブツコレクション1",
  "maker": "日本物産",
  "releaseDate": "1996/11/29",
  "price": "7980",
  "genre": "テーブル・スロット",
  "icNo": "1"
 },
 {
  "id": "1368",
  "ini": "は",
  "name": "Parlor！Mini4 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1996/11/29",
  "price": "5800",
  "genre": "パチンコ",
  "icNo": "30"
 },
 {
  "id": "1369",
  "ini": "ふ",
  "name": "VS.コレクション",
  "maker": "ボトムアップ",
  "releaseDate": "1996/11/29",
  "price": "6980",
  "genre": "アクション・パズル・レース・3D",
  "icNo": "0"
 },
 {
  "id": "1370",
  "ini": "と",
  "name": "ドラゴンクエストⅢ そして伝説へ…",
  "maker": "エニックス",
  "releaseDate": "1996/12/6",
  "price": "8700",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "1371",
  "ini": "も",
  "name": "桃太郎電鉄HAPPY",
  "maker": "ハドソン",
  "releaseDate": "1996/12/6",
  "price": "8300",
  "genre": "テーブル",
  "icNo": "13"
 },
 {
  "id": "1372",
  "ini": "す",
  "name": "すごろく銀河戦記",
  "maker": "ボトムアップ",
  "releaseDate": "1996/12/19",
  "price": "6980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1373",
  "ini": "く",
  "name": "クオンパ",
  "maker": "T&Eソフト",
  "releaseDate": "1996/12/20",
  "price": "6800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1374",
  "ini": "し",
  "name": "G・O・D 目覚めよと呼ぶ声が聴こえ",
  "maker": "イマジニア",
  "releaseDate": "1996/12/20",
  "price": "7980",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1375",
  "ini": "す",
  "name": "ストリートファイターZERO2",
  "maker": "カプコン",
  "releaseDate": "1996/12/20",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "0"
 },
 {
  "id": "1376",
  "ini": "と",
  "name": "ドナルドダックのマウイマラード",
  "maker": "カプコン",
  "releaseDate": "1996/12/20",
  "price": "7500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1377",
  "ini": "に",
  "name": "西陣パチンコ3",
  "maker": "KSS",
  "releaseDate": "1996/12/20",
  "price": "7800",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1378",
  "ini": "ひ",
  "name": "ピノキオ",
  "maker": "カプコン",
  "releaseDate": "1996/12/20",
  "price": "7500",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "1379",
  "ini": "ほ",
  "name": "ボンバーマン ビーダマン",
  "maker": "ハドソン",
  "releaseDate": "1996/12/20",
  "price": "5980",
  "genre": "シューティング・パズル",
  "icNo": "32"
 },
 {
  "id": "1380",
  "ini": "み",
  "name": "ミニ四駆 シャイニングスコーピオン レッツ&ゴー!!",
  "maker": "アスキー",
  "releaseDate": "1996/12/20",
  "price": "8800",
  "genre": "レース・シュミレーション",
  "icNo": "0"
 },
 {
  "id": "1381",
  "ini": "と",
  "name": "ドラゴンナイトⅣ",
  "maker": "バンプレスト",
  "releaseDate": "1996/12/27",
  "price": "7980",
  "genre": "シミュレーション・RPG",
  "icNo": "21"
 },
 {
  "id": "1382",
  "ini": "に",
  "name": "ニチブツコレクション2",
  "maker": "日本物産",
  "releaseDate": "1996/12/27",
  "price": "7980",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "1383",
  "ini": "ま",
  "name": "マスク",
  "maker": "ヴァージンインタラクティブエンターテインメント",
  "releaseDate": "1996/12/27",
  "price": "9800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "1384",
  "ini": "あ",
  "name": "アルカノイド Doh It Again",
  "maker": "タイトー",
  "releaseDate": "1997/1/15",
  "price": "4980",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "1385",
  "ini": "ふ",
  "name": "BUSHI 青龍伝 二人の勇者",
  "maker": "T&Eソフト",
  "releaseDate": "1997/1/17",
  "price": "7980",
  "genre": "アクション・RPG",
  "icNo": "51"
 },
 {
  "id": "1386",
  "ini": "ふ",
  "name": "プロ野球スター",
  "maker": "カルチャーブレーン",
  "releaseDate": "1997/1/17",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1387",
  "ini": "か",
  "name": "ガンプル GUNMAN'S PROOF",
  "maker": "アスキー",
  "releaseDate": "1997/1/31",
  "price": "8000",
  "genre": "アクション・アドベンチャー",
  "icNo": "32"
 },
 {
  "id": "1388",
  "ini": "ひ",
  "name": "ピキーニャ！",
  "maker": "アスキー",
  "releaseDate": "1997/1/31",
  "price": "6800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1389",
  "ini": "み",
  "name": "ミランドラ",
  "maker": "アスキー",
  "releaseDate": "1997/1/31",
  "price": "7800",
  "genre": "ローグライク・RPG",
  "icNo": "0"
 },
 {
  "id": "1390",
  "ini": "い",
  "name": "糸井重里のバス釣りNo.1",
  "maker": "任天堂",
  "releaseDate": "1997/2/21",
  "price": "7800",
  "genre": "釣り",
  "icNo": "100"
 },
 {
  "id": "1391",
  "ini": "す",
  "name": "スーパーボンバーマン5",
  "maker": "ハドソン",
  "releaseDate": "1997/2/28",
  "price": "6980",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1392",
  "ini": "に",
  "name": "忍たま乱太郎3",
  "maker": "カルチャーブレーン",
  "releaseDate": "1997/2/28",
  "price": "6980",
  "genre": "アクション・ミニゲーム",
  "icNo": "56"
 },
 {
  "id": "1393",
  "ini": "あ",
  "name": "アニマニアクス",
  "maker": "コナミ",
  "releaseDate": "1997/3/7",
  "price": "5800",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "1394",
  "ini": "は",
  "name": "パチスロ完全攻略 ユニバーサル新台入荷 volume1",
  "maker": "日本シスコン",
  "releaseDate": "1997/3/7",
  "price": "5980",
  "genre": "スロット",
  "icNo": "62"
 },
 {
  "id": "1395",
  "ini": "き",
  "name": "キャスパー",
  "maker": "KSS",
  "releaseDate": "1997/3/14",
  "price": "4980",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "1396",
  "ini": "す",
  "name": "スーパーダブル役満Ⅱ",
  "maker": "バップ",
  "releaseDate": "1997/3/14",
  "price": "7500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "1397",
  "ini": "し",
  "name": "実戦パチスロ必勝法！TWIN",
  "maker": "サミー",
  "releaseDate": "1997/3/15",
  "price": "5980",
  "genre": "スロット",
  "icNo": "56"
 },
 {
  "id": "1398",
  "ini": "し",
  "name": "実況パワフルプロ野球3 '97春",
  "maker": "コナミ",
  "releaseDate": "1997/3/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "1399",
  "ini": "そ",
  "name": "ソリッドランナー",
  "maker": "アスキー",
  "releaseDate": "1997/3/28",
  "price": "8000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1400",
  "ini": "た",
  "name": "ダークロウ Meaning of Death",
  "maker": "アスキー",
  "releaseDate": "1997/3/28",
  "price": "8000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "1401",
  "ini": "は",
  "name": "Parlor！Mini5 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1997/3/28",
  "price": "4900",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "1402",
  "ini": "ふ",
  "name": "プロ麻雀 兵",
  "maker": "カルチャーブレーン",
  "releaseDate": "1997/4/18",
  "price": "6980",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1403",
  "ini": "ふ",
  "name": "プロ野球 熱闘ぱずるスタジアム",
  "maker": "ココナッツジャパンエンターテイメント",
  "releaseDate": "1997/4/25",
  "price": "7800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1404",
  "ini": "か",
  "name": "加藤一二三九段 将棋倶楽部",
  "maker": "ヘクト",
  "releaseDate": "1997/5/16",
  "price": "4980",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1405",
  "ini": "は",
  "name": "Parlor！Mini6 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1997/5/30",
  "price": "4980",
  "genre": "パチンコ",
  "icNo": "41"
 },
 {
  "id": "1406",
  "ini": "ふ",
  "name": "ふね太郎",
  "maker": "ビクターインタラクティブ（パック・イン・ソフト）",
  "releaseDate": "1997/8/1",
  "price": "7800",
  "genre": "釣り",
  "icNo": "52"
 },
 {
  "id": "1407",
  "ini": "は",
  "name": "Parlor！Mini7 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1997/8/29",
  "price": "4900",
  "genre": "パチンコ",
  "icNo": "53"
 },
 {
  "id": "1408",
  "ini": "し",
  "name": "実戦パチスロ必勝法！Twin Vol.2 ウルトラセブン・ワイワイパルサー2",
  "maker": "サミー",
  "releaseDate": "1997/9/12",
  "price": "5980",
  "genre": "スロット",
  "icNo": "0"
 },
 {
  "id": "1409",
  "ini": "と",
  "name": "同級生2",
  "maker": "バンプレスト",
  "releaseDate": "1997/12/1",
  "price": "3000",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1410",
  "ini": "は",
  "name": "爆球連発!! スーパービーダマン",
  "maker": "ハドソン",
  "releaseDate": "1997/12/19",
  "price": "4980",
  "genre": "シューティング・パズル",
  "icNo": "1"
 },
 {
  "id": "1411",
  "ini": "へ",
  "name": "HEIWA Parlor！Mini8 パチンコ実機シミュレーションゲーム",
  "maker": "日本テレネット",
  "releaseDate": "1998/1/30",
  "price": "5200",
  "genre": "パチンコ",
  "icNo": "10"
 },
 {
  "id": "1412",
  "ini": "す",
  "name": "スーパーファミリーゲレンデ",
  "maker": "ナムコ",
  "releaseDate": "1998/2/1",
  "price": "3000",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "1413",
  "ini": "す",
  "name": "スーパーパンチアウト!!",
  "maker": "任天堂",
  "releaseDate": "1998/3/1",
  "price": "3000",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "1414",
  "ini": "し",
  "name": "実況パワフルプロ野球 BASIC版'98",
  "maker": "コナミ",
  "releaseDate": "1998/3/19",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1415",
  "ini": "ほ",
  "name": "星のカービィ3",
  "maker": "任天堂",
  "releaseDate": "1998/3/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "1416",
  "ini": "ふ",
  "name": "ファミコン探偵倶楽部PARTⅡ うしろに立つ少女",
  "maker": "任天堂",
  "releaseDate": "1998/4/1",
  "price": "3000",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1417",
  "ini": "ろ",
  "name": "ロックマン&フォルテ",
  "maker": "カプコン",
  "releaseDate": "1998/4/24",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1418",
  "ini": "す",
  "name": "スーパーファミコンウォーズ",
  "maker": "任天堂",
  "releaseDate": "1998/5/1",
  "price": "3000",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "1419",
  "ini": "へ",
  "name": "平成 新・鬼ヶ島 前編",
  "maker": "任天堂",
  "releaseDate": "1998/5/23",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "32"
 },
 {
  "id": "1420",
  "ini": "へ",
  "name": "平成 新・鬼ヶ島 後編",
  "maker": "任天堂",
  "releaseDate": "1998/5/23",
  "price": "3800",
  "genre": "アドベンチャー",
  "icNo": "32"
 },
 {
  "id": "1421",
  "ini": "れ",
  "name": "レッキングクルー'98",
  "maker": "任天堂",
  "releaseDate": "1998/5/23",
  "price": "3800",
  "genre": "アクション・パズル",
  "icNo": "0"
 },
 {
  "id": "1422",
  "ini": "け",
  "name": "幻獣旅団",
  "maker": "アクセラ",
  "releaseDate": "1998/6/1",
  "price": "3000",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "1423",
  "ini": "と",
  "name": "ドクターマリオ",
  "maker": "任天堂",
  "releaseDate": "1998/6/1",
  "price": "3000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1424",
  "ini": "り",
  "name": "リングにかけろ",
  "maker": "メサイア（日本コンピュータシステム）",
  "releaseDate": "1998/6/1",
  "price": "3000",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "1425",
  "ini": "す",
  "name": "Zooっと麻雀！",
  "maker": "任天堂",
  "releaseDate": "1998/7/1",
  "price": "3000",
  "genre": "テーブル",
  "icNo": "3"
 },
 {
  "id": "1426",
  "ini": "た",
  "name": "ダービースタリオン98",
  "maker": "任天堂",
  "releaseDate": "1998/8/25",
  "price": "6000",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "1427",
  "ini": "み",
  "name": "ミニ四駆レッツ&ゴー!! POWER WGP2",
  "maker": "任天堂",
  "releaseDate": "1998/12/4",
  "price": "3800",
  "genre": "レース・シミュレーション",
  "icNo": "30"
 },
 {
  "id": "1428",
  "ini": "は",
  "name": "POWERロードランナー",
  "maker": "任天堂",
  "releaseDate": "1999/1/1",
  "price": "2500",
  "genre": "アクション・パズル",
  "icNo": "3"
 },
 {
  "id": "1429",
  "ini": "ひ",
  "name": "ピクロスNP Vol.1",
  "maker": "任天堂",
  "releaseDate": "1999/4/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1430",
  "ini": "た",
  "name": "たまごっちタウン",
  "maker": "バンダイ",
  "releaseDate": "1999/5/1",
  "price": "2500",
  "genre": "育成・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "1431",
  "ini": "う",
  "name": "ウィザードリィⅠ・Ⅱ・Ⅲ Story of Llylgamyn",
  "maker": "メディアファクトリー",
  "releaseDate": "1999/6/1",
  "price": "3000",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "1432",
  "ini": "お",
  "name": "お絵かきロジック",
  "maker": "世界文化社",
  "releaseDate": "1999/6/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1433",
  "ini": "ひ",
  "name": "ピクロスNP Vol.2",
  "maker": "任天堂",
  "releaseDate": "1999/6/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1434",
  "ini": "か",
  "name": "カービィのきらきらきっず",
  "maker": "任天堂",
  "releaseDate": "1999/6/25",
  "price": "4200",
  "genre": "パズル",
  "icNo": "56"
 },
 {
  "id": "1435",
  "ini": "す",
  "name": "すってはっくん",
  "maker": "任天堂",
  "releaseDate": "1999/6/25",
  "price": "4200",
  "genre": "アクション・パズル",
  "icNo": "0"
 },
 {
  "id": "1436",
  "ini": "は",
  "name": "POWER倉庫番",
  "maker": "任天堂",
  "releaseDate": "1999/6/25",
  "price": "4200",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "1437",
  "ini": "ふ",
  "name": "ファミコン文庫 はじまりの森",
  "maker": "任天堂",
  "releaseDate": "1999/7/1",
  "price": "2500",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "1438",
  "ini": "こ",
  "name": "コラムス",
  "maker": "メディアファクトリー",
  "releaseDate": "1999/8/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1439",
  "ini": "ひ",
  "name": "ピクロスNP Vol.3",
  "maker": "任天堂",
  "releaseDate": "1999/8/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1440",
  "ini": "ひ",
  "name": "ピクロスNP Vol.4",
  "maker": "任天堂",
  "releaseDate": "1999/10/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1441",
  "ini": "お",
  "name": "お絵かきロジック2",
  "maker": "世界文化社",
  "releaseDate": "1999/11/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1442",
  "ini": "ひ",
  "name": "ピクロスNP Vol.5",
  "maker": "任天堂",
  "releaseDate": "1999/12/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1443",
  "ini": "ふ",
  "name": "ファイアーエムブレム トラキア776",
  "maker": "任天堂",
  "releaseDate": "2000/1/21",
  "price": "5200",
  "genre": "シミュレーション・RPG",
  "icNo": "32"
 },
 {
  "id": "1444",
  "ini": "ひ",
  "name": "ピクロスNP Vol.6",
  "maker": "任天堂",
  "releaseDate": "2000/2/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1445",
  "ini": "ひ",
  "name": "ピクロスNP Vol.7",
  "maker": "任天堂",
  "releaseDate": "2000/4/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1446",
  "ini": "ひ",
  "name": "ピクロスNP Vol.8",
  "maker": "任天堂",
  "releaseDate": "2000/6/1",
  "price": "2000",
  "genre": "パズル",
  "icNo": "3"
 },
 {
  "id": "1447",
  "ini": "め",
  "name": "メタルスレイダーグローリー ディレクターズカット",
  "maker": "任天堂",
  "releaseDate": "2000/11/29",
  "price": "5980",
  "genre": "アドベンチャー",
  "icNo": "1"
 }
];